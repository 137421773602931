import { baseUrl, carHomeUrl } from '@/config';

export const TRENDING_SEARCHES_CAR = [
  { label: 'kishaszongépjármű', link: `${carHomeUrl}/magyarorszag/kishaszongepjarmu` },
  { label: 'használt elektromos autó', link: `${baseUrl}/magyarorszag?q=elektromos auto` },
  { label: 'lakóautó eladó', link: `${baseUrl}/magyarorszag?q=lakóautó` },
  { label: 'kisteherautó', link: `${baseUrl}/magyarorszag?q=kisteherautó` },
  { label: 'eladó csónak', link: `${baseUrl}/magyarorszag?q=csonak` },
  { label: 'teherautó', link: `${carHomeUrl}/magyarorszag/teherauto` },
  { label: 'eladó lakókocsi', link: `${carHomeUrl}/magyarorszag/lakokocsi` },
  { label: 'bmw motor', link: `${carHomeUrl}/magyarorszag/motor-robogo/bmw-` },
  { label: 'terepjáró', link: `${baseUrl}/magyarorszag?q=terepjáró` },
  { label: 'felni', link: `${carHomeUrl}/magyarorszag/felnik-gumiabroncsok` },
  { label: 'négyévszakos gumi', link: `${baseUrl}/magyarorszag?q=négyévszakos` },
  { label: 'mopedautó', link: `${carHomeUrl}/magyarorszag/mopedauto` },
  { label: 'eladó autók magánszemélytől', link: `${carHomeUrl}/magyarorszag/auto` },
  { label: 'utánfutó', link: `${carHomeUrl}/magyarorszag/utanfuto` },
  { label: 'használt kerékpár', link: `${baseUrl}/magyarorszag/kerekparok-es-alkatreszek` },
  { label: 'használt motor', link: `${carHomeUrl}/magyarorszag/motor-robogo` },
];

export const TRENDING_CITIES = [
  { label: 'használt autó budapest', link: `${carHomeUrl}/budapest/auto` },
  { label: 'használt autó kaposvár', link: `${carHomeUrl}/somogy/kaposvar/auto` },
  { label: 'használt autó pécs', link: `${carHomeUrl}/baranya/pecs/auto` },
  { label: 'használt autó szeged', link: `${carHomeUrl}/csongrad/szeged/auto` },
  { label: 'használt autó szombathely', link: `${carHomeUrl}/vas/szombathely/auto` },
  { label: 'használt autó debrecen', link: `${carHomeUrl}/hajdu-bihar/debrecen/auto` },
  { label: 'használt autó székesfehérvár', link: `${carHomeUrl}/fejer/szekesfehervar/auto` },
  { label: 'használt autó miskolc', link: `${carHomeUrl}/borsod-abauj-zemplen/miskolc/auto` },
  { label: 'használt autó eger', link: `${carHomeUrl}/heves/eger/auto` },
  { label: 'használt autó nyíregyháza', link: `${carHomeUrl}/szabolcs-szatmar-bereg/nyiregyhaza/auto` },
  { label: 'használt autó győr', link: `${carHomeUrl}/gyor-moson-sopron/gyor/auto` },
];

export const TRENDING_BRANDS_CAR = [
  { label: 'audi a4', link: `${carHomeUrl}/magyarorszag/auto/audi/a4-1` },
  { label: 'nissan qashqai', link: `${carHomeUrl}/magyarorszag/auto/nissan/qashqai` },
  { label: 'opel corsa', link: `${carHomeUrl}/magyarorszag/auto/opel/corsa` },
  { label: 'alfa romeo', link: `${carHomeUrl}/magyarorszag/auto/alfa-romeo` },
  { label: 'ford mustang', link: `${carHomeUrl}/magyarorszag/auto/ford/mustang-1` },
  { label: 'honda civic', link: `${carHomeUrl}/magyarorszag/auto/honda/civic` },
  { label: 'toyota corolla', link: `${carHomeUrl}/magyarorszag/auto/toyota/corolla-` },
  { label: 'ford focus', link: `${carHomeUrl}/magyarorszag/auto/ford/focus-1` },
  { label: 'opel astra', link: `${carHomeUrl}/magyarorszag/auto/opel/astra-` },
  { label: 'toyota yaris', link: `${carHomeUrl}/magyarorszag/auto/toyota/yaris` },
  { label: 'mazda', link: `${carHomeUrl}/magyarorszag/auto/mazda` },
  { label: 'peugeot', link: `${carHomeUrl}/magyarorszag/auto/peugeot` },
  { label: 'dacia', link: `${carHomeUrl}/magyarorszag/auto/dacia` },
  { label: 'suzuki vitara', link: `${carHomeUrl}/magyarorszag/auto/suzuki/vitara` },
  { label: 'bmw', link: `${carHomeUrl}/magyarorszag/auto/bmw` },
  { label: 'suzuki', link: `${carHomeUrl}/magyarorszag/auto/suzuki` },
  { label: 'hyundai i30', link: `${carHomeUrl}/magyarorszag/auto/hyundai/i30-` },
  { label: 'toyota', link: `${carHomeUrl}/magyarorszag/auto/toyota` },
  { label: 'mercedes', link: `${carHomeUrl}/magyarorszag/auto/mercedes-benz` },
  { label: 'ford', link: `${carHomeUrl}/magyarorszag/auto/ford` },
  { label: 'volkswagen', link: `${carHomeUrl}/magyarorszag/auto/volkswagen` },
  { label: 'suzuki swift', link: `${carHomeUrl}/magyarorszag/auto/suzuki` },
  { label: 'hyundai', link: `${carHomeUrl}/magyarorszag/auto/hyundai` },
  { label: 'audi', link: `${carHomeUrl}/magyarorszag/auto/audi` },
  { label: 'kia', link: `${carHomeUrl}/magyarorszag/auto/kia` },
  { label: 'skoda', link: `${carHomeUrl}/magyarorszag/auto/skoda` },
  { label: 'renault', link: `${carHomeUrl}/magyarorszag/auto/renault` },
  { label: 'honda', link: `${carHomeUrl}/magyarorszag/auto/honda` },
  { label: 'porsche', link: `${carHomeUrl}/magyarorszag/auto/porsche` },
  { label: 'skoda octavia', link: `${carHomeUrl}/magyarorszag/auto/skoda/octavia` },
  { label: 'fiat 500', link: `${carHomeUrl}/magyarorszag/auto/fiat/500-6` },
  { label: 'opel', link: `${carHomeUrl}/magyarorszag/auto/opel` },
  { label: 'mitsubishi', link: `${carHomeUrl}/magyarorszag/auto/mitsubishi` },
  { label: 'hyundai tucson', link: `${carHomeUrl}/magyarorszag/auto/hyundai/galloper` },
  { label: 'volvo', link: `${carHomeUrl}/magyarorszag/auto/volvo` },
  { label: 'hyundai i20', link: `${carHomeUrl}/magyarorszag/auto/hyundai/i20` },
  { label: 'renault megane', link: `${carHomeUrl}/magyarorszag/auto/renault/megane` },
  { label: 'audi a3', link: `${carHomeUrl}/magyarorszag/auto/audi/a3-2` },
  { label: 'kia ceed', link: `${carHomeUrl}/magyarorszag/auto/kia/cee-d` },
  { label: 'harley davidson', link: `${carHomeUrl}/magyarorszag/motor-robogo/harley-davidson` },
  { label: 'nissan', link: `${carHomeUrl}/magyarorszag/auto/nissan` },
  { label: 'toyota rav4', link: `${carHomeUrl}/magyarorszag/auto/toyota/rav-4-` },
  { label: 'dacia duster', link: `${carHomeUrl}/magyarorszag/auto/dacia/duster` },
  { label: 'mercedes amg', link: `${carHomeUrl}/magyarorszag/auto/mercedes-amg` },
  { label: 'jeep', link: `${carHomeUrl}/magyarorszag/auto/jeep` },
  { label: 'chevrolet', link: `${carHomeUrl}/magyarorszag/auto/chevrolet` },
  { label: 'citroen', link: `${carHomeUrl}/magyarorszag/auto/citroen` },
  { label: 'jaguar', link: `${carHomeUrl}/magyarorszag/auto/jaguar` },
  { label: 'lexus', link: `${carHomeUrl}/magyarorszag/auto/lexus` },
];
