import { OrganizationJsonLd, ProductJsonLd } from 'next-seo';

import { baseUrl } from '@/config';

const StructuredData = ({ productJsonLdData }) => {
  if (productJsonLdData) {
    return (
      <ProductJsonLd
        productName={productJsonLdData.name}
        images={productJsonLdData.image}
        description={productJsonLdData.description}
        offers={productJsonLdData.offers}
      />
    );
  }

  return (
    <OrganizationJsonLd
      type="Organization"
      logo={`${baseUrl}/assets/seo/jfg_logo_google.png`}
      name="Jófogás"
      sameAs={[
        'https://www.facebook.com/jofogas',
        'https://www.instagram.com/jofogas/',
        'https://www.youtube.com/user/jofogas',
      ]}
      url={baseUrl}
    />
  );
};

export default StructuredData;
