import Script from 'next/script';

const Braze = () => (
  <Script
    id="braze"
    strategy="afterInteractive"
    src="https://js.appboycdn.com/web-sdk/3.4/appboy.min.js"
  />
);

export default Braze;
