import { Context, createContext, useContext } from 'react';

export type DimensionContextState = {
  breadcrumb: any,
};

const initialState: DimensionContextState = {
  breadcrumb: null,
};

const DimensionsContext: Context<DimensionContextState> = createContext(initialState);

export const useDimensionsContext = ():DimensionContextState => useContext(DimensionsContext);

export default DimensionsContext;
