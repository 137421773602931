import { baseUrl, realEstateHomeUrl } from '@/config';

export const APARTMENTS = [
  { label: 'eladó lakás szekszárd', link: `${realEstateHomeUrl}/tolna/szekszard/lakas` },
  { label: 'eladó lakás vác', link: `${realEstateHomeUrl}/pest/vac/lakas` },
  { label: 'albérlet szeged', link: `${realEstateHomeUrl}/csongrad/szeged/lakas?st=u` },
  { label: 'albérlet miskolc', link: `${realEstateHomeUrl}/borsod-abauj-zemplen/miskolc/lakas?st=u` },
  { label: 'eladó lakás szolnok', link: `${realEstateHomeUrl}/jasz-nagykun-szolnok/szolnok/lakas` },
  { label: 'eladó lakás kecskemét', link: `${realEstateHomeUrl}/bacs-kiskun/kecskemet/lakas` },
  { label: 'albérlet győr', link: `${realEstateHomeUrl}/gyor-moson-sopron/gyor/lakas?st=u` },
  { label: 'eladó lakás eger', link: `${realEstateHomeUrl}/heves/eger/lakas` },
  { label: 'eladó lakás zalaegerszeg', link: `${realEstateHomeUrl}/zala/zalaegerszeg/lakas` },
  { label: 'eladó lakás nyíregyháza', link: `${realEstateHomeUrl}/szabolcs-szatmar-bereg/nyiregyhaza/lakas` },
  { label: 'eladó lakás székesfehérvár', link: `${realEstateHomeUrl}/fejer/szekesfehervar/lakas` },
  { label: 'eladó lakás pécs', link: `${realEstateHomeUrl}/baranya/pecs/lakas` },
  { label: 'eladó lakás szeged', link: `${realEstateHomeUrl}/csongrad/szeged/lakas` },
  { label: 'eladó lakás veszprém', link: `${realEstateHomeUrl}/veszprem/lakas` },
  { label: 'albérlet debrecen', link: `${realEstateHomeUrl}/hajdu-bihar/debrecen/lakas?st=u` },
  { label: 'eladó lakás győr', link: `${realEstateHomeUrl}/gyor-moson-sopron/gyor/lakas` },
  { label: 'eladó lakás miskolc', link: `${realEstateHomeUrl}/borsod-abauj-zemplen/miskolc/lakas` },
  { label: 'eladó lakás debrecen', link: `${realEstateHomeUrl}/hajdu-bihar/debrecen/lakas` },
  { label: 'eladó lakás budapest', link: `${realEstateHomeUrl}/budapest/ingatlan` },
  { label: 'albérlet budapest', link: `${realEstateHomeUrl}/budapest/lakas?st=u` },
];

export const TRENDING_SEARCHES = [
  { label: 'kiadó üzlethelyiség', link: `${baseUrl}/budapest?q=kiado uzlet` },
  { label: 'eladó nyaraló', link: `${realEstateHomeUrl}/magyarorszag/nyaralok-udulok` },
  { label: 'eladó telek', link: `${realEstateHomeUrl}/budapest/telek-fold` },
  { label: 'eladó tanya', link: `${realEstateHomeUrl}/magyarorszag/tanya?st=s` },
  { label: 'eladó nyaraló balaton', link: `${baseUrl}/magyarorszag?q=q=balatoni nyaralo` },
  { label: 'eladó kastély', link: `${baseUrl}/magyarorszag?q=kastely` },
];

export const HOUSES = [
  { label: 'eladó ház hódmezővásárhely', link: `${realEstateHomeUrl}/csongrad/hodmezovasarhely/haz` },
  { label: 'eladó házak felsőtárkány', link: `${realEstateHomeUrl}/heves/felsotarkany/haz` },
  { label: 'eladó ház békéscsaba', link: `${realEstateHomeUrl}/bekes/bekescsaba/haz` },
  { label: 'eladó ház szolnok', link: `${realEstateHomeUrl}/jasz-nagykun-szolnok/szolnok/haz` },
  { label: 'eladó ház zalaegerszeg', link: `${realEstateHomeUrl}/zala/zalaegerszeg/haz` },
  { label: 'eladó ház győr', link: `${realEstateHomeUrl}/gyor-moson-sopron/gyor/haz` },
  { label: 'eladó ház nagykanizsa', link: `${realEstateHomeUrl}/zala/nagykanizsa/haz` },
  { label: 'eladó ház pest megye', link: `${realEstateHomeUrl}/pest/haz` },
  { label: 'eladó ház székesfehérvár', link: `${realEstateHomeUrl}/fejer/szekesfehervar/haz` },
  { label: 'eladó házak békésen', link: `${realEstateHomeUrl}/bekes/haz` },
  { label: 'eladó ház gyöngyös', link: `${realEstateHomeUrl}/heves/gyongyos/haz` },
  { label: 'eladó ház kecskemét', link: `${realEstateHomeUrl}/bacs-kiskun/kecskemet/haz` },
  { label: 'eladó ház szeged', link: `${realEstateHomeUrl}/csongrad/szeged/haz` },
  { label: 'eladó ház miskolc', link: `${realEstateHomeUrl}/borsod-abauj-zemplen/miskolc/haz` },
  { label: 'eladó ház pécs', link: `${realEstateHomeUrl}/baranya/pecs/haz` },
  { label: 'eladó ház debrecen', link: `${realEstateHomeUrl}/hajdu-bihar/debrecen/haz` },
];
