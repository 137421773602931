import { apiUrl, apiKey } from '@/config';
import measurableFetch from '@/helpers/measurableFetch';

async function fetchTermsAndConditions() {
  const res = await measurableFetch(`${apiUrl}/v2/config/terms`, {
    headers: {
      api_key: apiKey,
      'content-type': 'application/json',
    },
  });

  if (!res.ok) {
    throw Error(`${res.url} Request rejected with status ${res.status}`);
  }

  return res.json({ termsText: res.termsText, links: res.links });
}

const getTermsAndConditions = async () => {
  try {
    return await fetchTermsAndConditions();
  } catch (error) {
    return {};
  }
};

export default getTermsAndConditions;
