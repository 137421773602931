const tealiumPageData = {
  '/': {
    event_name: 'docs/index',
    acmh_event_type: 'view_frontpage',
    acmh_page_type: '"homepage"',
    level2_site: '1',
    page_type_id: '1',
  },
  '/auto': {
    event_name: 'docs/index',
    acmh_event_type: 'view_frontpage',
    acmh_page_type: '"homepage"',
    level2_site: '1',
    page_type_id: '1',
  },
  '/allas': {
    event_name: 'docs/index',
    acmh_event_type: 'view_frontpage',
    acmh_page_type: '"homepage"',
    level2_site: '1',
    page_type_id: '1',
  },
  '/ingatlan': {
    event_name: 'docs/index',
    acmh_event_type: 'view_frontpage',
    acmh_page_type: '"homepage"',
    level2_site: '1',
    page_type_id: '1',
  },
  '/fiok/kedvencek': {
    event_name: 'Account::Favourites',
    acmh_event_type: 'view_mypage',
    level2_site: '9',
    page_type_id: '4',
  },
  '/fiok/mentett_keresesek': {
    event_name: 'Account::Adwatch',
    acmh_event_type: 'view_mypage',
    level2_site: '9',
    page_type_id: '4',
  },
  '/fiok/postalada': {
    event_name: 'Account::Messages::In Box::Discussion',
    acmh_event_type: 'view_mypage',
    level2_site: '9',
    page_type_id: '4',
  },
  '/boltok': {
    event_name: '/boltok',
  },
  '/product/[listId]': {
    acmh_event_type: 'view_ad',
    acmh_page_type: '"ad_detail"',
  },
};

export default tealiumPageData;
