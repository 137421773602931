import { SEARCHED_BRANDS, TRENDING_CATEGORIES } from './data';
import SeoLinks from './seo-links';

const home = [{
  title: 'Keresett márkák',
  pages: SEARCHED_BRANDS,
},
{
  title: 'Népszerű kategóriák',
  pages: TRENDING_CATEGORIES,
}];

const JfgSeoLinks = () => <SeoLinks groups={home} />;

export default JfgSeoLinks;
