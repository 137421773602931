import { AddCircleOutline } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { baseUrl, base2Url } from '@/config';
import { clickAiButton } from '@/helpers/tracking/events';

const AdInsertionIcon = ({ account, pathname = '', ...args }) => {
  const backUrl = `${base2Url}/ai`;
  const loginUrl = `${baseUrl}/belepes?backUrl=${encodeURIComponent(backUrl).replace(/\./g, '%2E')}`;
  const loginAttr = account ? { href: backUrl } : { href: loginUrl };
  const clickAttrForJobVertical = pathname === '/allas' && { onClick: clickAiButton };

  return (
    <IconButton
      {...loginAttr}
      {...clickAttrForJobVertical}
      size="large"
      color="primary"
      {...args}
    >
      <AddCircleOutline />
    </IconButton>
  );
};

export default AdInsertionIcon;
