import Link from '@mui/material/Link';

const flatMap = (array, fn) => {
  let result = [];
  array.forEach((element) => {
    result = result.concat(fn(element));
  });
  return result;
};

const changeWordWithLink = (originalText, index, links) => {
  if (originalText) {
    return flatMap(
      originalText.split(links[index].tag),
      (part) => [part,
        <Link
          key={index}
          href={links[index].url}
        >
          {links[index].name}
        </Link>],
    );
  }
  return false;
};

const exchangeWordsWithLinks = (termsText, links) => {
  let text = termsText;
  let textArray = [];
  links.forEach((element, index) => {
    if (index !== 0) {
      const buffer = changeWordWithLink(text[index * 2], index, links);
      if (buffer) {
        buffer.pop();
        textArray[index * 2] = buffer;
        textArray = textArray.flat();
        text = textArray;
      }
    } else {
      text = changeWordWithLink(text, index, links);
      text.pop();
      textArray = text;
    }
  });

  return text;
};

export default exchangeWordsWithLinks;
