import { ModalContext } from '@/contexts/Modal/ModalContext';

const ModalUI = () => (
  <ModalContext.Consumer>
    {({ modalView: Component, displayModal, modalProps }) => (
      displayModal ? <Component {...modalProps} /> : null
    )}
  </ModalContext.Consumer>
);

export default ModalUI;
