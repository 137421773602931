/* eslint-disable max-lines */
const fallbacks = {
  '300x250': [
    {
      image: '300w250h_adatv_banner_v1.png', url: 'https://www.jofogas.hu/adathalaszat', width: 300, height: 250,
    },
    {
      image: '300w250h_adatv_v2.png', url: 'https://www.jofogas.hu/adathalaszat', width: 300, height: 250,
    },
    {
      image: '300w250h_blog_banner_DIY.png', url: 'https://blog.jofogas.hu/category/diy/', width: 300, height: 250,
    },
    {
      image: '300w250h_otthon_blog_banner.png', url: 'https://blog.jofogas.hu/category/otthon/', width: 300, height: 250,
    },
    {
      image: '300w250h_fb_promo_banner.png', url: 'https://www.facebook.com/jofogas/?locale=hu_HU', width: 300, height: 250,
    },
    {
      image: '300w250h_insta_promo_bannerx1_12.png', url: 'https://www.instagram.com/jofogas/?hl=en', width: 300, height: 250,
    },
  ],
  '336x280': [
    {
      image: '336w280h_adatv_banner_v1_x1_12.png', url: 'https://www.jofogas.hu/adathalaszat', width: 336, height: 280,
    },
    {
      image: '336w280h_adatv_v2_x1_12.png', url: 'https://www.jofogas.hu/adathalaszat', width: 336, height: 280,
    },
    {
      image: '336w280h_blog_banner_DIYx1_12.png', url: 'https://blog.jofogas.hu/category/diy', width: 336, height: 280,
    },
    {
      image: '336w280h_blog_banner_otthon_x1_12.png', url: 'https://blog.jofogas.hu/category/otthon/', width: 336, height: 280,
    },
    {
      image: '336w280h_fb_promo_bannerx1_12.png', url: 'https://www.facebook.com/jofogas/?locale=hu_HU', width: 336, height: 280,
    },
    {
      image: '336w280h_insta_promo_bannerx1_12.png', url: 'https://www.instagram.com/jofogas/?hl=en', width: 336, height: 280,
    },
  ],
  '640x360': [
    {
      image: '640w360h_adatv_banner_v1_x1_12.png', url: 'https://www.jofogas.hu/adathalaszat', width: 640, height: 360,
    },
    {
      image: '640w360h_adatv_v2_x1_12.png', url: 'https://www.jofogas.hu/adathalaszat', width: 640, height: 360,
    },
    {
      image: '640w360h_blog_banner_DIYx1_12.png', url: 'https://blog.jofogas.hu/category/diy/', width: 640, height: 360,
    },
    {
      image: '640w360h_blog_banner_otthon_x1_12.png', url: 'https://blog.jofogas.hu/category/otthon/', width: 640, height: 360,
    },
    {
      image: '640w360h_fb_promo_banner.png', url: 'https://www.facebook.com/jofogas/?locale=hu_HU', width: 640, height: 360,
    },
    {
      image: '640w360h_insta_promo_banner.png', url: 'https://www.instagram.com/jofogas/?hl=en', width: 640, height: 360,
    },
  ],
  '970x250': [
    {
      image: '970w250h_adatv_banner_v1.png', url: 'https://www.jofogas.hu/adathalaszat', width: 970, height: 250,
    },
    {
      image: '970w250h_adatv_v2.png', url: 'https://www.jofogas.hu/adathalaszat', width: 970, height: 250,
    },
    {
      image: '970w250h_blog_banner_DIY.png', url: 'https://blog.jofogas.hu/category/diy/', width: 970, height: 250,
    },
    {
      image: '970w250h_otthon_blog_banner.png', url: 'https://blog.jofogas.hu/category/otthon/', width: 970, height: 250,
    },
    {
      image: '970w250h_fb_promo_banner.png', url: 'https://www.facebook.com/jofogas/?locale=hu_HU', width: 970, height: 250,
    },
    {
      image: '970w250h_insta_promo_banner.png', url: 'https://www.instagram.com/jofogas/?hl=en', width: 970, height: 250,
    },
  ],
};

const zones = {
  global: {
    common_stat_zone_1: {
      all: {
        production: 5362152,
        test: 6787982,
        development: 6787982,
      },
    },
    common_stat_zone_2: {
      all: {
        production: 5362153,
        test: 6787983,
        development: 6787983,
      },
    },
    popup: {
      all: {
        production: 3244593,
        test: 6787987,
        development: 6787987,
      },
    },
  },
  indexPage: {
    r_above: {
      desktop: {
        production: 6541637,
        test: 3672182,
        development: 3672182,
      },
    },
    index_bottom: {
      mobile: {
        production: 4226533,
        test: 4247121,
        development: 4247121,
      },
    },
    index_inbetween: {
      desktop: {
        production: 7586717,
        test: 7586717,
        development: 7586717,
      },
      mobile: {
        production: 7586718,
        test: 7586718,
        development: 7586718,
      },
      fallbacks: { desktop: fallbacks['970x250'], mobile: fallbacks['300x250'] },
    },
  },
  vertical: {
    car_inbetween: {
      desktop: {
        production: 7616590,
        test: 7616590,
        development: 7616590,
      },
      mobile: {
        production: 7616592,
        test: 7616592,
        development: 7616592,
      },
      fallbacks: { desktop: fallbacks['970x250'], mobile: fallbacks['300x250'] },
    },
    job_inbetween: {
      desktop: {
        production: 7616591,
        test: 7616591,
        development: 7616591,
      },
      mobile: {
        production: 7616593,
        test: 7616593,
        development: 7616593,
      },
      fallbacks: { desktop: fallbacks['970x250'], mobile: fallbacks['300x250'] },
    },
    realestate_inbetween: {
      desktop: {
        production: 7621253,
        test: 7621253,
        development: 7621253,
      },
      mobile: {
        production: 7621254,
        test: 7621254,
        development: 7621254,
      },
      fallbacks: { desktop: fallbacks['970x250'], mobile: fallbacks['300x250'] },
    },
  },
  myPages: {
    r_above: {
      desktop: {
        production: 3201445,
        test: 4974633,
        development: 4974633,
      },
      mobile: {
        production: 4248017,
        test: 4974655,
        development: 4974655,
      },
    },
  },
  favoriteAds: {
    r_above: {
      desktop: {
        production: 4394421,
        test: 4974633,
        development: 4974633,
      },
    },
    m_r_admin: {
      mobile: {
        production: 4970260,
        test: 4974655,
        development: 4974655,
      },
    },
  },
  mailBox: {
    r_above: {
      desktop: {
        production: 4394417,
        test: 4974633,
        development: 4974633,
      },
    },
    m_r_admin: {
      mobile: {
        production: 4970260,
        test: 4974655,
        development: 4974655,
      },
    },
  },
  vi: {
    r_above: {
      desktop: {
        1020: 3673021,
        1040: 3673004,
        1050: 3672997,
        1060: 3673038,
        1080: 3673010,
        1100: 3673030,
        1200: 3673023,
        1210: 3673023,
        1220: 3673023,
        1340: 3673032,
        1360: 3673024,
        1361: 3673024,
        1362: 3673024,
        1363: 3673024,
        1364: 3673024,
        1365: 3673024,
        1366: 3673024,
        1367: 3673024,
        2020: 3672120,
        2040: 3672125,
        2060: 3672126,
        2080: 3672124,
        2081: 5481552,
        2082: 5481660,
        2083: 5481684,
        2084: 5481700,
        2085: 5481722,
        2086: 5481740,
        2087: 5481782,
        2088: 5481807,
        2089: 5481825,
        2090: 5481857,
        2091: 5481876,
        2100: 3672122,
        2101: 5481963,
        2102: 5481893,
        2103: 5481909,
        2104: 5481929,
        2105: 5481945,
        2119: 3672121,
        2120: 3672121,
        2121: 3672121,
        2122: 5481563,
        2123: 3672121,
        2124: 3672121,
        2125: 3672121,
        2126: 3672121,
        2127: 3672121,
        2128: 3672121,
        2129: 3672121,
        2130: 3672121,
        2131: 3672121,
        2132: 3672121,
        2133: 3672121,
        2134: 3672121,
        2135: 3672121,
        2136: 3672121,
        2137: 3672121,
        2138: 3672121,
        2139: 3672121,
        2140: 3673026,
        2141: 5481620,
        2142: 3673026,
        2143: 3673026,
        2144: 3673026,
        2145: 3673026,
        2146: 3673026,
        2147: 3673026,
        2148: 3673026,
        2149: 3673026,
        2150: 3673026,
        2151: 3673026,
        2152: 3673026,
        2153: 3673026,
        2154: 5481642,
        2155: 3673026,
        2156: 3673026,
        2157: 3673026,
        2158: 3673026,
        2159: 3673026,
        2160: 3672121,
        2161: 5481602,
        2180: 3673002,
        2181: 3673002,
        2182: 3673002,
        2183: 3673002,
        2184: 3673002,
        2185: 3673002,
        2186: 3673002,
        2187: 3673002,
        2188: 3673002,
        2189: 3673002,
        2190: 3673002,
        2191: 3673002,
        2310: 3672123,
        2410: 3672988,
        2411: 3672988,
        2412: 3672988,
        2413: 3672988,
        2414: 3672988,
        2415: 3672988,
        2460: 3673043,
        3001: 3672991,
        3002: 3672991,
        3003: 3672991,
        3004: 3672991,
        3005: 3672991,
        3006: 3672991,
        3007: 3672991,
        3008: 3672991,
        3020: 3673029,
        3021: 3673029,
        3022: 3673029,
        3023: 3673029,
        3024: 3673029,
        3025: 3673029,
        3040: 3672987,
        3041: 5481980,
        3042: 5482000,
        3043: 5482015,
        3044: 5482031,
        3045: 3673022,
        3046: 5482047,
        3047: 5482080,
        3048: 5482062,
        3050: 3673042,
        3051: 3673042,
        3052: 3673042,
        3053: 3673042,
        3054: 3673042,
        3055: 3673042,
        3056: 3673042,
        3057: 3673042,
        3060: 3673006,
        3061: 3673006,
        3062: 3673006,
        3063: 3673006,
        3064: 3673006,
        3065: 3673006,
        3066: 3673006,
        3067: 3673006,
        3071: 5482096,
        3072: 5482115,
        3073: 5482133,
        3074: 5482150,
        3075: 5482180,
        3076: 5482199,
        3080: 3673036,
        3081: 3673036,
        3082: 3673036,
        3083: 3673036,
        3084: 3673036,
        3085: 3673036,
        3100: 3672992,
        3101: 3672992,
        3102: 3672992,
        3103: 3672992,
        3104: 3672992,
        3105: 3672992,
        3106: 3672992,
        3107: 3672992,
        3108: 3672992,
        3109: 3672992,
        3360: 3672994,
        3361: 5482371,
        3362: 3672994,
        3363: 3672994,
        3364: 3672994,
        3365: 3672994,
        3366: 3672994,
        3367: 3672994,
        3368: 3672994,
        3423: 3673016,
        3424: 3673016,
        3425: 3673016,
        3426: 3673016,
        3427: 3673016,
        3428: 3673016,
        3461: 3673008,
        3462: 3673009,
        3463: 3673008,
        3464: 3673008,
        3465: 3673008,
        3466: 3673008,
        3467: 3673008,
        3468: 3673008,
        3469: 3673008,
        3470: 3673008,
        3471: 5482216,
        3472: 5482242,
        3473: 5482261,
        3474: 5482279,
        3475: 5482298,
        3476: 5482317,
        3477: 5482335,
        3478: 5482354,
        4020: 3673040,
        4021: 3673040,
        4022: 3673040,
        4023: 3673040,
        4024: 3673040,
        4040: 3673028,
        4041: 3673028,
        4042: 3673028,
        4043: 3673028,
        4044: 3673028,
        4045: 3673028,
        4046: 3673028,
        4047: 3673028,
        4048: 3673028,
        4049: 3673028,
        4050: 3673028,
        4060: 3673005,
        4061: 3673001,
        4062: 3673001,
        4063: 3673001,
        4064: 3673005,
        4080: 3673044,
        4081: 3673044,
        4082: 3673044,
        4083: 3673044,
        4084: 3673019,
        4100: 3673033,
        4101: 5482667,
        4102: 5482615,
        4103: 3673033,
        4104: 5482559,
        4105: 5482684,
        4106: 5482598,
        4107: 5482523,
        4108: 5482542,
        4109: 5482580,
        4110: 5482633,
        4111: 5482650,
        4140: 3673012,
        4141: 5482857,
        4142: 5482897,
        4143: 5482720,
        4144: 5482816,
        4145: 5482915,
        4146: 5482780,
        4147: 5482877,
        4148: 5482701,
        4149: 5482743,
        4150: 5482762,
        4151: 5482798,
        4152: 5482838,
        4301: 3673001,
        4302: 3673001,
        4303: 3673001,
        4304: 3673001,
        4305: 3673001,
        4310: 3673015,
        4311: 3673015,
        4312: 3673015,
        4313: 3673015,
        4314: 3673015,
        4315: 3673015,
        4316: 5482444,
        4317: 5482464,
        4318: 5482481,
        4319: 5482503,
        4320: 3673001,
        4321: 3673001,
        4322: 3673001,
        4323: 3673001,
        4324: 3673001,
        4325: 3673001,
        4326: 3673001,
        4327: 3673001,
        4340: 3673017,
        4341: 3673017,
        4342: 3673017,
        4343: 3673017,
        4344: 3673031,
        4345: 3673017,
        4346: 3673017,
        4347: 3673017,
        4348: 3673017,
        4360: 3673013,
        4361: 5482933,
        4362: 3673013,
        4363: 3673013,
        4364: 3673013,
        4365: 5482952,
        4380: 3672999,
        4381: 3672999,
        4382: 3672999,
        4383: 3672999,
        4384: 3672999,
        4385: 3672999,
        4386: 3672999,
        4387: 3672999,
        4390: 3673019,
        4391: 3673019,
        4392: 3673019,
        4393: 3673019,
        4394: 3673019,
        4395: 3673019,
        4396: 3673019,
        4397: 3673019,
        4398: 3673019,
        4399: 3673019,
        4400: 3673019,
        4401: 3673019,
        4402: 3673019,
        4403: 3673019,
        4460: 3672977,
        4461: 3672977,
        4462: 3672977,
        4463: 3672977,
        4464: 3672977,
        4465: 3672977,
        4466: 3672977,
        4467: 3672977,
        5001: 3673034,
        5002: 3673034,
        5003: 3673034,
        5004: 3673034,
        5005: 3673034,
        5006: 3673034,
        5007: 3673034,
        5008: 3673034,
        5009: 3673034,
        5010: 3673027,
        5020: 3673025,
        5021: 5482406,
        5022: 3673025,
        5023: 3673025,
        5024: 3673025,
        5040: 3673041,
        5041: 3672995,
        5042: 3673041,
        5043: 3673041,
        5044: 3673041,
        5045: 3673041,
        5046: 3673041,
        5047: 5482426,
        5048: 3672995,
        5049: 3672995,
        5050: 3672995,
        5051: 3672995,
        5052: 3672995,
        5053: 3672995,
        5054: 3673041,
        5055: 3673041,
        5060: 3673034,
        5070: 3673034,
        5080: 3673020,
        5081: 3673020,
        5082: 3673020,
        5083: 3673020,
        5084: 3673020,
        5085: 3673020,
        5086: 3673020,
        5320: 3673039,
        5321: 3673039,
        5322: 3673039,
        5323: 3673039,
        5324: 3673039,
        5325: 3673039,
        5330: 3673011,
        5360: 3673034,
        5370: 3673034,
        6020: 3672974,
        6021: 3672974,
        6022: 3672974,
        6023: 3672974,
        6024: 3672974,
        6025: 3672974,
        6026: 3672974,
        6027: 3672974,
        6028: 3672974,
        6029: 3672974,
        6030: 3672974,
        6031: 3672974,
        6032: 3672974,
        6033: 3672974,
        6034: 3672974,
        6035: 3672974,
        6036: 3672974,
        6037: 3672974,
        6038: 3672974,
        6039: 3672974,
        6041: 3672974,
        6062: 3672974,
        6063: 3672974,
        6064: 3672974,
        6065: 3672976,
        6066: 3672976,
        6067: 3672976,
        8030: 3673035,
        8031: 3673035,
        8032: 3673035,
        8033: 3673035,
        8034: 3673035,
        8035: 3673035,
        8036: 3673035,
        8044: 3673035,
        8061: 3672985,
        8062: 3672983,
        8063: 3672981,
        8064: 3672980,
        8065: 3672984,
        8066: 3672990,
        8067: 3672978,
        8068: 3673018,
        8069: 3672985,
        8070: 3672985,
        8071: 3672985,
        8072: 3672985,
        8073: 3672985,
        8074: 3672985,
        8075: 3672985,
        8076: 3672985,
        8077: 3672985,
        8078: 3672985,
        8079: 3672985,
        8080: 3672980,
        8081: 3672980,
        8082: 3672980,
        8083: 3672980,
        8084: 3672980,
        8100: 3673031,
        8101: 3673031,
        8102: 3673031,
        8103: 3673031,
        8104: 3673031,
        8105: 3673031,
        8106: 3673031,
        8107: 3673031,
        8108: 3673031,
        8109: 3673031,
        8110: 5482969,
        8111: 3673031,
        8112: 5482986,
        8113: 5483003,
        8114: 3673014,
        8115: 3673031,
        8116: 5483020,
        8117: 3673031,
        8118: 5483039,
        8200: 5481461,
        8201: 5481461,
        8202: 5481461,
        8203: 5481461,
        8204: 5481461,
        8205: 5481461,
        8206: 5481461,
        8207: 5481461,
        8208: 5481461,
        8209: 5481461,
        8210: 5483059,
        8211: 5483077,
        8212: 5483096,
        8213: 5483116,
        8214: 5481461,
        8215: 5483134,
        8216: 5481461,
        8217: 5483152,
        8300: 5481507,
        8301: 5481461,
        8302: 5481461,
        8303: 5481461,
        8304: 5481461,
        8305: 5481461,
        8306: 5481461,
        8307: 5481461,
        8308: 5481461,
        8309: 5481461,
        8310: 5481461,
        8311: 5481461,
        8312: 5481461,
        8313: 5481461,
        8314: 5481461,
        8315: 5481461,
        8316: 5481461,
        8317: 5481461,
        8400: 5481539,
        8401: 5481539,
        8402: 5481539,
        8403: 5481539,
        8404: 5481539,
        9020: 3673037,
        9021: 3673037,
        9022: 5483171,
        9023: 3673037,
        9024: 3673037,
        9026: 3673037,
        9027: 3673037,
        9028: 3673037,
        9029: 5483187,
        9030: 3673037,
        9031: 3673037,
        9032: 3673037,
        9033: 3673037,
        9034: 3673037,
        9035: 3673037,
        9040: 3673011,
        9041: 3673011,
        9042: 3673011,
        9043: 3673011,
        9044: 3673011,
        9045: 3673011,
        9046: 3673011,
        9047: 3673011,
        9060: 3672998,
        9061: 3672998,
        9062: 3672998,
        9063: 3672998,
        9064: 3672998,
        9065: 3672998,
      },
      mobile: {
        1020: 3791802,
        1040: 3791786,
        1050: 3791781,
        1060: 3791819,
        1080: 3791791,
        1100: 3791811,
        1200: 3791804,
        1210: 3791804,
        1220: 3791804,
        1340: 3791813,
        1360: 3791805,
        1361: 3791805,
        1362: 3791805,
        1363: 3791805,
        1364: 3791805,
        1365: 3791805,
        1366: 3791805,
        1367: 3791805,
        2020: 3791915,
        2040: 3791920,
        2060: 3791921,
        2080: 3791919,
        2081: 5481557,
        2082: 5481667,
        2083: 5481689,
        2084: 5481708,
        2085: 5481728,
        2086: 5481767,
        2087: 5481790,
        2088: 5481813,
        2089: 5481832,
        2090: 5481864,
        2091: 5481882,
        2100: 3791917,
        2101: 5481970,
        2102: 5481898,
        2103: 5481916,
        2104: 5481935,
        2105: 5481951,
        2119: 3791916,
        2120: 3791916,
        2121: 3791916,
        2122: 5481589,
        2123: 3791916,
        2124: 3791916,
        2125: 3791916,
        2126: 3791916,
        2127: 3791916,
        2128: 3791916,
        2129: 3791916,
        2130: 3791916,
        2131: 3791916,
        2132: 3791916,
        2133: 3791916,
        2134: 3791916,
        2135: 3791916,
        2136: 3791916,
        2137: 3791916,
        2138: 3791916,
        2139: 3791916,
        2140: 3791807,
        2141: 5481625,
        2142: 3791807,
        2143: 3791807,
        2144: 3791807,
        2145: 3791807,
        2146: 3791807,
        2147: 3791807,
        2148: 3791807,
        2149: 3791807,
        2150: 3791807,
        2151: 3791807,
        2152: 3791807,
        2153: 3791807,
        2154: 5481647,
        2155: 3791807,
        2156: 3791807,
        2157: 3791807,
        2158: 3791807,
        2159: 3791807,
        2160: 3791916,
        2161: 5481607,
        2180: 3791785,
        2181: 3791785,
        2182: 3791785,
        2183: 3791785,
        2184: 3791785,
        2185: 3791785,
        2186: 3791785,
        2187: 3791785,
        2188: 3791785,
        2189: 3791785,
        2190: 3791785,
        2191: 3791785,
        2310: 3791918,
        2410: 3791775,
        2411: 3791775,
        2412: 3791775,
        2413: 3791775,
        2414: 3791775,
        2415: 3791775,
        2460: 3791824,
        3001: 3791777,
        3002: 3791777,
        3003: 3791777,
        3004: 3791777,
        3005: 3791777,
        3006: 3791777,
        3007: 3791777,
        3008: 3791777,
        3020: 3791810,
        3021: 3791810,
        3022: 3791810,
        3023: 3791810,
        3024: 3791810,
        3025: 3791810,
        3040: 3791774,
        3041: 5481987,
        3042: 5482005,
        3043: 5482021,
        3044: 5482036,
        3045: 3791803,
        3046: 5482052,
        3047: 5482085,
        3048: 5482070,
        3050: 3791823,
        3051: 3791823,
        3052: 3791823,
        3053: 3791823,
        3054: 3791823,
        3055: 3791823,
        3056: 3791823,
        3057: 3791823,
        3060: 3791788,
        3061: 3791788,
        3062: 3791788,
        3063: 3791788,
        3064: 3791788,
        3065: 3791788,
        3066: 3791788,
        3067: 3791788,
        3071: 5482101,
        3072: 5482121,
        3073: 5482138,
        3074: 5482161,
        3075: 5482185,
        3076: 5482204,
        3080: 3791817,
        3081: 3791817,
        3082: 3791817,
        3083: 3791817,
        3084: 3791817,
        3085: 3791817,
        3100: 3791778,
        3101: 3791778,
        3102: 3791778,
        3103: 3791778,
        3104: 3791778,
        3105: 3791778,
        3106: 3791778,
        3107: 3791778,
        3108: 3791778,
        3109: 3791778,
        3360: 3791779,
        3361: 5482376,
        3362: 3791779,
        3363: 3791779,
        3364: 3791779,
        3365: 3791779,
        3366: 3791779,
        3367: 3791779,
        3368: 3791779,
        3423: 3791797,
        3424: 3791797,
        3425: 3791797,
        3426: 3791797,
        3427: 3791797,
        3428: 3791797,
        3461: 3791789,
        3462: 3791790,
        3463: 3791789,
        3464: 3791789,
        3465: 3791789,
        3466: 3791789,
        3467: 3791789,
        3468: 3791789,
        3469: 3791789,
        3470: 3791789,
        3471: 5482221,
        3472: 5482247,
        3473: 5482266,
        3474: 5482285,
        3475: 5482303,
        3476: 5482323,
        3477: 5482340,
        3478: 5482359,
        4020: 3791821,
        4021: 3791821,
        4022: 3791821,
        4023: 3791821,
        4024: 3791821,
        4040: 3791809,
        4041: 3791809,
        4042: 3791809,
        4043: 3791809,
        4044: 3791809,
        4045: 3791809,
        4046: 3791809,
        4047: 3791809,
        4048: 3791809,
        4049: 3791809,
        4050: 3791809,
        4060: 3791784,
        4061: 3791784,
        4062: 3791784,
        4063: 3791784,
        4064: 3791787,
        4080: 3791825,
        4081: 3791825,
        4082: 3791825,
        4083: 3791825,
        4084: 3791825,
        4100: 3791814,
        4101: 5482672,
        4102: 5482620,
        4103: 3791814,
        4104: 5482564,
        4105: 5482689,
        4106: 5482603,
        4107: 5482529,
        4108: 5482547,
        4109: 5482585,
        4110: 5482638,
        4111: 5482655,
        4140: 3791793,
        4141: 5482864,
        4142: 5482902,
        4143: 5482725,
        4144: 5482822,
        4145: 5482920,
        4146: 5482785,
        4147: 5482882,
        4148: 5482706,
        4149: 5482750,
        4150: 5482767,
        4151: 5482803,
        4152: 5482843,
        4301: 3791784,
        4302: 3791784,
        4303: 3791784,
        4304: 3791784,
        4305: 3791784,
        4310: 3791796,
        4311: 3791796,
        4312: 3791796,
        4313: 3791796,
        4314: 3791796,
        4315: 3791796,
        4316: 5482452,
        4317: 5482469,
        4318: 5482487,
        4319: 5482508,
        4320: 3791784,
        4321: 3791784,
        4322: 3791784,
        4323: 3791784,
        4324: 3791784,
        4325: 3791784,
        4326: 3791784,
        4327: 3791784,
        4340: 3791798,
        4341: 3791798,
        4342: 3791798,
        4343: 3791798,
        4344: 3791798,
        4345: 3791798,
        4346: 3791798,
        4347: 3791798,
        4348: 3791798,
        4360: 3791794,
        4361: 5482939,
        4362: 3791794,
        4363: 3791794,
        4364: 3791794,
        4365: 5482957,
        4380: 3791783,
        4381: 3791783,
        4382: 3791783,
        4383: 3791783,
        4384: 3791783,
        4385: 3791783,
        4386: 3791783,
        4387: 3791783,
        4390: 3791800,
        4391: 3791800,
        4392: 3791800,
        4393: 3791800,
        4394: 3791800,
        4395: 3791800,
        4396: 3791800,
        4397: 3791800,
        4398: 3791800,
        4399: 3791800,
        4400: 3791800,
        4401: 3791800,
        4402: 3791800,
        4403: 3791800,
        4460: 3791767,
        4461: 3791767,
        4462: 3791767,
        4463: 3791767,
        4464: 3791767,
        4465: 3791767,
        4466: 3791767,
        4467: 3791767,
        5001: 3791815,
        5002: 3791815,
        5003: 3791815,
        5004: 3791815,
        5005: 3791815,
        5006: 3791815,
        5007: 3791815,
        5008: 3791815,
        5009: 3791815,
        5010: 3791808,
        5020: 3791806,
        5021: 5482413,
        5022: 3791806,
        5023: 3791806,
        5024: 3791806,
        5040: 3791822,
        5041: 3791780,
        5042: 3791822,
        5043: 3791822,
        5044: 3791822,
        5045: 3791822,
        5046: 3791822,
        5047: 5482432,
        5048: 3791780,
        5049: 3791780,
        5050: 3791780,
        5051: 3791780,
        5052: 3791780,
        5053: 3791780,
        5054: 3791822,
        5055: 3791822,
        5060: 3791815,
        5070: 3791815,
        5080: 3791801,
        5081: 3791801,
        5082: 3791801,
        5083: 3791801,
        5084: 3791801,
        5085: 3791801,
        5086: 3791801,
        5320: 3791820,
        5321: 3791820,
        5322: 3791820,
        5323: 3791820,
        5324: 3791820,
        5325: 3791820,
        5330: 3791792,
        5360: 3791815,
        5370: 3791815,
        6020: 3791765,
        6021: 3791765,
        6022: 3791765,
        6023: 3791765,
        6024: 3791765,
        6025: 3791765,
        6026: 3791765,
        6027: 3791765,
        6028: 3791765,
        6029: 3791765,
        6030: 3791765,
        6031: 3791765,
        6032: 3791765,
        6033: 3791765,
        6034: 3791765,
        6035: 3791765,
        6036: 3791765,
        6037: 3791765,
        6038: 3791765,
        6039: 3791765,
        6041: 3791765,
        6062: 3791765,
        6063: 3791765,
        6064: 3791765,
        6065: 3791766,
        6066: 3791766,
        6067: 3791766,
        8030: 3791816,
        8031: 3791816,
        8032: 3791816,
        8033: 3791816,
        8034: 3791816,
        8035: 3791816,
        8036: 3791816,
        8044: 3791816,
        8061: 3791773,
        8062: 3791771,
        8063: 3791770,
        8064: 3791769,
        8065: 3791772,
        8066: 3791776,
        8067: 3791768,
        8068: 3791799,
        8069: 3791773,
        8070: 3791773,
        8071: 3791773,
        8072: 3791773,
        8073: 3791773,
        8074: 3791773,
        8075: 3791773,
        8076: 3791773,
        8077: 3791773,
        8078: 3791773,
        8079: 3791773,
        8080: 3791769,
        8081: 3791769,
        8082: 3791769,
        8083: 3791769,
        8084: 3791769,
        8100: 3791812,
        8101: 3791795,
        8102: 3791795,
        8103: 3791795,
        8104: 3791795,
        8105: 3791795,
        8106: 3791795,
        8107: 3791795,
        8108: 3791795,
        8109: 3791795,
        8110: 5482974,
        8111: 3791795,
        8112: 5482991,
        8113: 5483008,
        8114: 3791795,
        8115: 3791795,
        8116: 5483026,
        8117: 3791795,
        8118: 5483044,
        8200: 5481494,
        8201: 5481494,
        8202: 5481494,
        8203: 5481494,
        8204: 5481494,
        8205: 5481494,
        8206: 5481494,
        8207: 5481494,
        8208: 5481494,
        8209: 5481494,
        8210: 5483064,
        8211: 5483082,
        8212: 5483104,
        8213: 5483121,
        8214: 5481494,
        8215: 5483140,
        8216: 5481494,
        8217: 5483157,
        8300: 5481513,
        8301: 5481513,
        8302: 5481513,
        8303: 5481513,
        8304: 5481513,
        8305: 5481513,
        8306: 5481513,
        8307: 5481513,
        8308: 5481513,
        8309: 5481513,
        8310: 5481513,
        8311: 5481513,
        8312: 5481513,
        8313: 5481513,
        8314: 5481513,
        8315: 5481513,
        8316: 5481513,
        8317: 5481513,
        8400: 5481544,
        8401: 5481544,
        8402: 5481544,
        8403: 5481544,
        8404: 5481544,
        9020: 3791818,
        9021: 3791818,
        9022: 5483176,
        9023: 3791818,
        9024: 3791818,
        9026: 3791818,
        9027: 3791818,
        9028: 3791818,
        9029: 5483192,
        9030: 3791818,
        9031: 3791818,
        9032: 3791818,
        9033: 3791818,
        9034: 3791818,
        9035: 3791818,
        9040: 3791792,
        9041: 3791792,
        9042: 3791792,
        9043: 3791792,
        9044: 3791792,
        9045: 3791792,
        9046: 3791792,
        9047: 3791792,
        9060: 3791782,
        9061: 3791782,
        9062: 3791782,
        9063: 3791782,
        9064: 3791782,
        9065: 3791782,
      },
      fallbacks: { desktop: fallbacks['970x250'], mobile: fallbacks['300x250'] },
    },
    r_side: {
      desktop: {
        1020: 3673091,
        1040: 3673074,
        1050: 3673068,
        1060: 3673110,
        1080: 3673079,
        1100: 3673101,
        1200: 3673093,
        1210: 3673093,
        1220: 3673093,
        1340: 3673104,
        1360: 3673094,
        1361: 3673094,
        1362: 3673094,
        1363: 3673094,
        1364: 3673094,
        1365: 3673094,
        1366: 3673094,
        1367: 3673094,
        2020: 3673048,
        2040: 3673096,
        2060: 3673116,
        2080: 3673073,
        2081: 5481553,
        2082: 5481661,
        2083: 5481685,
        2084: 5481703,
        2085: 5481723,
        2086: 5481742,
        2087: 5481783,
        2088: 5481808,
        2089: 5481826,
        2090: 5481858,
        2091: 5481877,
        2100: 3673061,
        2101: 5481964,
        2102: 5481894,
        2103: 5481910,
        2104: 5481930,
        2105: 5481946,
        2119: 3673049,
        2120: 3673049,
        2121: 3673049,
        2122: 5481566,
        2123: 3673049,
        2124: 3673049,
        2125: 3673049,
        2126: 3673049,
        2127: 3673049,
        2128: 3673049,
        2129: 3673049,
        2130: 3673049,
        2131: 3673049,
        2132: 3673049,
        2133: 3673049,
        2134: 3673049,
        2135: 3673049,
        2136: 3673049,
        2137: 3673049,
        2138: 3673049,
        2139: 3673049,
        2140: 3673097,
        2141: 5481621,
        2142: 3673097,
        2143: 3673097,
        2144: 3673097,
        2145: 3673097,
        2146: 3673097,
        2147: 3673097,
        2148: 3673097,
        2149: 3673097,
        2150: 3673097,
        2151: 3673097,
        2152: 3673097,
        2153: 3673097,
        2154: 5481643,
        2155: 3673097,
        2156: 3673097,
        2157: 3673097,
        2158: 3673097,
        2159: 3673097,
        2160: 3673049,
        2161: 5481603,
        2180: 3673072,
        2181: 3673072,
        2182: 3673072,
        2183: 3673072,
        2184: 3673072,
        2185: 3673072,
        2186: 3673072,
        2187: 3673072,
        2188: 3673072,
        2189: 3673072,
        2190: 3673072,
        2191: 3673072,
        2310: 3673066,
        2410: 3673060,
        2411: 3673060,
        2412: 3673060,
        2413: 3673060,
        2414: 3673060,
        2415: 3673060,
        2460: 3673115,
        3001: 3673063,
        3002: 3673063,
        3003: 3673063,
        3004: 3673063,
        3005: 3673063,
        3006: 3673063,
        3007: 3673063,
        3008: 3673063,
        3020: 3673100,
        3021: 3673100,
        3022: 3673100,
        3023: 3673100,
        3024: 3673100,
        3025: 3673100,
        3040: 3673059,
        3041: 5481982,
        3042: 5482001,
        3043: 5482016,
        3044: 5482032,
        3045: 3673092,
        3046: 5482048,
        3047: 5482081,
        3048: 5482063,
        3050: 3673114,
        3051: 3673114,
        3052: 3673114,
        3053: 3673114,
        3054: 3673114,
        3055: 3673114,
        3056: 3673114,
        3057: 3673114,
        3060: 3673076,
        3061: 3673076,
        3062: 3673076,
        3063: 3673076,
        3064: 3673076,
        3065: 3673076,
        3066: 3673076,
        3067: 3673076,
        3071: 5482097,
        3072: 5482116,
        3073: 5482134,
        3074: 5482157,
        3075: 5482181,
        3076: 5482200,
        3080: 3673108,
        3081: 3673108,
        3082: 3673108,
        3083: 3673108,
        3084: 3673108,
        3085: 3673108,
        3100: 3673064,
        3101: 3673064,
        3102: 3673064,
        3103: 3673064,
        3104: 3673064,
        3105: 3673064,
        3106: 3673064,
        3107: 3673064,
        3108: 3673064,
        3109: 3673064,
        3360: 3673065,
        3361: 5482372,
        3362: 3673065,
        3363: 3673065,
        3364: 3673065,
        3365: 3673065,
        3366: 3673065,
        3367: 3673065,
        3368: 3673065,
        3423: 3673086,
        3424: 3673086,
        3425: 3673086,
        3426: 3673086,
        3427: 3673086,
        3428: 3673086,
        3461: 3673077,
        3462: 3673078,
        3463: 3673077,
        3464: 3673077,
        3465: 3673077,
        3466: 3673077,
        3467: 3673077,
        3468: 3673077,
        3469: 3673077,
        3470: 3673077,
        3471: 5482217,
        3472: 5482243,
        3473: 5482262,
        3474: 5482280,
        3475: 5482299,
        3476: 5482318,
        3477: 5482336,
        3478: 5482355,
        4020: 3673112,
        4021: 3673112,
        4022: 3673112,
        4023: 3673112,
        4024: 3673112,
        4040: 3673099,
        4041: 3673099,
        4042: 3673099,
        4043: 3673099,
        4044: 3673099,
        4045: 3673099,
        4046: 3673099,
        4047: 3673099,
        4048: 3673099,
        4049: 3673099,
        4050: 3673099,
        4060: 3673071,
        4061: 3673071,
        4062: 3673071,
        4063: 3673071,
        4064: 3673075,
        4080: 3673117,
        4081: 3673117,
        4082: 3673117,
        4083: 3673117,
        4084: 3673117,
        4100: 3673105,
        4101: 5482668,
        4102: 5482616,
        4103: 3673105,
        4104: 5482560,
        4105: 5482685,
        4106: 5482599,
        4107: 5482525,
        4108: 5482543,
        4109: 5482581,
        4110: 5482634,
        4111: 5482651,
        4140: 3673081,
        4141: 5482858,
        4142: 5482898,
        4143: 5482721,
        4144: 5482817,
        4145: 5482916,
        4146: 5482781,
        4147: 5482878,
        4148: 5482702,
        4149: 5482745,
        4150: 5482763,
        4151: 5482799,
        4152: 5482839,
        4301: 3673071,
        4302: 3673071,
        4303: 3673071,
        4304: 3673071,
        4305: 3673071,
        4310: 3673085,
        4311: 3673085,
        4312: 3673085,
        4313: 3673085,
        4314: 3673085,
        4315: 3673085,
        4316: 5482445,
        4317: 5482465,
        4318: 5482482,
        4319: 5482504,
        4320: 3673071,
        4321: 3673071,
        4322: 3673071,
        4323: 3673071,
        4324: 3673071,
        4325: 3673071,
        4326: 3673071,
        4327: 3673071,
        4340: 3673087,
        4341: 3673087,
        4342: 3673087,
        4343: 3673087,
        4344: 3673087,
        4345: 3673087,
        4346: 3673087,
        4347: 3673087,
        4348: 3673087,
        4360: 3673082,
        4361: 5482934,
        4362: 3673082,
        4363: 3673082,
        4364: 3673082,
        4365: 5482953,
        4380: 3673070,
        4381: 3673070,
        4382: 3673070,
        4383: 3673070,
        4384: 3673070,
        4385: 3673070,
        4386: 3673070,
        4387: 3673070,
        4390: 3673089,
        4391: 3673089,
        4392: 3673089,
        4393: 3673089,
        4394: 3673089,
        4395: 3673089,
        4396: 3673089,
        4397: 3673089,
        4398: 3673089,
        4399: 3673089,
        4400: 3673089,
        4401: 3673089,
        4402: 3673089,
        4403: 3673089,
        4460: 3673047,
        4461: 3673047,
        4462: 3673047,
        4463: 3673047,
        4464: 3673047,
        4465: 3673047,
        4466: 3673047,
        4467: 3673047,
        5001: 3673106,
        5002: 3673106,
        5003: 3673106,
        5004: 3673106,
        5005: 3673106,
        5006: 3673106,
        5007: 3673106,
        5008: 3673106,
        5009: 3673106,
        5010: 3673098,
        5020: 3673095,
        5021: 5482407,
        5022: 3673095,
        5023: 3673095,
        5024: 3673095,
        5040: 3673113,
        5041: 3673067,
        5042: 3673113,
        5043: 3673113,
        5044: 3673113,
        5045: 3673113,
        5046: 3673113,
        5047: 5482428,
        5048: 3673067,
        5049: 3673067,
        5050: 3673067,
        5051: 3673067,
        5052: 3673067,
        5053: 3673067,
        5054: 3673113,
        5055: 3673113,
        5060: 3673106,
        5070: 3673106,
        5080: 3673090,
        5081: 3673090,
        5082: 3673090,
        5083: 3673090,
        5084: 3673090,
        5085: 3673090,
        5086: 3673090,
        5320: 3673111,
        5321: 3673111,
        5322: 3673111,
        5323: 3673111,
        5324: 3673111,
        5325: 3673111,
        5330: 3673080,
        5360: 3673106,
        5370: 3673106,
        6020: 3673045,
        6021: 3673045,
        6022: 3673045,
        6023: 3673045,
        6024: 3673045,
        6025: 3673045,
        6026: 3673045,
        6027: 3673045,
        6028: 3673045,
        6029: 3673045,
        6030: 3673045,
        6031: 3673045,
        6032: 3673045,
        6033: 3673045,
        6034: 3673045,
        6035: 3673045,
        6036: 3673045,
        6037: 3673045,
        6038: 3673045,
        6039: 3673045,
        6041: 3673045,
        6062: 3673045,
        6063: 3673045,
        6064: 3673045,
        6065: 3673046,
        6066: 3673046,
        6067: 3673046,
        8030: 3673107,
        8031: 3673107,
        8032: 3673107,
        8033: 3673107,
        8034: 3673107,
        8035: 3673107,
        8036: 3673107,
        8044: 3673107,
        8061: 3673057,
        8062: 3673054,
        8063: 3673052,
        8064: 3673051,
        8065: 3673056,
        8066: 3673062,
        8067: 3673050,
        8068: 3673088,
        8069: 3673057,
        8070: 3673057,
        8071: 3673057,
        8072: 3673057,
        8073: 3673057,
        8074: 3673057,
        8075: 3673057,
        8076: 3673057,
        8077: 3673057,
        8078: 3673057,
        8079: 3673057,
        8080: 3673051,
        8081: 3673051,
        8082: 3673051,
        8083: 3673051,
        8084: 3673051,
        8100: 3673102,
        8101: 3673102,
        8102: 3673102,
        8103: 3673102,
        8104: 3673102,
        8105: 3673102,
        8106: 3673102,
        8107: 3673102,
        8108: 3673102,
        8109: 3673102,
        8110: 5482970,
        8111: 3673102,
        8112: 5482987,
        8113: 5483004,
        8114: 3673083,
        8115: 3673102,
        8116: 5483022,
        8117: 3673102,
        8118: 5483040,
        8200: 5481472,
        8201: 5481472,
        8202: 5481472,
        8203: 5481472,
        8204: 5481472,
        8205: 5481472,
        8206: 5481472,
        8207: 5481472,
        8208: 5481472,
        8209: 5481472,
        8210: 5483060,
        8211: 5483078,
        8212: 5483099,
        8213: 5483117,
        8214: 5481472,
        8215: 5483135,
        8216: 5481472,
        8217: 5483153,
        8300: 5481508,
        8301: 5481508,
        8302: 5481508,
        8303: 5481508,
        8304: 5481508,
        8305: 5481508,
        8306: 5481508,
        8307: 5481508,
        8308: 5481508,
        8309: 5481508,
        8310: 5481508,
        8311: 5481508,
        8312: 5481508,
        8313: 5481508,
        8314: 5481508,
        8315: 5481508,
        8316: 5481508,
        8317: 5481508,
        8400: 5481540,
        8401: 5481540,
        8402: 5481540,
        8403: 5481540,
        8404: 5481540,
        9020: 3673109,
        9021: 3673109,
        9022: 5483172,
        9023: 3673109,
        9024: 3673109,
        9026: 3673109,
        9027: 3673109,
        9028: 3673109,
        9029: 5483188,
        9030: 3673109,
        9031: 3673109,
        9032: 3673109,
        9033: 3673109,
        9034: 3673109,
        9035: 3673109,
        9040: 3673080,
        9041: 3673080,
        9042: 3673080,
        9043: 3673080,
        9044: 3673080,
        9045: 3673080,
        9046: 3673080,
        9047: 3673080,
        9060: 3673069,
        9061: 3673069,
        9062: 3673069,
        9063: 3673069,
        9064: 3673069,
        9065: 3673069,
      },
      mobile: {
        1020: 3791868,
        1040: 3791852,
        1050: 3791846,
        1060: 3791887,
        1080: 3791857,
        1100: 3791879,
        1200: 3791871,
        1210: 3791871,
        1220: 3791871,
        1340: 3791881,
        1360: 3791872,
        1361: 3791872,
        1362: 3791872,
        1363: 3791872,
        1364: 3791872,
        1365: 3791872,
        1366: 3791872,
        1367: 3791872,
        2020: 3791829,
        2040: 3791874,
        2060: 3791893,
        2080: 3791851,
        2081: 5481558,
        2082: 5481668,
        2083: 5481691,
        2084: 5481710,
        2085: 5481731,
        2086: 5481768,
        2087: 5481791,
        2088: 5481815,
        2089: 5481833,
        2090: 5481865,
        2091: 5481883,
        2100: 3791839,
        2101: 5481971,
        2102: 5481899,
        2103: 5481918,
        2104: 5481936,
        2105: 5481952,
        2119: 3791830,
        2120: 3791830,
        2121: 3791830,
        2122: 5481590,
        2123: 3791830,
        2124: 3791830,
        2125: 3791830,
        2126: 3791830,
        2127: 3791830,
        2128: 3791830,
        2129: 3791830,
        2130: 3791830,
        2131: 3791830,
        2132: 3791830,
        2133: 3791830,
        2134: 3791830,
        2135: 3791830,
        2136: 3791830,
        2137: 3791830,
        2138: 3791830,
        2139: 3791830,
        2140: 3791875,
        2141: 5481626,
        2142: 3791875,
        2143: 3791875,
        2144: 3791875,
        2145: 3791875,
        2146: 3791875,
        2147: 3791875,
        2148: 3791875,
        2149: 3791875,
        2150: 3791875,
        2151: 3791875,
        2152: 3791875,
        2153: 3791875,
        2154: 5481648,
        2155: 3791875,
        2156: 3791875,
        2157: 3791875,
        2158: 3791875,
        2159: 3791875,
        2160: 3791830,
        2161: 5481608,
        2180: 3791850,
        2181: 3791850,
        2182: 3791850,
        2183: 3791850,
        2184: 3791850,
        2185: 3791850,
        2186: 3791850,
        2187: 3791850,
        2188: 3791850,
        2189: 3791850,
        2190: 3791850,
        2191: 3791850,
        2310: 3791844,
        2410: 3791838,
        2411: 3791838,
        2412: 3791838,
        2413: 3791838,
        2414: 3791838,
        2415: 3791838,
        2460: 3791892,
        3001: 3791841,
        3002: 3791841,
        3003: 3791841,
        3004: 3791841,
        3005: 3791841,
        3006: 3791841,
        3007: 3791841,
        3008: 3791841,
        3020: 3791878,
        3021: 3791878,
        3022: 3791878,
        3023: 3791878,
        3024: 3791878,
        3025: 3791878,
        3040: 3791837,
        3041: 5481988,
        3042: 5482006,
        3043: 5482022,
        3044: 5482037,
        3045: 3791870,
        3046: 5482053,
        3047: 5482086,
        3048: 5482071,
        3050: 3791891,
        3051: 3791891,
        3052: 3791891,
        3053: 3791891,
        3054: 3791891,
        3055: 3791891,
        3056: 3791891,
        3057: 3791891,
        3060: 3791854,
        3061: 3791854,
        3062: 3791854,
        3063: 3791854,
        3064: 3791854,
        3065: 3791854,
        3066: 3791854,
        3067: 3791854,
        3071: 5482102,
        3072: 5482122,
        3073: 5482139,
        3074: 5482162,
        3075: 5482186,
        3076: 5482205,
        3080: 3791885,
        3081: 3791885,
        3082: 3791885,
        3083: 3791885,
        3084: 3791885,
        3085: 3791885,
        3100: 3791842,
        3101: 3791842,
        3102: 3791842,
        3103: 3791842,
        3104: 3791842,
        3105: 3791842,
        3106: 3791842,
        3107: 3791842,
        3108: 3791842,
        3109: 3791842,
        3360: 3791843,
        3361: 5482377,
        3362: 3791843,
        3363: 3791843,
        3364: 3791843,
        3365: 3791843,
        3366: 3791843,
        3367: 3791843,
        3368: 3791843,
        3423: 3791863,
        3424: 3791863,
        3425: 3791863,
        3426: 3791863,
        3427: 3791863,
        3428: 3791863,
        3461: 3791855,
        3462: 3791856,
        3463: 3791855,
        3464: 3791855,
        3465: 3791855,
        3466: 3791855,
        3467: 3791855,
        3468: 3791855,
        3469: 3791855,
        3470: 3791855,
        3471: 5482222,
        3472: 5482248,
        3473: 5482267,
        3474: 5482286,
        3475: 5482305,
        3476: 5482324,
        3477: 5482341,
        3478: 5482360,
        4020: 3791889,
        4021: 3791889,
        4022: 3791889,
        4023: 3791889,
        4024: 3791889,
        4040: 3791877,
        4041: 3791877,
        4042: 3791877,
        4043: 3791877,
        4044: 3791877,
        4045: 3791877,
        4046: 3791877,
        4047: 3791877,
        4048: 3791877,
        4049: 3791877,
        4050: 3791877,
        4060: 3791784,
        4061: 3791849,
        4062: 3791849,
        4063: 3791849,
        4064: 3791853,
        4080: 3791894,
        4081: 3791894,
        4082: 3791894,
        4083: 3791894,
        4084: 3791825,
        4100: 3791882,
        4101: 5482673,
        4102: 5482621,
        4103: 3791882,
        4104: 5482568,
        4105: 5482690,
        4106: 5482604,
        4107: 5482530,
        4108: 5482548,
        4109: 5482586,
        4110: 5482639,
        4111: 5482656,
        4140: 3791859,
        4141: 5482865,
        4142: 5482904,
        4143: 5482726,
        4144: 5482823,
        4145: 5482921,
        4146: 5482786,
        4147: 5482883,
        4148: 5482707,
        4149: 5482751,
        4150: 5482768,
        4151: 5482804,
        4152: 5482844,
        4301: 3791849,
        4302: 3791849,
        4303: 3791849,
        4304: 3791849,
        4305: 3791849,
        4310: 3791862,
        4311: 3791862,
        4312: 3791862,
        4313: 3791862,
        4314: 3791862,
        4315: 3791862,
        4316: 5482453,
        4317: 5482470,
        4318: 5482488,
        4319: 5482510,
        4320: 3791849,
        4321: 3791849,
        4322: 3791849,
        4323: 3791849,
        4324: 3791849,
        4325: 3791849,
        4326: 3791849,
        4327: 3791849,
        4340: 3791864,
        4341: 3791864,
        4342: 3791864,
        4343: 3791864,
        4344: 3791798,
        4345: 3791798,
        4346: 3791798,
        4347: 3791798,
        4348: 3791798,
        4360: 3791860,
        4361: 5482940,
        4362: 3791860,
        4363: 3791860,
        4364: 3791794,
        4365: 5482958,
        4380: 3791848,
        4381: 3791848,
        4382: 3791848,
        4383: 3791848,
        4384: 3791848,
        4385: 3791848,
        4386: 3791783,
        4387: 3791783,
        4390: 3791866,
        4391: 3791866,
        4392: 3791866,
        4393: 3791866,
        4394: 3791866,
        4395: 3791866,
        4396: 3791866,
        4397: 3791866,
        4398: 3791866,
        4399: 3791866,
        4400: 3791866,
        4401: 3791866,
        4402: 3791866,
        4403: 3791800,
        4460: 3791828,
        4461: 3791828,
        4462: 3791828,
        4463: 3791828,
        4464: 3791828,
        4465: 3791828,
        4466: 3791767,
        4467: 3791767,
        5001: 3791883,
        5002: 3791883,
        5003: 3791883,
        5004: 3791883,
        5005: 3791883,
        5006: 3791883,
        5007: 3791883,
        5008: 3791883,
        5009: 3791883,
        5010: 3791876,
        5020: 3791873,
        5021: 5482414,
        5022: 3791873,
        5023: 3791873,
        5024: 3791873,
        5040: 3791890,
        5041: 3791845,
        5042: 3791890,
        5043: 3791890,
        5044: 3791890,
        5045: 3791890,
        5046: 3791890,
        5047: 5482433,
        5048: 3791845,
        5049: 3791845,
        5050: 3791845,
        5051: 3791845,
        5052: 3791845,
        5053: 3791845,
        5054: 3791890,
        5055: 3791890,
        5060: 3791883,
        5070: 3791883,
        5080: 3791867,
        5081: 3791867,
        5082: 3791867,
        5083: 3791867,
        5084: 3791867,
        5085: 3791867,
        5086: 3791867,
        5320: 3791888,
        5321: 3791888,
        5322: 3791888,
        5323: 3791888,
        5324: 3791888,
        5325: 3791888,
        5330: 3791858,
        5360: 3791883,
        5370: 3791883,
        6020: 3791826,
        6021: 3791826,
        6022: 3791826,
        6023: 3791826,
        6024: 3791826,
        6025: 3791826,
        6026: 3791826,
        6027: 3791826,
        6028: 3791826,
        6029: 3791826,
        6030: 3791826,
        6031: 3791826,
        6032: 3791826,
        6033: 3791826,
        6034: 3791826,
        6035: 3791826,
        6036: 3791826,
        6037: 3791826,
        6038: 3791826,
        6039: 3791826,
        6041: 3791826,
        6062: 3791826,
        6063: 3791826,
        6064: 3791826,
        6065: 3791827,
        6066: 3791827,
        6067: 3791827,
        8030: 3791884,
        8031: 3791884,
        8032: 3791884,
        8033: 3791884,
        8034: 3791884,
        8035: 3791884,
        8036: 3791884,
        8044: 3791884,
        8061: 3791836,
        8062: 3791834,
        8063: 3791833,
        8064: 3791832,
        8065: 3791835,
        8066: 3791840,
        8067: 3791831,
        8068: 3791865,
        8069: 3791836,
        8070: 3791836,
        8071: 3791836,
        8072: 3791836,
        8073: 3791836,
        8074: 3791836,
        8075: 3791836,
        8076: 3791836,
        8077: 3791836,
        8078: 3791836,
        8079: 3791836,
        8080: 3791832,
        8081: 3791832,
        8082: 3791832,
        8083: 3791832,
        8084: 3791832,
        8100: 3791880,
        8101: 3791812,
        8102: 3791812,
        8103: 3791812,
        8104: 3791812,
        8105: 3791812,
        8106: 3791812,
        8107: 3791812,
        8108: 3791812,
        8109: 3791812,
        8110: 5482975,
        8111: 3791812,
        8112: 5482992,
        8113: 5483009,
        8114: 3791861,
        8115: 3791812,
        8116: 5483027,
        8117: 3791812,
        8118: 5483045,
        8200: 5481495,
        8201: 5481494,
        8202: 5481494,
        8203: 5481494,
        8204: 5481494,
        8205: 5481494,
        8206: 5481494,
        8207: 5481494,
        8208: 5481494,
        8209: 5481494,
        8210: 5483065,
        8211: 5483083,
        8212: 5483105,
        8213: 5483122,
        8214: 5481494,
        8215: 5483141,
        8216: 5481494,
        8217: 5483158,
        8300: 5481514,
        8301: 5481513,
        8302: 5481513,
        8303: 5481513,
        8304: 5481513,
        8305: 5481513,
        8306: 5481513,
        8307: 5481513,
        8308: 5481513,
        8309: 5481513,
        8310: 5481513,
        8311: 5481513,
        8312: 5481513,
        8313: 5481513,
        8314: 5481513,
        8315: 5481513,
        8316: 5481513,
        8317: 5481513,
        8400: 5481545,
        8401: 5481544,
        8402: 5481544,
        8403: 5481544,
        8404: 5481544,
        9020: 3791886,
        9021: 3791886,
        9022: 5483177,
        9023: 3791886,
        9024: 3791886,
        9026: 3791886,
        9027: 3791886,
        9028: 3791886,
        9029: 5483193,
        9030: 3791886,
        9031: 3791886,
        9032: 3791886,
        9033: 3791886,
        9034: 3791886,
        9035: 3791886,
        9040: 3791858,
        9041: 3791858,
        9042: 3791858,
        9043: 3791858,
        9044: 3791858,
        9045: 3791858,
        9046: 3791858,
        9047: 3791858,
        9060: 3791847,
        9061: 3791847,
        9062: 3791847,
        9063: 3791847,
        9064: 3791847,
        9065: 3791847,
      },
      fallbacks: { desktop: fallbacks['300x250'], mobile: fallbacks['300x250'] },
    },
    r_side_extra: {
      desktop: {
        1000: 7156184,
        2000: 7156182,
        6000: 7156185,
        3000: 7156180,
        5000: 7156179,
        4000: 7156170,
        8000: 7156165,
        9000: 7156146,
      },
    },
    gallery: {
      desktop: {
        1020: 3854412,
        1040: 3854413,
        1050: 3854416,
        1060: 3854418,
        1080: 3854419,
        1100: 3854417,
        1200: 3854415,
        1210: 3854415,
        1220: 3854415,
        1340: 3854414,
        1360: 3854420,
        1361: 3854420,
        1362: 3854420,
        1363: 3854420,
        1364: 3854420,
        1365: 3854420,
        1366: 3854420,
        1367: 3854420,
        2020: 3854421,
        2040: 3854424,
        2060: 3854430,
        2080: 3854426,
        2081: 5481562,
        2082: 5481676,
        2083: 5481695,
        2084: 5481717,
        2085: 5481735,
        2086: 5481774,
        2087: 5481798,
        2088: 5481819,
        2089: 5481852,
        2090: 5481871,
        2091: 5481887,
        2100: 3854428,
        2101: 5481975,
        2102: 5481904,
        2103: 5481922,
        2104: 5481940,
        2105: 5481956,
        2119: 3854422,
        2120: 3854422,
        2121: 3854422,
        2122: 5481595,
        2123: 3854422,
        2124: 3854422,
        2125: 3854422,
        2126: 3854422,
        2127: 3854422,
        2128: 3854422,
        2129: 3854422,
        2130: 3854422,
        2131: 3854422,
        2132: 3854422,
        2133: 3854422,
        2134: 3854422,
        2135: 3854422,
        2136: 3854422,
        2137: 3854422,
        2138: 3672988,
        2139: 3854422,
        2140: 3673097,
        2141: 5481630,
        2142: 3854425,
        2143: 3854425,
        2144: 3854422,
        2145: 3854425,
        2146: 3854425,
        2147: 3854425,
        2148: 3854425,
        2149: 3854425,
        2150: 3854425,
        2151: 3854425,
        2152: 3854425,
        2153: 3854425,
        2154: 5481652,
        2155: 3854425,
        2156: 3854425,
        2157: 3854425,
        2158: 3854425,
        2159: 3854425,
        2160: 3854422,
        2161: 5481612,
        2180: 3673002,
        2181: 3673072,
        2182: 3673072,
        2183: 3673072,
        2184: 3673072,
        2185: 3673072,
        2186: 3673072,
        2187: 3673072,
        2188: 3673072,
        2189: 3673072,
        2190: 3673072,
        2191: 3673072,
        2310: 3854423,
        2410: 3673060,
        2411: 3673060,
        2412: 3673060,
        2413: 3673060,
        2414: 3673060,
        2415: 3673060,
        2460: 3854431,
        3001: 3854436,
        3002: 3854436,
        3003: 3854436,
        3004: 3854436,
        3005: 3854436,
        3006: 3854436,
        3007: 3854436,
        3008: 3854436,
        3020: 3854442,
        3021: 3854442,
        3022: 3854442,
        3023: 3854442,
        3024: 3854442,
        3025: 3854442,
        3040: 3854432,
        3041: 5481995,
        3042: 5482010,
        3043: 5482026,
        3044: 5482042,
        3045: 3854433,
        3046: 5482057,
        3047: 5482091,
        3048: 5482075,
        3050: 3854434,
        3051: 3854434,
        3052: 3854434,
        3053: 3854434,
        3054: 3854434,
        3055: 3854434,
        3056: 3854434,
        3057: 3854434,
        3060: 3854435,
        3061: 3854435,
        3062: 3854435,
        3063: 3854435,
        3064: 3854435,
        3065: 3854435,
        3066: 3854435,
        3067: 3854435,
        3071: 5482108,
        3072: 5482126,
        3073: 5482143,
        3074: 5482173,
        3075: 5482190,
        3076: 5482209,
        3080: 3854440,
        3081: 3854440,
        3083: 3854440,
        3084: 3854440,
        3085: 3854440,
        3100: 3854479,
        3101: 3854479,
        3102: 3854479,
        3103: 3854479,
        3105: 3854479,
        3106: 3854479,
        3107: 3854479,
        3108: 3854479,
        3109: 3854479,
        3360: 3854439,
        3361: 5482381,
        3362: 3854439,
        3363: 3854439,
        3364: 3854439,
        3366: 3854439,
        3367: 3854439,
        3368: 3854439,
        3423: 3854441,
        3424: 3854441,
        3425: 3854441,
        3426: 3854441,
        3427: 3854441,
        3428: 3854441,
        3461: 3854437,
        3462: 3854438,
        3463: 3854437,
        3464: 3854437,
        3465: 3854437,
        3466: 3854437,
        3467: 3854437,
        3468: 3854437,
        3469: 3854437,
        3470: 3854437,
        3471: 5482226,
        3472: 5482252,
        3473: 5482272,
        3474: 5482291,
        3475: 5482310,
        3476: 5482328,
        3477: 5482347,
        3478: 5482364,
        4020: 3854453,
        4021: 3854453,
        4022: 3854453,
        4023: 3854453,
        4024: 3854453,
        4040: 3854451,
        4041: 3854451,
        4042: 3854451,
        4043: 3854451,
        4044: 3854451,
        4045: 3854451,
        4046: 3854451,
        4049: 3854451,
        4060: 3854445,
        4061: 3854445,
        4062: 3854445,
        4063: 3854445,
        4080: 3854478,
        4081: 3854478,
        4082: 3854478,
        4083: 3854478,
        4084: 3854478,
        4100: 3854449,
        4101: 5482677,
        4102: 5482626,
        4103: 3854449,
        4104: 5482573,
        4105: 5482694,
        4106: 5482608,
        4107: 5482534,
        4108: 5482552,
        4109: 5482590,
        4110: 5482643,
        4111: 5482661,
        4140: 3854450,
        4141: 5482869,
        4142: 5482908,
        4143: 5482736,
        4144: 5482827,
        4145: 5482925,
        4146: 5482791,
        4147: 5482889,
        4148: 5482711,
        4149: 5482755,
        4150: 5482772,
        4151: 5482809,
        4152: 5482850,
        4301: 3854445,
        4302: 3854445,
        4303: 3854445,
        4304: 3854445,
        4305: 3854445,
        4310: 3854443,
        4311: 3854443,
        4314: 3854443,
        4315: 3854443,
        4316: 5482457,
        4317: 5482474,
        4318: 5482492,
        4319: 5482514,
        4320: 3854445,
        4321: 3854445,
        4322: 3854445,
        4323: 3854445,
        4324: 3854445,
        4325: 3854445,
        4326: 3854445,
        4340: 3854452,
        4341: 3854452,
        4342: 3854452,
        4343: 3854452,
        4344: 3854452,
        4345: 3854452,
        4346: 3854452,
        4347: 3854452,
        4348: 3854452,
        4360: 3854454,
        4361: 5482945,
        4362: 3854454,
        4363: 3854454,
        4364: 3854447,
        4365: 5482962,
        4380: 3854447,
        4381: 3854447,
        4382: 3854447,
        4383: 3854447,
        4384: 3854447,
        4385: 3854447,
        4386: 3854447,
        4387: 3854447,
        4390: 3854448,
        4392: 3854448,
        4394: 3854448,
        4395: 3854448,
        4396: 3854448,
        4397: 3854448,
        4398: 3854448,
        4399: 3854448,
        4400: 3854448,
        4401: 3854448,
        4402: 3854448,
        4403: 3854448,
        4460: 3854446,
        4461: 3854446,
        4462: 3854446,
        4463: 3854446,
        4464: 3854446,
        4465: 3854446,
        4466: 3854446,
        4467: 3854446,
        5001: 3854459,
        5002: 3854459,
        5003: 3854459,
        5004: 3854459,
        5005: 3854459,
        5006: 3854459,
        5007: 3854459,
        5008: 3854459,
        5009: 3854459,
        5010: 3854461,
        5020: 3854455,
        5021: 5482418,
        5022: 3854455,
        5024: 3854455,
        5040: 3854457,
        5041: 3854456,
        5042: 3854457,
        5043: 3854457,
        5044: 3854457,
        5045: 3854457,
        5046: 3854457,
        5047: 5482437,
        5048: 3854456,
        5049: 3854456,
        5050: 3854456,
        5051: 3854456,
        5052: 3854456,
        5053: 3854456,
        5054: 3854457,
        5055: 3854457,
        5060: 3854459,
        5070: 3854459,
        5080: 3854460,
        5081: 3854460,
        5082: 3854460,
        5083: 3854460,
        5084: 3854460,
        5085: 3854460,
        5086: 3854460,
        5320: 3854458,
        5321: 3854458,
        5322: 3854458,
        5323: 3854458,
        5324: 3854458,
        5325: 3854458,
        5360: 3854459,
        5370: 3854459,
        6020: 3854462,
        6021: 3854462,
        6022: 3854462,
        6023: 3854462,
        6024: 3854462,
        6025: 3854462,
        6026: 3854462,
        6027: 3854462,
        6028: 3854462,
        6029: 3854462,
        6030: 3854462,
        6031: 3854462,
        6032: 3854462,
        6033: 3854462,
        6034: 3854462,
        6035: 3854462,
        6036: 3854462,
        6037: 3854462,
        6038: 3854462,
        6039: 3854462,
        6041: 3854462,
        8030: 3854466,
        8031: 3854466,
        8032: 3854466,
        8033: 3854466,
        8034: 3854466,
        8035: 3854466,
        8044: 3854466,
        8061: 3854467,
        8062: 3854468,
        8063: 3854469,
        8064: 3854470,
        8065: 3854471,
        8066: 3854474,
        8067: 3854472,
        8068: 3854473,
        8069: 3854467,
        8070: 3854467,
        8071: 3854467,
        8072: 3854467,
        8073: 3854467,
        8074: 3854467,
        8075: 3854467,
        8076: 3854467,
        8077: 3854467,
        8078: 3854467,
        8079: 3854467,
        8080: 3854470,
        8081: 3854470,
        8082: 3854470,
        8083: 3854470,
        8084: 3854470,
        8100: 3854464,
        8101: 3854464,
        8102: 3854464,
        8103: 3854464,
        8104: 3854464,
        8105: 3854464,
        8106: 3854464,
        8107: 3854464,
        8108: 3854464,
        8109: 3854464,
        8110: 5482979,
        8111: 3854464,
        8112: 5482996,
        8113: 5483013,
        8114: 3854465,
        8115: 3854464,
        8116: 5483031,
        8117: 3854464,
        8118: 5483049,
        8200: 5481501,
        8201: 5481501,
        8202: 5481501,
        8203: 5481501,
        8204: 5481501,
        8205: 5481501,
        8206: 5481501,
        8207: 5481501,
        8208: 5481501,
        8209: 5481501,
        8210: 5483069,
        8211: 5483087,
        8212: 5483109,
        8213: 5483127,
        8214: 5481501,
        8215: 5483145,
        8216: 5481501,
        8217: 5483164,
        8300: 5481536,
        8301: 5481536,
        8302: 5481536,
        8303: 5481536,
        8304: 5481536,
        8305: 5481536,
        8306: 5481536,
        8307: 5481536,
        8308: 5481536,
        8309: 5481536,
        8310: 5481536,
        8311: 5481536,
        8312: 5481536,
        8313: 5481536,
        8314: 5481536,
        8315: 5481536,
        8316: 5481536,
        8317: 5481536,
        8400: 5481549,
        8401: 5481549,
        8402: 5481549,
        8403: 5481549,
        8404: 5481549,
        9020: 3854475,
        9021: 3854475,
        9022: 5483181,
        9023: 3854475,
        9024: 3854475,
        9026: 3854475,
        9027: 3854475,
        9028: 3854475,
        9029: 5483197,
        9030: 3854475,
        9031: 3854475,
        9032: 3854475,
        9033: 3854475,
        9034: 3854475,
        9035: 3854475,
        9040: 3854476,
        9041: 3854476,
        9043: 3854476,
        9044: 3854476,
        9045: 3854476,
        9046: 3854476,
        9047: 3854476,
        9060: 3854477,
        9061: 3854477,
        9062: 3854477,
        9063: 3854477,
        9064: 3854477,
        9065: 3854477,
      },
      mobile: {
        1020: 7287660,
        1040: 7287657,
        1050: 7287633,
        1060: 7287667,
        1080: 7287659,
        1100: 7287665,
        1200: 7287661,
        1210: 7287661,
        1220: 7287661,
        1340: 7287666,
        1360: 7287664,
        1361: 7287664,
        1362: 7287664,
        1363: 7287664,
        1364: 7287664,
        1365: 7287664,
        1366: 7287664,
        1367: 7287664,
        2000: 7540678,
        2020: 7540678,
        2040: 7540678,
        2060: 7540678,
        2080: 7540678,
        2081: 7540678,
        2082: 7540678,
        2083: 7540678,
        2084: 7540678,
        2085: 7540678,
        2086: 7540678,
        2087: 7540678,
        2088: 7540678,
        2089: 7540678,
        2090: 7540678,
        2091: 7540678,
        2100: 7540678,
        2101: 7540678,
        2102: 7540678,
        2103: 7540678,
        2104: 7540678,
        2105: 7540678,
        2119: 7540678,
        2120: 7540678,
        2121: 7540678,
        2122: 7540678,
        2123: 7540678,
        2124: 7540678,
        2125: 7540678,
        2126: 7540678,
        2127: 7540678,
        2128: 7540678,
        2129: 7540678,
        2130: 7540678,
        2131: 7540678,
        2132: 7540678,
        2133: 7540678,
        2134: 7540678,
        2135: 7540678,
        2136: 7540678,
        2137: 7540678,
        2138: 7540678,
        2139: 7540678,
        2140: 7540678,
        2141: 7540678,
        2142: 7540678,
        2143: 7540678,
        2144: 7540678,
        2145: 7540678,
        2146: 7540678,
        2147: 7540678,
        2148: 7540678,
        2149: 7540678,
        2150: 7540678,
        2151: 7540678,
        2152: 7540678,
        2153: 7540678,
        2154: 7540678,
        2155: 7540678,
        2156: 7540678,
        2157: 7540678,
        2158: 7540678,
        2159: 7540678,
        2160: 7540678,
        2161: 7540678,
        2180: 7540678,
        2181: 7540678,
        2182: 7540678,
        2183: 7540678,
        2184: 7540678,
        2185: 7540678,
        2186: 7540678,
        2187: 7540678,
        2188: 7540678,
        2189: 7540678,
        2190: 7540678,
        2191: 7540678,
        2310: 7540678,
        2410: 3791775,
        2411: 7540678,
        2412: 7540678,
        2413: 7540678,
        2414: 7540678,
        2415: 7540678,
        2460: 7540678,
        3002: 7577084,
        3003: 7577084,
        3004: 7577084,
        3005: 7577084,
        3006: 7577084,
        3007: 7577084,
        3008: 7577084,
        3021: 7577084,
        3022: 7577084,
        3023: 7577084,
        3024: 7577084,
        3025: 7577084,
        3041: 7577084,
        3042: 7577084,
        3043: 7577084,
        3044: 7577084,
        3046: 7577084,
        3047: 7577084,
        3048: 7577084,
        3051: 7577084,
        3052: 7577084,
        3053: 7577084,
        3054: 7577084,
        3055: 7577084,
        3056: 7577084,
        3057: 7577084,
        3061: 7577084,
        3062: 7577084,
        3063: 7577084,
        3064: 7577084,
        3065: 7577084,
        3066: 7577084,
        3067: 7577084,
        3071: 7577084,
        3072: 7577084,
        3073: 7577084,
        3074: 7577084,
        3075: 7577084,
        3076: 7577084,
        3081: 7577084,
        3083: 7577084,
        3084: 7577084,
        3085: 7577084,
        3101: 7577084,
        3102: 7577084,
        3103: 7577084,
        3105: 7577084,
        3106: 7577084,
        3107: 7577084,
        3108: 7577084,
        3109: 7577084,
        3361: 7577084,
        3362: 7577084,
        3363: 7577084,
        3364: 7577084,
        3366: 7577084,
        3367: 7577084,
        3368: 7577084,
        3424: 7577084,
        3425: 7577084,
        3426: 7577084,
        3427: 7577084,
        3428: 7577084,
        3463: 7577084,
        3464: 7577084,
        3465: 7577084,
        3466: 7577084,
        3467: 7577084,
        3468: 7577084,
        3469: 7577084,
        3470: 7577084,
        3471: 7577084,
        3472: 7577084,
        3473: 7577084,
        3474: 7577084,
        3475: 7577084,
        3476: 7577084,
        3477: 7577084,
        3478: 7577084,
        4021: 7577088,
        4022: 7577088,
        4023: 7577088,
        4024: 7577088,
        4041: 7577088,
        4042: 7577088,
        4043: 7577088,
        4044: 7577088,
        4045: 7577088,
        4046: 7577088,
        4049: 7577088,
        4060: 7577088,
        4061: 7577088,
        4062: 7577088,
        4063: 7577088,
        4081: 7577088,
        4082: 7577088,
        4083: 7577088,
        4084: 7577088,
        4101: 7577088,
        4102: 7577088,
        4103: 7577088,
        4104: 7577088,
        4105: 7577088,
        4106: 7577088,
        4107: 7577088,
        4108: 7577088,
        4109: 7577088,
        4110: 7577088,
        4111: 7577088,
        4141: 7577088,
        4142: 7577088,
        4143: 7577088,
        4144: 7577088,
        4145: 7577088,
        4146: 7577088,
        4147: 7577088,
        4148: 7577088,
        4149: 7577088,
        4150: 7577088,
        4151: 7577088,
        4152: 7577088,
        4301: 7577088,
        4302: 7577088,
        4303: 7577088,
        4304: 7577088,
        4305: 7577088,
        4311: 7577088,
        4314: 7577088,
        4315: 7577088,
        4316: 7577088,
        4317: 7577088,
        4318: 7577088,
        4319: 7577088,
        4321: 7577088,
        4322: 7577088,
        4323: 7577088,
        4324: 7577088,
        4325: 7577088,
        4326: 7577088,
        4341: 7577088,
        4342: 7577088,
        4343: 7577088,
        4344: 7577088,
        4345: 7577088,
        4346: 7577088,
        4347: 7577088,
        4348: 7577088,
        4361: 7577088,
        4362: 7577088,
        4363: 7577088,
        4364: 7577088,
        4365: 7577088,
        4381: 7577088,
        4382: 7577088,
        4383: 7577088,
        4384: 7577088,
        4385: 7577088,
        4386: 7577088,
        4387: 7577088,
        4392: 7577088,
        4394: 7577088,
        4395: 7577088,
        4396: 7577088,
        4397: 7577088,
        4398: 7577088,
        4399: 7577088,
        4400: 7577088,
        4401: 7577088,
        4402: 7577088,
        4403: 7577088,
        4461: 7577088,
        4462: 7577088,
        4463: 7577088,
        4464: 7577088,
        4465: 7577088,
        4466: 7577088,
        4467: 7577088,
        5002: 7577085,
        5003: 7577085,
        5004: 7577085,
        5005: 7577085,
        5006: 7577085,
        5007: 7577085,
        5008: 7577085,
        5009: 7577085,
        5010: 7577085,
        5021: 7577085,
        5022: 7577085,
        5024: 7577085,
        5042: 7577085,
        5043: 7577085,
        5044: 7577085,
        5045: 7577085,
        5046: 7577085,
        5047: 7577085,
        5048: 7577085,
        5049: 7577085,
        5050: 7577085,
        5051: 7577085,
        5052: 7577085,
        5053: 7577085,
        5054: 7577085,
        5055: 7577085,
        5060: 7577085,
        5070: 7577085,
        5081: 7577085,
        5082: 7577085,
        5083: 7577085,
        5084: 7577085,
        5085: 7577085,
        5086: 7577085,
        5321: 7577085,
        5322: 7577085,
        5323: 7577085,
        5324: 7577085,
        5325: 7577085,
        5360: 7577085,
        5370: 7577085,
        6020: 7534403,
        6021: 7534403,
        6022: 7534403,
        6023: 7534403,
        6024: 7534403,
        6025: 7534403,
        6026: 7534403,
        6027: 7534403,
        6028: 7534403,
        6029: 7534403,
        6030: 7534403,
        6031: 7534403,
        6032: 7534403,
        6033: 7534403,
        6034: 7534403,
        6035: 7534403,
        6036: 7534403,
        6037: 7534403,
        6038: 7534403,
        6039: 7534403,
        6041: 7534403,
        8031: 7577089,
        8032: 7577089,
        8033: 7577089,
        8034: 7577089,
        8035: 7577089,
        8044: 7577089,
        8062: 7577091,
        8063: 7577091,
        8065: 7577091,
        8066: 7577091,
        8067: 7577091,
        8068: 7577091,
        8069: 7577091,
        8070: 7577091,
        8071: 7577091,
        8072: 7577091,
        8073: 7577091,
        8074: 7577091,
        8075: 7577091,
        8076: 7577091,
        8077: 7577091,
        8078: 7577091,
        8079: 7577091,
        8080: 7577091,
        8081: 7577091,
        8082: 7577091,
        8083: 7577091,
        8084: 7577091,
        8101: 7577089,
        8102: 7577089,
        8103: 7577089,
        8104: 7577089,
        8105: 7577089,
        8106: 7577089,
        8107: 7577089,
        8108: 7577089,
        8109: 7577089,
        8110: 7577089,
        8111: 7577089,
        8112: 7577089,
        8113: 7577089,
        8114: 7577089,
        8115: 7577089,
        8116: 7577089,
        8117: 7577089,
        8118: 7577089,
        8201: 7577089,
        8202: 7577089,
        8203: 7577089,
        8204: 7577089,
        8205: 7577089,
        8206: 7577089,
        8207: 7577089,
        8208: 7577089,
        8209: 7577089,
        8210: 7577089,
        8211: 7577089,
        8212: 7577089,
        8213: 7577089,
        8214: 7577089,
        8215: 7577089,
        8216: 7577089,
        8217: 7577089,
        8301: 7577089,
        8302: 7577089,
        8303: 7577089,
        8304: 7577089,
        8305: 7577089,
        8306: 7577089,
        8307: 7577089,
        8308: 7577089,
        8309: 7577089,
        8310: 7577089,
        8311: 7577089,
        8312: 7577089,
        8313: 7577089,
        8314: 7577089,
        8315: 7577089,
        8316: 7577089,
        8317: 7577089,
        8401: 7577089,
        8402: 7577089,
        8403: 7577089,
        8404: 7577089,
        9021: 7577090,
        9022: 7577090,
        9023: 7577090,
        9024: 7577090,
        9026: 7577090,
        9027: 7577090,
        9028: 7577090,
        9029: 7577090,
        9030: 7577090,
        9031: 7577090,
        9032: 7577090,
        9033: 7577090,
        9034: 7577090,
        9035: 7577090,
        9041: 7577090,
        9043: 7577090,
        9044: 7577090,
        9045: 7577090,
        9046: 7577090,
        9047: 7577090,
        9061: 7577090,
        9062: 7577090,
        9063: 7577090,
        9064: 7577090,
        9065: 7577090,
      },
      fallbacks: { desktop: fallbacks['336x280'], mobile: fallbacks['336x280'] },
    },
    roadblock: {
      desktop: {
        1020: 7287576,
        1040: 7287565,
        1050: 7287551,
        1060: 7287582,
        1080: 7287574,
        1100: 7287579,
        1200: 7287577,
        1210: 7287577,
        1220: 7287577,
        1340: 7287580,
        1360: 7287578,
        1361: 7287578,
        1362: 7287578,
        1363: 7287578,
        1364: 7287578,
        1365: 7287578,
        1366: 7287578,
        1367: 7287578,
        2000: 7540674,
        2020: 7540674,
        2040: 7540674,
        2060: 7540674,
        2080: 7540674,
        2081: 7540674,
        2082: 7540674,
        2083: 7540674,
        2084: 7540674,
        2085: 7540674,
        2086: 7540674,
        2087: 7540674,
        2088: 7540674,
        2089: 7540674,
        2090: 7540674,
        2091: 7540674,
        2100: 7540674,
        2101: 7540674,
        2102: 7540674,
        2103: 7540674,
        2104: 7540674,
        2105: 7540674,
        2119: 7540674,
        2121: 7540674,
        2122: 7540674,
        2123: 7540674,
        2124: 7540674,
        2125: 7540674,
        2126: 7540674,
        2127: 7540674,
        2128: 7540674,
        2129: 7540674,
        2130: 7540674,
        2131: 7540674,
        2132: 7540674,
        2133: 7540674,
        2134: 7540674,
        2135: 7540674,
        2136: 7540674,
        2137: 7540674,
        2138: 7540674,
        2139: 7540674,
        2140: 7540674,
        2141: 7540674,
        2142: 7540674,
        2143: 7540674,
        2144: 7540674,
        2145: 7540674,
        2146: 7540674,
        2147: 7540674,
        2148: 7540674,
        2149: 7540674,
        2150: 7540674,
        2151: 7540674,
        2152: 7540674,
        2153: 7540674,
        2154: 7540674,
        2155: 7540674,
        2156: 7540674,
        2157: 7540674,
        2158: 7540674,
        2159: 7540674,
        2160: 7540674,
        2161: 7540674,
        2180: 7540674,
        2181: 7540674,
        2182: 7540674,
        2183: 7540674,
        2184: 7540674,
        2185: 7540674,
        2186: 7540674,
        2187: 7540674,
        2188: 7540674,
        2189: 7540674,
        2190: 7540674,
        2191: 7540674,
        2310: 7540674,
        2411: 7540674,
        2412: 7540674,
        2413: 7540674,
        2414: 7540674,
        2415: 7540674,
        2460: 7540674,
        3002: 7576693,
        3003: 7576693,
        3004: 7576693,
        3005: 7576693,
        3006: 7576693,
        3007: 7576693,
        3008: 7576693,
        3021: 7576693,
        3022: 7576693,
        3023: 7576693,
        3024: 7576693,
        3025: 7576693,
        3041: 7576693,
        3042: 7576693,
        3043: 7576693,
        3044: 7576693,
        3046: 7576693,
        3047: 7576693,
        3048: 7576693,
        3051: 7576693,
        3052: 7576693,
        3053: 7576693,
        3054: 7576693,
        3055: 7576693,
        3056: 7576693,
        3057: 7576693,
        3061: 7576693,
        3062: 7576693,
        3063: 7576693,
        3064: 7576693,
        3065: 7576693,
        3066: 7576693,
        3067: 7576693,
        3071: 7576693,
        3072: 7576693,
        3073: 7576693,
        3074: 7576693,
        3075: 7576693,
        3076: 7576693,
        3081: 7576693,
        3083: 7576693,
        3084: 7576693,
        3085: 7576693,
        3101: 7576693,
        3102: 7576693,
        3103: 7576693,
        3105: 7576693,
        3106: 7576693,
        3107: 7576693,
        3108: 7576693,
        3109: 7576693,
        3361: 7576693,
        3362: 7576693,
        3363: 7576693,
        3364: 7576693,
        3366: 7576693,
        3367: 7576693,
        3368: 7576693,
        3424: 7576693,
        3425: 7576693,
        3426: 7576693,
        3427: 7576693,
        3428: 7576693,
        3463: 7576693,
        3464: 7576693,
        3465: 7576693,
        3466: 7576693,
        3467: 7576693,
        3468: 7576693,
        3469: 7576693,
        3470: 7576693,
        3471: 7576693,
        3472: 7576693,
        3473: 7576693,
        3474: 7576693,
        3475: 7576693,
        3476: 7576693,
        3477: 7576693,
        3478: 7576693,
        4021: 7576704,
        4022: 7576704,
        4023: 7576704,
        4024: 7576704,
        4041: 7576704,
        4042: 7576704,
        4043: 7576704,
        4044: 7576704,
        4045: 7576704,
        4046: 7576704,
        4049: 7576704,
        4060: 7576704,
        4061: 7576704,
        4062: 7576704,
        4063: 7576704,
        4081: 7576704,
        4082: 7576704,
        4083: 7576704,
        4084: 7576704,
        4101: 7576704,
        4102: 7576704,
        4103: 7576704,
        4104: 7576704,
        4105: 7576704,
        4106: 7576704,
        4107: 7576704,
        4108: 7576704,
        4109: 7576704,
        4110: 7576704,
        4111: 7576704,
        4141: 7576704,
        4142: 7576704,
        4143: 7576704,
        4144: 7576704,
        4145: 7576704,
        4146: 7576704,
        4147: 7576704,
        4148: 7576704,
        4149: 7576704,
        4150: 7576704,
        4151: 7576704,
        4152: 7576704,
        4301: 7576704,
        4302: 7576704,
        4303: 7576704,
        4304: 7576704,
        4305: 7576704,
        4311: 7576704,
        4314: 7576704,
        4315: 7576704,
        4316: 7576704,
        4317: 7576704,
        4318: 7576704,
        4319: 7576704,
        4321: 7576704,
        4322: 7576704,
        4323: 7576704,
        4324: 7576704,
        4325: 7576704,
        4326: 7576704,
        4341: 7576704,
        4342: 7576704,
        4343: 7576704,
        4344: 7576704,
        4345: 7576704,
        4346: 7576704,
        4347: 7576704,
        4348: 7576704,
        4361: 7576704,
        4362: 7576704,
        4363: 7576704,
        4364: 7576704,
        4365: 7576704,
        4381: 7576704,
        4382: 7576704,
        4383: 7576704,
        4384: 7576704,
        4385: 7576704,
        4386: 7576704,
        4387: 7576704,
        4392: 7576704,
        4394: 7576704,
        4395: 7576704,
        4396: 7576704,
        4397: 7576704,
        4398: 7576704,
        4399: 7576704,
        4400: 7576704,
        4401: 7576704,
        4402: 7576704,
        4403: 7576704,
        4461: 7576704,
        4462: 7576704,
        4463: 7576704,
        4464: 7576704,
        4465: 7576704,
        4466: 7576704,
        4467: 7576704,
        5002: 7576701,
        5003: 7576701,
        5004: 7576701,
        5005: 7576701,
        5006: 7576701,
        5007: 7576701,
        5008: 7576701,
        5009: 7576701,
        5010: 7576701,
        5021: 7576701,
        5022: 7576701,
        5024: 7576701,
        5042: 7576701,
        5043: 7576701,
        5044: 7576701,
        5045: 7576701,
        5046: 7576701,
        5047: 7576701,
        5048: 7576701,
        5049: 7576701,
        5050: 7576701,
        5051: 7576701,
        5052: 7576701,
        5053: 7576701,
        5054: 7576701,
        5055: 7576701,
        5060: 7576701,
        5070: 7576701,
        5081: 7576701,
        5082: 7576701,
        5083: 7576701,
        5084: 7576701,
        5085: 7576701,
        5086: 7576701,
        5321: 7576701,
        5322: 7576701,
        5323: 7576701,
        5324: 7576701,
        5325: 7576701,
        5360: 7576701,
        5370: 7576701,
        6020: 7534371,
        6021: 7534371,
        6022: 7534371,
        6023: 7534371,
        6024: 7534371,
        6025: 7534371,
        6026: 7534371,
        6027: 7534371,
        6028: 7534371,
        6029: 7534371,
        6030: 7534371,
        6031: 7534371,
        6032: 7534371,
        6033: 7534371,
        6034: 7534371,
        6035: 7534371,
        6036: 7534371,
        6037: 7534371,
        6038: 7534371,
        6039: 7534371,
        6041: 7534371,
        8031: 7576707,
        8032: 7576707,
        8033: 7576707,
        8034: 7576707,
        8035: 7576707,
        8044: 7576707,
        8062: 7576710,
        8063: 7576710,
        8065: 7576710,
        8066: 7576710,
        8067: 7576710,
        8068: 7576710,
        8069: 7576710,
        8070: 7576710,
        8071: 7576710,
        8072: 7576710,
        8073: 7576710,
        8074: 7576710,
        8075: 7576710,
        8076: 7576710,
        8077: 7576710,
        8078: 7576710,
        8079: 7576710,
        8080: 7576707,
        8081: 7576707,
        8082: 7576707,
        8083: 7576707,
        8084: 7576707,
        8101: 7576707,
        8102: 7576707,
        8103: 7576707,
        8104: 7576707,
        8105: 7576707,
        8106: 7576707,
        8107: 7576707,
        8108: 7576707,
        8109: 7576707,
        8110: 7576707,
        8111: 7576707,
        8112: 7576707,
        8113: 7576707,
        8114: 7576707,
        8115: 7576707,
        8116: 7576707,
        8117: 7576707,
        8118: 7576707,
        8201: 7576707,
        8202: 7576707,
        8203: 7576707,
        8204: 7576707,
        8205: 7576707,
        8206: 7576707,
        8207: 7576707,
        8208: 7576707,
        8209: 7576707,
        8210: 7576707,
        8211: 7576707,
        8212: 7576707,
        8213: 7576707,
        8214: 7576707,
        8215: 7576707,
        8216: 7576707,
        8217: 7576707,
        8301: 7576707,
        8302: 7576707,
        8303: 7576707,
        8304: 7576707,
        8305: 7576707,
        8306: 7576707,
        8307: 7576707,
        8308: 7576707,
        8309: 7576707,
        8310: 7576707,
        8311: 7576707,
        8312: 7576707,
        8313: 7576707,
        8314: 7576707,
        8315: 7576707,
        8316: 7576707,
        8317: 7576707,
        8401: 7576707,
        8402: 7576707,
        8403: 7576707,
        8404: 7576707,
        9021: 7576709,
        9022: 7576709,
        9023: 7576709,
        9024: 7576709,
        9026: 7576709,
        9027: 7576709,
        9028: 7576709,
        9029: 7576709,
        9030: 7576709,
        9031: 7576709,
        9032: 7576709,
        9033: 7576709,
        9034: 7576709,
        9035: 7576709,
        9041: 7576709,
        9043: 7576709,
        9044: 7576709,
        9045: 7576709,
        9046: 7576709,
        9047: 7576709,
        9061: 7576709,
        9062: 7576709,
        9063: 7576709,
        9064: 7576709,
        9065: 7576709,
      },
      mobile: {
        1020: 7287660,
        1040: 7287657,
        1050: 7287633,
        1060: 7287667,
        1080: 7287659,
        1100: 7287665,
        1200: 7287661,
        1210: 7287661,
        1220: 7287661,
        1340: 7287666,
        1360: 7287664,
        1361: 7287664,
        1362: 7287664,
        1363: 7287664,
        1364: 7287664,
        1365: 7287664,
        1366: 7287664,
        1367: 7287664,
        2310: 7540678,
        2410: 7540678,
        2416: 7540678,
      },
      fallbacks: { desktop: fallbacks['640x360'], mobile: fallbacks['640x360'] },
    },
    calculator: {
      desktop: {
        1020: 7373583,
        1040: 7373583,
        1080: 7373583,
        1100: 7373583,
        1340: 7373583,
        2020: 7214450,
        2040: 7214450,
        2081: 7214450,
        2082: 7214450,
        2083: 7214450,
        2084: 7214450,
        2085: 7214450,
        2086: 7214450,
        2087: 7214450,
        2088: 7214450,
        2089: 7214450,
        2090: 7214450,
        2091: 7214450,
        2101: 7214450,
        2102: 7214450,
        2103: 7214450,
        2104: 7214450,
        2105: 7214450,
        2060: 7214450,
      },
      mobile: {
        1020: 7373581,
        1040: 7373581,
        1080: 7373581,
        1100: 7373581,
        1340: 7373581,
        2020: 7214451,
        2040: 7214451,
        2081: 7214451,
        2082: 7214451,
        2083: 7214451,
        2084: 7214451,
        2085: 7214451,
        2086: 7214451,
        2087: 7214451,
        2088: 7214451,
        2089: 7214451,
        2090: 7214451,
        2091: 7214451,
        2101: 7214451,
        2102: 7214451,
        2103: 7214451,
        2104: 7214451,
        2105: 7214451,
        2060: 7214451,
      },
      fallbacks: { desktop: fallbacks['336x280'], mobile: fallbacks['336x280'] },
    },
    adsense: {
      desktop: {
        1000: 7540356,
        2000: 7540369,
        6000: 7540370,
        3000: 7540371,
        5000: 7540374,
        4000: 7540378,
        8000: 7540379,
        9000: 7540380,
        8060: 7540381,
      },
      mobile: {
        1000: 7540382,
        2000: 7540383,
        6000: 7540384,
        3000: 7540385,
        5000: 7540386,
        4000: 7540387,
        8000: 7540388,
        9000: 7540389,
        8060: 7540390,
      },
    },
  },
};

export default zones;
