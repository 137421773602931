import {
  createContext, useContext,
} from 'react';

export const UserContext = createContext();

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within UserContextWrapper');
  }
  return context;
};

const UserContextWrapper = ({ children, value }) => (
  <UserContext.Provider value={value}>
    {children}
  </UserContext.Provider>
);

export default UserContextWrapper;
