import { baseUrl, carHomeUrl, realEstateHomeUrl } from '@/config';

export const SEARCHED_BRANDS = [
  { label: 'volkswagen passat', link: `${carHomeUrl}/magyarorszag/auto/volkswagen/passat` },
  { label: 'trabant', link: `${carHomeUrl}/magyarorszag/auto/trabant` },
  { label: 'mini cooper', link: `${carHomeUrl}/magyarorszag/auto/mini` },
  { label: 'jbl', link: `${baseUrl}/magyarorszag?q=jbl bluetooth` },
  { label: 'forza horizon 4', link: `${baseUrl}/magyarorszag?q=forza horizon 4` },
  { label: 'nintendo switch', link: `${baseUrl}/magyarorszag?q=nintendo switch` },
  { label: 'samsung galaxy s10', link: `${baseUrl}/magyarorszag?q=samsung galaxy s10 dual` },
  { label: 'lego friends', link: `${baseUrl}/magyarorszag?q=lego friends` },
  { label: 'xbox one', link: `${baseUrl}/magyarorszag?q=q=xbox one` },
  { label: 'husqvarna', link: `${baseUrl}/magyarorszag?q=husqvarna` },
  { label: 'mayo chix', link: `${baseUrl}/magyarorszag?q=mayo chix` },
  { label: 'iphone 11', link: `${baseUrl}/magyarorszag?q=iphone 11 pro max` },
  { label: 'iphone se', link: `${baseUrl}/magyarorszag?q=apple iphone se 2020` },
  { label: 'xiaomi mi 10', link: `${baseUrl}/magyarorszag?q=xiaomi mi 10 pro` },
  { label: 'ps5', link: `${baseUrl}/magyarorszag?q=sony playstation ps5` },
  { label: 'bonprix', link: `${baseUrl}/magyarorszag?q=bonprix` },
  { label: 'bershka', link: `${baseUrl}/magyarorszag?q=bershka` },
  { label: 'tchibo', link: `${baseUrl}/magyarorszag?q=tchibo` },
  { label: 'honfoglaló', link: `${baseUrl}/magyarorszag?q=honfoglaló` },
  { label: 'kika', link: `${baseUrl}/magyarorszag/fotelek-kanapek-ulogarniturak?q=kika` },
  { label: 'cropp', link: `${baseUrl}/magyarorszag?q=cropp` },
  { label: 'xiaomi redmi note 9 pro', link: `${baseUrl}/magyarorszag?q=xiaomi redmi note 9 pro` },
  { label: 'miután', link: `${baseUrl}/magyarorszag?q=miután` },
  { label: 'brawl stars', link: `${baseUrl}/magyarorszag?q=brawl stars` },
  { label: 'xbox series x', link: `${baseUrl}/magyarorszag?q=xbox series x` },
  { label: 'crystal nails', link: `${baseUrl}/magyarorszag?q=crystal nails lámpa` },
  { label: 'amerikai staffordshire terrier', link: `${baseUrl}/magyarorszag?q=amerikai staffordshire` },
  { label: 'iphone 11 pro', link: `${baseUrl}/magyarorszag?q=iphone 11 pro` },
  { label: 'airpods', link: `${baseUrl}/magyarorszag?q=airpods` },
  { label: 'samsung tv', link: `${baseUrl}/magyarorszag?q=samsung tv cm` },
  { label: 'playstation 5', link: `${baseUrl}/magyarorszag?q=sony playstation 5` },
  { label: 'gamestar', link: `${baseUrl}/magyarorszag?q=gamestar` },
  { label: 'iphone xr', link: `${baseUrl}/magyarorszag?q=iphone xr` },
  { label: 'apple watch', link: `${baseUrl}/magyarorszag?q=apple watch` },
  { label: 'huawei p30 lite', link: `${baseUrl}/magyarorszag?q=huawei p30 lite` },
  { label: 'samsung galaxy a51', link: `${baseUrl}/magyarorszag?q=samsung galaxy a51` },
  { label: 'iphone x', link: `${baseUrl}/magyarorszag?q=iphone x` },
  { label: 'labrador', link: `${baseUrl}/magyarorszag?q=labrador` },
  { label: 'iphone 6s', link: `${baseUrl}/magyarorszag?q=iphone 6s` },
  { label: 'husky', link: `${baseUrl}/magyarorszag?q=husky` },
  { label: 'maine coon', link: `${baseUrl}/magyarorszag?q=maine coon` },
  { label: 'ps4', link: `${baseUrl}/magyarorszag?q=ps4` },
  { label: 'iphone 7', link: `${baseUrl}/magyarorszag?q=iphone 7` },
  { label: 'iphone xs', link: `${baseUrl}/magyarorszag?q=iphone xs` },
  { label: 'ipad', link: `${baseUrl}/magyarorszag?q=ipad` },
  { label: 'iphone 8', link: `${baseUrl}/magyarorszag?q=iphone 8 telefon` },
];

export const TRENDING_CATEGORIES = [
  { label: 'kerékpár', link: `${baseUrl}/magyarorszag/kerekparok-es-alkatreszek` },
  { label: 'eladó lakás', link: `${realEstateHomeUrl}/budapest/ingatlan` },
  { label: 'monitor', link: `${baseUrl}/magyarorszag/monitor` },
  { label: 'téli gumi', link: `${baseUrl}/magyarorszag?q=téli gumi` },
  { label: 'ingyen elvihető', link: `${baseUrl}/magyarorszag?q=ingyen elviheto` },
  { label: 'trambulin', link: `${baseUrl}/magyarorszag?q=trambulin` },
  { label: 'elektromos roller', link: `${baseUrl}/magyarorszag?q=elektromos roller` },
  { label: 'mosogatógép', link: `${baseUrl}/magyarorszag/mosogatogep` },
  { label: 'puzzle', link: `${baseUrl}/magyarorszag/puzzle-kirako` },
  { label: 'eladó lovak', link: `${baseUrl}/magyarorszag/lo` },
  { label: 'laptop', link: `${baseUrl}/magyarorszag?q=laptop` },
  { label: 'eladó ház', link: `${realEstateHomeUrl}/magyarorszag/haz` },
  { label: 'okosóra', link: `${baseUrl}/magyarorszag?q=okosóra` },
  { label: 'francia bulldog', link: `${baseUrl}/magyarorszag?q=francia bulldog` },
  { label: 'használt autó', link: `${carHomeUrl}/magyarorszag/auto` },
  { label: 'számológép', link: `${baseUrl}/magyarorszag?q=szamologep` },
  { label: 'szinoníma szótár', link: `${baseUrl}/magyarorszag?q=szinoníma szótár` },
  { label: 'konyhabútor', link: `${baseUrl}/magyarorszag/konyhabutor` },
  { label: 'sakk', link: `${baseUrl}/magyarorszag?q=sakk` },
  { label: 'szájmaszk', link: `${baseUrl}/magyarorszag?q=szájmaszk` },
  { label: 'halloween tök', link: `${baseUrl}/gyor-moson-sopron?q=halloween tok` },
  { label: 'mikrohullámú sütő', link: `${baseUrl}/magyarorszag/mikrohullamu-suto` },
  { label: 'trapézlemez', link: `${baseUrl}/magyarorszag?q=trapézlemez` },
  { label: 'adventi koszorú', link: `${baseUrl}/magyarorszag?q=adventi koszorú` },
  { label: 'elektromos kerékpár', link: `${baseUrl}/magyarorszag?q=elektromos bicikli` },
  { label: 'kerti bútor', link: `${baseUrl}/magyarorszag/kerti-butor` },
  { label: 'térkő', link: `${baseUrl}/magyarorszag?q=térkő` },
  { label: 'kávéfőző', link: `${baseUrl}/magyarorszag/kavefozo-teafozo` },
  { label: 'számítógép', link: `${baseUrl}/magyarorszag?q=számítógép` },
  { label: 'menyasszonyi ruha', link: `${baseUrl}/magyarorszag?q=menyasszonyi ruha` },
  { label: 'futópad', link: `${baseUrl}/magyarorszag?q=futópad` },
  { label: 'gördeszka', link: `${baseUrl}/magyarorszag?q=gördeszka` },
  { label: 'tolóajtó', link: `${baseUrl}/magyarorszag/ajto-ablak?q=toloajto` },
  { label: 'bluetooth fülhallgató', link: `${baseUrl}/magyarorszag?q=bluetooth fulhallgato` },
  { label: 'könnyűszerkezetes ház', link: `${baseUrl}/magyarorszag?q=konnyuszerkezetes` },
  { label: 'dohányzóasztal', link: `${baseUrl}/magyarorszag?q=dohányzóasztal` },
  { label: 'bejárati ajtó', link: `${baseUrl}/magyarorszag?q=bejárati ajtó` },
  { label: 'kanapéágy', link: `${baseUrl}/magyarorszag?q=kanapéágy` },
  { label: 'hintaágy', link: `${baseUrl}/magyarorszag?q=hintaágy` },
  { label: 'bicikli', link: `${baseUrl}/magyarorszag?q=bicikli` },
  { label: 'diego szőnyeg', link: `${baseUrl}/magyarorszag?q=diego szonyeg` },
  { label: 'használt laptop', link: `${baseUrl}/magyarorszag?q=laptop` },
  { label: 'faház', link: `${baseUrl}/magyarorszag?q=faház` },
  { label: 'hdmi kábel', link: `${baseUrl}/magyarorszag?q=hdmi kabel` },
  { label: 'aroma diffúzor', link: `${baseUrl}/magyarorszag?q=aroma diffúzor` },
  { label: 'gáztűzhely', link: `${baseUrl}/magyarorszag?q=gáztűzhely` },
  { label: 'roller', link: `${baseUrl}/magyarorszag?q=roller` },
  { label: 'tengerimalac', link: `${baseUrl}/magyarorszag?q=tengerimalac` },
  { label: 'magaságyás', link: `${baseUrl}/magyarorszag?q=magaságyás` },
  { label: 'függőfotel', link: `${baseUrl}/magyarorszag?q=Függőfotel` },
];
