import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowForwardIosOutlined from '@mui/icons-material/KeyboardArrowRightOutlined';
import {
  Box,
  Typography,
  Button,
  IconButton,
} from '@mui/material';

import { HeadlineProps } from './types';
import { useModalContext } from '@/contexts/Modal/ModalContext';
import { clickInfoIconHeadline } from '@/helpers/tracking/homepage';

const Headline = ({
  children,
  infoModalComponent = null,
  readMoreData,
  position,
}: HeadlineProps): React.ReactNode => {
  const { showModal } = useModalContext();
  const handleInfoIconClick = ():void => {
    showModal(infoModalComponent, null);
    clickInfoIconHeadline(position);
  };

  return (
    <Box
      mb={1}
      height={32}
      justifyContent="start"
      alignItems="center"
      display="flex"
    >
      <Typography variant="h4" color="text.primary" mr={2}>{children}</Typography>
      {infoModalComponent
        && (
          <IconButton
            data-testid="info-icon-button"
            onClick={() => handleInfoIconClick()}
            size="small"
            color="info"
            aria-label="Információ"
          >
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        )}
      {readMoreData
        && (
          <Button
            href={readMoreData.href}
            variant="text"
            size="small"
            color="secondary"
            sx={{
              marginLeft: 'auto',
            }}
            endIcon={<ArrowForwardIosOutlined fontSize="small" />}
          >
            {readMoreData.text}
          </Button>
        )}
    </Box>
  );
};

export default Headline;
