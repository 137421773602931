import { Button } from '@mui/material';

import { baseUrl, base2Url } from '@/config';
import { clickAiButton } from '@/helpers/tracking/events';

const AdInsertionButton = ({
  account, size, channel, pathname = '', text = 'Hirdetésfeladás', ...args
}) => {
  const backUrl = `${base2Url}/ai${(channel ? `#channel=${channel}` : '')}`;
  const loginUrl = `${baseUrl}/belepes?backUrl=${encodeURIComponent(backUrl).replace(/\./g, '%2E')}`;
  const loginAttr = account ? { href: backUrl } : { href: loginUrl };
  const clickAttrForJobVertical = pathname === '/allas' && { onClick: clickAiButton };

  return (
    <Button
      aria-label="Hirdetésfeladás"
      {...loginAttr}
      {...clickAttrForJobVertical}
      size={size}
      {...args}
    >
      {text}
    </Button>
  );
};

export default AdInsertionButton;
