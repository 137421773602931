import tealium from '@/helpers/tealium';

// eslint-disable-next-line import/prefer-default-export
export const clickInfoIconHeadline = (position) => {
  tealium.link({
    event_name: 'click_info_button',
    tealium_event: 'show',
    acmh_position: position,
  });
};

export const clickHeroCTA = (position) => {
  tealium.link({
    event_name: 'click_hero_section',
    tealium_event: 'show',
    acmh_position: position,
  });
};
