/* eslint max-len: ['error', {ignoreComments: true}] */
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import {
  Button, Container, Grid, Typography,
} from '@mui/material';

import Head from '@/components/common/Head';
import ShrinkingImage from '@/components/common/ShrinkingImage';

/**
 * 500 Error page when something happened on the server side.
 *
 * @param {Object} Custom500Props
 * @param {string} [Custom500Props.title = 'Technikai hiba'] The page title. Default value: `Technikai hiba`
 * @returns {JSX.Element}
*/
const Custom500 = ({ title = 'Technikai hiba' }) => {
  const handlePageReload = () => {
    window.location.reload(true);
  };

  return (
    <>
      <Head
        title={title}
        description=""
      />
      <Grid
        sx={{
          minHeight: { xs: 'auto', lg: '75vh' },
        }}
        container
      >
        <Container>
          <Grid
            sx={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
            container
          >
            <Grid
              sx={{
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 8,
                mt: 8,
                mb: { xs: 4, md: 8 },
                px: 8,
                py: 4,
              }}
              container
            >
              <Grid item>
                <ShrinkingImage
                  src="/assets/svg/Alert.svg"
                  alt="Valami technikai hiba történt"
                  width={160}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h1"
                  textAlign="center"
                  mb={2}
                >
                  Valami technikai hiba történt
                </Typography>
                <Typography
                  variant="body1"
                  textAlign="center"
                >
                  Ellenőrizd az internetkapcsolatod és próbáld újra!
                </Typography>
              </Grid>
              <Grid container justifyContent="center">
                <Button
                  size="large"
                  color="secondary"
                  sx={{
                    width: { xs: '100%', md: 'auto' },
                  }}
                  startIcon={<RefreshOutlinedIcon />}
                  onClick={handlePageReload}
                >
                  Újratöltés
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  );
};

export default Custom500;
