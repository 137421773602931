import { TRENDING_SEARCHES_JOB, TRENDING_CITIES_JOB, TRENDING_JOBS } from './data';
import SeoLinks from './seo-links';

const SEO_GROUPS = [
  {
    title: 'Gyakori keresések',
    pages: TRENDING_SEARCHES_JOB,
  },
  {
    title: 'Népszerű városok',
    pages: TRENDING_CITIES_JOB,
  },
  {
    title: 'Keresett szakmák',
    pages: TRENDING_JOBS,
  },
];

const SeoJob = () => <SeoLinks groups={SEO_GROUPS} />;

export default SeoJob;
