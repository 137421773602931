import { cloneDeep } from 'lodash';

const tealiumUDO = (_viewId) => {
  let udo = {};
  let clientDataComponent;

  try {
    const viewId = _viewId || 'generic';

    const viewIdMap = {
      viShowPhone: {
        criteo_event: 'trackTransaction',
        criteo_trans_id: Math.floor((Math.random() * 10000000000) + 1),
        crimtan_pixel: '37687507',
        criteo_item_id: window?.utag_data?.list_id || '',
        criteo_price: '1',
        criteo_quantity: '1',
        event_name: 'ad_reply_show_phone',
        xiti_p: 'ad_reply_show_phone',
      },
      viCallPhone: {
        event_name: 'ad_reply_call_phone',
        xiti_p: 'ad_reply_call_phone',
      },
    };

    Object.assign(udo, (typeof window.utag_data !== 'undefined') ? cloneDeep(window.utag_data) : {});

    if (viewIdMap[viewId]) {
      Object.assign(udo, viewIdMap[viewId]);
    } else {
      Object.assign(udo, viewIdMap.generic);
    }
  } catch (e) {
    clientDataComponent = {};
    clientDataComponent.page_type = 'generic udo error';
    clientDataComponent.error_name = e.name;
    clientDataComponent.error_message = e.message;
    Object.assign(udo, clientDataComponent);
  }
  if (udo && typeof udo === 'object') {
    return udo;
  }
  clientDataComponent = {};
  clientDataComponent.page_type = 'generic';
  clientDataComponent.data = 'no data found for udo, please check setup of tealium_data.js';
  udo = clientDataComponent;
  return udo;
};

const tealium = {
  ready: false,
  inProgress: false,
  eventsQueue: [],
  processEventsQueue: () => {
    if (!tealium.eventsQueue.length) {
      tealium.inProgress = false;
      return;
    }
    tealium.inProgress = true;
    const { tealiumEvent, data } = tealium.eventsQueue.shift();
    window.utag.track(tealiumEvent, data, tealium.processEventsQueue);
  },
  track: (tealiumEvent, data) => {
    tealium.eventsQueue.push({ tealiumEvent, data });
    if ((tealium.ready) && (!tealium.inProgress)) {
      tealium.processEventsQueue();
      return;
    }
    const sendTimerUtag = setInterval(() => {
      if ((tealium.ready) && (!tealium.inProgress)) {
        tealium.processEventsQueue();
        clearInterval(sendTimerUtag);
      } else if (!tealium.eventsQueue.length) {
        clearInterval(sendTimerUtag);
      }
    }, 200);
  },
  link: (data) => tealium.track('link', data),
  linkGF: (data, viewId) => {
    const dataClone = cloneDeep(data);
    const udo = {
      ...((typeof window.utag_data !== 'undefined') ? cloneDeep(window.utag_data) : {}),
      ...dataClone,
      is_fake_page: 'false',
      tealium_event: 'show',
    };
    tealium.track('link', udo);
    tealium.fakeView(viewId);
  },
  view: (data) => tealium.track('view', data),
  sendView: () => {
    if (window.utag) {
      window.utag.view(window.utag_data, () => {
        setTimeout(() => {
          // This is to remove hash params after the page view event
          window.history.pushState(
            '',
            document.title,
            window.location.pathname + window.location.search,
          );
          tealium.ready = true;
        }, 1000);
      });
    }
  },
  fakeView: (viewId) => {
    setTimeout(() => {
      const udo = {
        ...tealiumUDO(viewId),
        level2_site: '186',
        level2_site_ok: '186',
        is_fake_page: 'true',
      };

      tealium.track('view', udo);
    }, 150);
  },
};

export default tealium;
