import { jobHomeUrl } from '@/config';

export const TRENDING_SEARCHES_JOB = [
  { label: 'hollandia munka', link: `${jobHomeUrl}/magyarorszag/allasajanlatok-allaskereses?q=hollandia` },
  { label: 'németországi munka', link: `${jobHomeUrl}/magyarorszag?q=nemetorszagi munka` },
  { label: 'hétvégi munka', link: `${jobHomeUrl}/magyarorszag?q=hetvegi munka` },
  { label: 'otthonról végezhető munka', link: `${jobHomeUrl}/magyarorszag?q=otthon vegezheto` },
  { label: 'állásajánlatok', link: `${jobHomeUrl}/magyarorszag/allasajanlatok-allaskereses` },
  { label: 'álláshirdetések', link: `${jobHomeUrl}/magyarorszag/allasajanlatok-allaskereses` },
  { label: 'távmunka', link: `${jobHomeUrl}/magyarorszag/allasajanlatok-allaskereses?q=office` },
  { label: 'részmunkaidős állás', link: `${jobHomeUrl}/magyarorszag?q=reszmunkaidos allas` },
  { label: 'diákmunka budapest', link: `${jobHomeUrl}/magyarorszag?q=diakmunka` },
];

export const TRENDING_CITIES_JOB = [
  { label: 'dunaújváros állás', link: `${jobHomeUrl}/fejer/dunaujvaros/allasajanlatok-allaskereses` },
  { label: 'állás zalaegerszeg', link: `${jobHomeUrl}/zala/zalaegerszeg/allasajanlatok-allaskereses` },
  { label: 'állás 17 kerület', link: `${jobHomeUrl}/budapest/xvii-kerulet/allasajanlatok-allaskereses` },
  { label: 'szolnok állás', link: `${jobHomeUrl}/jasz-nagykun-szolnok/szolnok/allasajanlatok-allaskereses` },
  { label: 'állás nagykanizsa', link: `${jobHomeUrl}/zala/nagykanizsa/allasajanlatok-allaskereses` },
  { label: 'állás veszprém', link: `${jobHomeUrl}/veszprem/allasajanlatok-allaskereses` },
  { label: 'állás kaposvár', link: `${jobHomeUrl}/somogy/kaposvar/allasajanlatok-allaskereses` },
  { label: 'állás székesfehérvár', link: `${jobHomeUrl}/fejer/szekesfehervar/allasajanlatok-allaskereses` },
  { label: 'állás kecskemét', link: `${jobHomeUrl}/bacs-kiskun/kecskemet/allasajanlatok-allaskereses` },
  { label: 'állás nyíregyháza', link: `${jobHomeUrl}/szabolcs-szatmar-bereg/nyiregyhaza/allasajanlatok-allaskereses` },
  { label: 'állás miskolc', link: `${jobHomeUrl}/borsod-abauj-zemplen/miskolc/allasajanlatok-allaskereses` },
  { label: 'állás pécs', link: `${jobHomeUrl}/baranya/pecs/allasajanlatok-allaskereses` },
  { label: 'szeged állás', link: `${jobHomeUrl}/csongrad/szeged/allasajanlatok-allaskereses` },
  { label: 'eger állás', link: `${jobHomeUrl}/heves/eger/allasajanlatok-allaskereses` },
  { label: 'állás győr', link: `${jobHomeUrl}/gyor-moson-sopron/gyor/allasajanlatok-allaskereses` },
  { label: 'állás budapest', link: `${jobHomeUrl}/budapest/allasajanlatok-allaskereses` },
  { label: 'állás debrecen', link: `${jobHomeUrl}/hajdu-bihar/debrecen/allasajanlatok-allaskereses` },
];

export const TRENDING_JOBS = [
  { label: 'raktáros állás', link: `${jobHomeUrl}/magyarorszag?q=w=1` },
  { label: 'szakács állás budapest', link: `${jobHomeUrl}/magyarorszag?q=szakacs` },
  { label: 'nehézgépkezelő állás', link: `${jobHomeUrl}/magyarorszag?q=nehezgepkezelo allas` },
  { label: 'targoncás állás', link: `${jobHomeUrl}/magyarorszag?q=targoncas allas` },
  { label: 'nemzetközi sofőr állás', link: `${jobHomeUrl}/magyarorszag/szallitas-logisztika` },
  { label: 'fodrász állás', link: `${jobHomeUrl}/magyarorszag?q=fodrasz allas` },
  { label: 'irodai munka debrecen', link: `${jobHomeUrl}/hajdu-bihar/debrecen/adminisztracio-irodai-munka` },
  { label: 'villanyszerelő állás', link: `${jobHomeUrl}/magyarorszag?q=villanyszerelo allas` },
  { label: 'portás állás', link: `${jobHomeUrl}/magyarorszag?q=portas` },
  { label: 'autószerelő', link: `${jobHomeUrl}/magyarorszag/allasajanlatok-allaskereses?q=autoszerelo` },
  { label: 'hegesztő állás', link: `${jobHomeUrl}/magyarorszag?q=hegeszto allas` },
  { label: 'grafikus állás', link: `${jobHomeUrl}/magyarorszag/allasajanlatok-allaskereses?q=grafikus` },
  { label: 'karosszéria lakatos', link: `${jobHomeUrl}/magyarorszag?q=karosszeria lakatos` },
  { label: 'asztalos', link: `${jobHomeUrl}/magyarorszag/allasajanlatok-allaskereses?q=asztalos` },
  { label: 'személy és vagyonőr', link: `${jobHomeUrl}/magyarorszag/szemely-es-vagyonvedelem` },
  { label: 'biztonsági őr állás', link: `${jobHomeUrl}/budapest/szemely-es-vagyonvedelem` },
  { label: 'sofőr állás', link: `${jobHomeUrl}/magyarorszag/szallitas-logisztika` },
];
