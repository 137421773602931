import { useState } from 'react';

import {
  AppBar, Container, Stack, Toolbar, Portal, Backdrop,
} from '@mui/material';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import AdInsertionButtonGroup from './AdInsertionButtonGroup';
import Logo from './Logo';
import MenuButton from './MenuButton';
import { useUserContext } from '@/contexts/User/UserContext';

const Searchbar = dynamic(() => import('./Searchbar'));

const UserAuthButtons = dynamic(
  () => import('./UserAuthButtons'),
  { ssr: false },
);

const Header = ({
  pathname, unreadMessages, openSideMenu, vertical, mainCategory,
}) => {
  const { userState } = useUserContext();
  const { account, loading } = userState;

  const [showSearchForm, setShowSearchForm] = useState(false);

  const showSearchBar = () => {
    const excludedPathNames = ['/', '/allas', '/auto', '/ingatlan', '/belepes', '/regisztracio', '/regisztracio/megerosites', '/szallitas/rendeles/[listId]', '/atlathatosagi-jelentes', '/adathalaszat', '/boltok', '/allas/kiemelt-partnereink', '/fiok/postalada/[[...slug]]', '/fiok/hirdeteseim', '/fiok/haztol-hazig/eladok', '/fiok/mentett_keresesek', '/fiok/kedvencek', '/fiok', '/informaciok-nyilatkozatokrol', '/rolunk', '/oldalterkep', '/404', '/500'];
    return !excludedPathNames.includes(pathname);
  };

  const checkLoginRegPage = () => {
    const pathNames = ['/belepes', '/regisztracio', '/regisztracio/megerosites'];
    return pathNames.includes(pathname);
  };

  const showPackagesButton = () => {
    const pathNames = ['/', '/allas', '/auto', '/ingatlan', '/belepes', '/regisztracio', '/regisztracio/megerosites'];
    return pathNames.includes(pathname);
  };

  const showAdInsertionButton = () => !showPackagesButton() && pathname !== '/szallitas/rendeles/[listId]';

  return (
    <AppBar id="app-bar-header" elevation={0} sx={{ borderBottom: '1px solid #e9e9e9' }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <MenuButton
            openSideMenu={openSideMenu}
            unreadMessages={unreadMessages}
          />
          <Logo showSearchForm={showSearchForm} vertical={vertical} pathname={pathname} />
          <Stack
            direction="row"
            justifyContent={{ xs: 'flex-end', lg: 'space-between' }}
            alignItems="center"
            sx={{ flexGrow: 1 }}
          >
            {showSearchBar() && (
              <>
                <Searchbar
                  showSearchForm={showSearchForm}
                  setShowSearchForm={setShowSearchForm}
                  vertical={vertical}
                  mainCategory={mainCategory}
                />
                <Portal>
                  <Backdrop
                    open={showSearchForm}
                    onClick={() => setShowSearchForm(false)}
                    sx={{
                      top: { xs: '56px', sm: '65px' },
                      display: { xs: 'block', lg: 'none' },
                      zIndex: (theme) => theme.zIndex.drawer - 1,
                    }}
                  />
                </Portal>
              </>
            )}
            {showAdInsertionButton() && (
              <AdInsertionButtonGroup
                account={account}
                pathname={pathname}
                showSearchForm={showSearchForm}
              />
            )}
          </Stack>
          {!loading && !checkLoginRegPage() && (
            <UserAuthButtons
              account={account}
              unreadMessages={unreadMessages}
            />
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

Header.propTypes = {
  pathname: PropTypes.string.isRequired,
  openSideMenu: PropTypes.func.isRequired,
};

export default Header;
