import { useCallback, useEffect } from 'react';

import { DidomiSDK } from '@didomi/react';

import { didomiApiKey, didomiLogoUrl, didomiSDKPath } from '@/config';
import { useMarketingContext } from '@/contexts/Marketing/MarketingContext';
import { useUserContext } from '@/contexts/User/UserContext';
import {
  addOnAppBoyScriptLoadHandler, cookiesAllowed, startBraze, stopBraze,
} from '@/helpers/braze';
import { updatePulseEnabled, setDidomiConsentString } from '@/helpers/didomi';
import tealium from '@/helpers/tealium';

const Didomi = () => {
  const { setDidomiReady, isDidomiReady, setDidomiNoticeShown } = useMarketingContext();
  const { userState } = useUserContext();
  const { loading } = userState;

  useEffect(() => {
    if (loading === false && isDidomiReady) {
      tealium.sendView();
    }
  }, [loading, isDidomiReady]);

  const onReady = useCallback(() => {
    if (!window.Didomi.shouldConsentBeCollected()) {
      updatePulseEnabled();

      if (window.appboy === undefined) {
        addOnAppBoyScriptLoadHandler();
      } else {
        startBraze();
      }

      setDidomiConsentString();
      setDidomiReady(true);
    }
  }, [setDidomiReady]);

  const onConsentChanged = useCallback(() => {
    if (!cookiesAllowed()) {
      stopBraze();
    } else {
      startBraze();
    }
    if (!window.Didomi.manualConsentChange) {
      updatePulseEnabled();
      setDidomiConsentString();
      tealium.sendView();
    } else {
      window.location.reload();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <DidomiSDK
        apiKey={didomiApiKey}
        logoUrl={didomiLogoUrl}
        iabVersion={2}
        sdkPath={didomiSDKPath}
        gdprAppliesGlobally
        onReady={() => onReady()}
        onConsentChanged={() => onConsentChanged()}
        onNoticeHidden={() => setDidomiNoticeShown(true)}
        onNoticeShown={() => setDidomiNoticeShown(false)}
      />
    </div>
  );
};

export default Didomi;
