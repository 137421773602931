/* eslint-disable max-lines */
import { huHU } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';

const breakpoints = {
  values: {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1200,
  },
};

const viewportTypeBreakpoints = {
  mobile: 0,
  desktop: 992,
};

const palette = {
  text: {
    dark: 'rgba(255,255,255,0.87)', // Dark/Text/Primary
    primary: 'rgba(0,0,0,0.86)', // Light/Text/Primary
    secondary: 'rgba(0,0,0,0.6)', // Light/Text/Secondary
    disabled: 'rgba(0,0,0,0.38)', // Light/Text/Disabled
  },
  primary: {
    lighter: '#fef1eb', // Light/Primary/Background
    light: '#ffa45e', // Light/Primary/Light
    main: '#f07330', // Light/Primary/Main
    dark: '#b74400', // Light/Primary/Dark
    contrastText: '#ffffff', // ??
  },
  secondary: {
    lighter: '#ebf0f5', // Light/Secondary/Background
    light: '#6693ca', // Light/Secondary/Light
    main: '#336699', // Light/Secondary/Main
    dark: '#003c6b', // Light/Secondary/Dark
    darker: '#213144', // ??
    background: '#ebf0f5',
    contrastText: '#ffffff', // ??
  },
  success: {
    main: '#18c662', // Light/Success/Main
    light: '#63fa91', // Light/Success/Light
    dark: '#009435', // Light/Success/Dark
    background: '#e8f9f0', // Light/Success/Background
    contrastText: '#ffffff', // ??
  },
  info: {
    main: '#6c8fb1', // Light/Info/Main
    light: '#9cbfe3', // Light/Info/Light
    dark: '#3d6281', // Light/Info/Dark
    background: '#f1f4f7', // Light/Info/Background
    contrastText: '#ffffff', // ??
  },
  infoVariant: { // overriding the "Info" icon color
    main: '#6c8fb1',
    light: '#6c8fb1',
  },
  error: {
    main: '#ef4848', // Light/Error/Main
    light: '#ff7c74', // Light/Error/Light
    dark: '#b60020', // Light/Error/Dark
    background: '#fdeded', // Light/Error/Background
    contrastText: '#ffffff', // ??
  },
  warning: {
    main: '#f39d21', // Light/Warning/Main
    light: '#ffce56', // Light/Warning/Light
    dark: '#bb6f00', // Light/Warning/Dark
    background: '#fef5e9', // Light/Warning/Background
    contrastText: '#ffffff',
  },
  background: {
    default: '#e9e9e9', // Light/Background/Default
    paper: '#ffffff', // Light/Background/Paper
    blueBar: '#ebf0f5', // Light/Background/Blubar
  },
  other: {
    outlinedBorder: 'rgba(0,0,0,0.23)', // Light/Other/Outlined Border
  },
  action: {
    passive: 'rgba(0,0,0,0.6)', // Light/Action/Passive
    hover: 'rgba(0,0,0,0.04)',
  },
  placeholderBackground: '#f1f4f7',
};

const typography = {
  h1: {
    fontSize: '1.5rem', // 24px
    lineHeight: '1.75rem', // 28px
    fontWeight: 400, // normal
    [`@media (min-width:${breakpoints.values.sm}px)`]: {
      fontSize: '1.75rem', // 28px
      lineHeight: '2rem', // 32px
      fontWeight: 400, // normal
    },
  },
  h2: {
    fontSize: '1.5rem', // 24px
    lineHeight: '1.75rem', // 28px
    fontWeight: 500, // bold
  },
  h3: {
    fontSize: '1.25rem', // 20px
    lineHeight: '1.5rem', // 24px
    fontWeight: 500, // bold
  },
  h4: {
    fontSize: '1.125rem', // 18px
    lineHeight: '1.375rem', // 22px
    fontWeight: 500, // bold
  },
  h5: {
    fontSize: '1rem', // 16px
    lineHeight: '1.25rem', // 20px
    fontWeight: 500, // bold
  },
  body1: {
    fontSize: '1rem', // 16px
    lineHeight: '1.5rem', // 24px
    fontWeight: 400, // normal
  },
  body2: {
    fontSize: '0.875rem', // 14px
    lineHeight: '1.375rem', // 22px
    fontWeight: 400, // normal
  },
  subtitle1: {
    fontSize: '14px',
    lineHeight: '1.125rem', // 18px
    letterSpacing: '0.15px',
    fontWeight: 500, // bold
  },
  subtitle2: {
    fontSize: '0.75rem', // 12px
    lineHeight: '1rem', // 16px
    letterSpacing: '0.25px',
    fontWeight: 500, // bold
  },
  caption: {
    fontSize: '0.75rem', // 12px
    lineHeight: '1rem', // 16px
    letterSpacing: '0.4px',
    fontWeight: 400, // normal
  },
  overline: {
    fontSize: '0.75rem', // 12px
    lineHeight: '1.375rem', // 22px
    letterSpacing: '1px',
    fontWeight: 400, // normal
  },
  button: {
    textTransform: 'none',
    fontWeight: 500, // bold
  },
  buttonMedium: {
    fontSize: '0.875rem', // 14px
    lineHeight: '1.5rem', // 24px
    letterSpacing: '0.5px',
  },
  regular: {
    fontSize: '0.75rem', // 12px,
    lineHeight: '1.65rem', // 16px
    fontWeight: 400,
  },
};

const components = {
  MuiAppBar: {
    defaultProps: {
      position: 'sticky',
      color: 'inherit',
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      noOptionsText: 'Nincs ilyen',
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        minWidth: 'min-content',
        fontSize: '1rem', // 16px
        lineHeight: '1.625rem', // 26px
        '&.MuiButton-textSizeSmall': {
          fontSize: '12px',
        },
        '&.MuiButton-textSizeSmall .MuiSvgIcon-fontSizeSmall': {
          width: '18px',
          height: '18px',
        },
      },
      containedPrimary: {
        backgroundColor: palette.primary.main,
        '&:hover': {
          backgroundColor: palette.primary.light,
        },
      },
      containedSecondary: {
        backgroundColor: palette.secondary.main,
        '&:hover': {
          backgroundColor: palette.secondary.light,
        },
      },
      containedSuccess: {
        backgroundColor: palette.success.main,
        '&:hover': {
          backgroundColor: palette.success.light,
        },
      },
      startIcon: {
        width: '24px',
        height: '24px',
        img: {
          width: '24px',
          height: '24px',
        },
      },
    },
    defaultProps: {
      variant: 'contained',
      disableElevation: true,
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        width: '24px',
        height: '24px',
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      color: 'secondary',
    },
  },
  MuiRadio: {
    defaultProps: {
      color: 'secondary',
    },
  },
  MuiLink: {
    defaultProps: {
      color: 'secondary',
      underline: 'hover',
    },
  },
  MuiListSubheader: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        backgroundColor: palette.secondary.darker,
      },
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      root: {
        input: {
          color: palette.text.primary,
        },
        '&:not(.Mui-error):not(.Mui-focused):not(.Mui-disabled)': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: palette.text.secondary,
          },
        },
        '&:hover:not(.Mui-error):not(.Mui-focused):not(.Mui-disabled)': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: palette.secondary.main,
          },
        },
        '&.Mui-disabled': {
          color: palette.text.disabled,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: palette.text.disabled,
            borderStyle: 'dotted',
          },
        },
      },
    },
  },
  MuiInputLabel: {
    defaultProps: {
      variant: 'outlined',
    },
    styleOverrides: {
      root: {
        color: palette.text.secondary,
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      size: 'small',
      color: 'secondary',
      fullWidth: true,
      variant: 'outlined',
    },
  },
  MuiPagination: {
    defaultProps: {
      color: 'secondary',
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        height: 4,
      },
    },
  },
  MuiStep: {
    styleOverrides: {
      root: {
        '& .MuiStepLabel-iconContainer .Mui-active': {
          color: palette.secondary.main,
        },
        '& .MuiStepLabel-iconContainer .Mui-completed': {
          color: palette.success.main,
        },
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        [`@media (min-width: ${breakpoints.values.xs})`]: {
          paddingRight: '8px',
          paddingLeft: '8px',
        },
        [`@media (min-width: ${breakpoints.values.md}px)`]: {
          paddingRight: '16px',
          paddingLeft: '16px',
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: '16px',
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '16px',
      },
    },
  },
};

const theme = createTheme({
  spacing: 4,
  breakpoints,
  viewportTypeBreakpoints,
  palette,
  typography,
  components,
}, huHU);

export default theme;
