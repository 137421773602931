import { useEffect, useState } from 'react';

import {
  Box, Container, Divider, Grid, Stack, Typography, Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';

import {
  FIRST_COLUMN,
  SECOND_COLUMN,
  THIRD_COLUMN,
  FOURTH_COLUMN,
} from './data/footerLinksData';
import socialLinksData from './data/socialLinksData';
import UsefulLinks from './UsefulLinks';
import AppSuggester from '../AppSuggester';
import JfgSeoLinks from '../SeoLinks/index.server';
import SeoCar from '../SeoLinks/SeoCar';
import SeoJob from '../SeoLinks/SeoJob';
import SeoRealEstate from '../SeoLinks/SeoRealEstate';
import ShrinkingImage from '../ShrinkingImage';
import JfgTermsAndConditions from '@/components/common/TermsAndConditions/index.client';
import getTermsAndConditions from '@/data/client/termsAndConditions';

const StyledDivider = styled((props) => (
  <Divider
    sx={{
      borderColor: '#44607c',
      borderWidth: 0,
      borderBottomWidth: '1px',
      width: '100%',
      display: {
        sm: 'none',
      },
    }}
    orientation="vertical"
    variant="middle"
    flexItem
    {...props}
  />
))({});

const renderSeoLinks = (route: string): JSX.Element => {
  switch (route) {
    case '/allas':
      return <SeoJob />;
    case '/ingatlan':
      return <SeoRealEstate />;
    case '/auto':
      return <SeoCar />;
    default:
      return <JfgSeoLinks />;
  }
};

const Footer = (): JSX.Element => {
  const [termsAndConditions, setTermsAndConditions] = useState({});
  const router = useRouter();
  useEffect(() => {
    getTermsAndConditions()
      .then((result) => setTermsAndConditions(result));
  }, []);

  return (
    <>
      <AppSuggester />
      <JfgTermsAndConditions terms={termsAndConditions} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        py={6}
        bgcolor="white"
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Typography
          variant="body2"
          mr={{ xs: 0, md: 4 }}
          mb={{ xs: 1, md: 0 }}
        >
          A Jófogást megtalálod a közösségi oldalakon is!
        </Typography>
        <Stack
          id="ob-social"
          direction="row"
          spacing={6}
        >
          {socialLinksData.map((link) => (
            <Link
              key={`${link.name}`}
              width="32px"
              height="32px"
              href={link.url}
              target="_blank"
            >
              <img src={link.iconUrl} alt={link.name} style={{ width: '100%' }} />
            </Link>
          ))}
        </Stack>
      </Box>
      {renderSeoLinks(router.pathname)}
      <Grid
        component="footer"
        sx={{ bgcolor: 'secondary.darker', color: 'info.main' }}
        id="footer-container"
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          rowGap={6}
          pb={6}
          divider={<Divider sx={{ borderColor: '#44607c' }} flexItem />}
        >
          <Container sx={{ padding: 0 }}>
            <Grid container rowGap={4} pt={6}>
              <Grid container columns={{ xs: 1, sm: 4 }} alignItems="flex-start" justifyContent="start">
                <UsefulLinks links={FIRST_COLUMN} />
                <UsefulLinks links={SECOND_COLUMN} />
                <StyledDivider sx={{ marginBottom: 4, paddingBottom: 2 }} />
                <UsefulLinks links={THIRD_COLUMN} />
                <UsefulLinks links={FOURTH_COLUMN}>
                  <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                    <img
                      width={200}
                      height={20}
                      src="/assets/footer/barion.svg"
                      alt="Bankkártya"
                      loading="lazy"
                    />
                  </Box>
                </UsefulLinks>
              </Grid>
              <StyledDivider sx={{ marginTop: 0 }} />
              <Grid container columns={4} alignItems="center" justifyContent="space-around" columnSpacing={2}>
                <Grid item sx={{ display: { xs: 'none', sm: 'flex' } }} sm={1}>
                  {/* @ts-expect-error todo */}
                  <ShrinkingImage
                    width={184}
                    height={46}
                    src="/assets/footer/copyright_vedd_tovabb.svg"
                    alt="copyright"
                    loading="lazy"
                  />
                </Grid>
                <Grid item xs={4} sm={3} data-nosnippet>
                  <Typography variant="caption" paragraph mb={0} pl={{ xs: 2, sm: 0 }}>
                    Szerzői jogi védelem alatt álló oldal.
                    A honlapon elhelyezett szöveges és képi anyagok, arculati és tartalmi elemek
                    (pl. betűtípusok, gombok, linkek, ikonok, szöveg, kép, grafika, logo stb.)
                    felhasználása, másolása, terjesztése, továbbítása - akár részben, vagy egészben
                    - kizárólag a Jófogás előzetes, írásos beleegyezésével lehetséges.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Stack>
      </Grid>
    </>
  );
};

export default Footer;
