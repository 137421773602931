import { Box, Link } from '@mui/material';

import {
  homeUrl, carHomeUrl, jobHomeUrl, realEstateHomeUrl,
} from '@/config';

const LogoLink = ({
  href, showSearchForm, children,
}) => (
  <Link
    href={href}
    title="Jófogás"
    sx={{
      display: { xs: showSearchForm ? 'none' : 'inline-flex', sm: 'inline-flex' },
      mr: { xs: 2, md: 8 },
    }}
  >
    {children}
  </Link>
);

const Logo = ({ showSearchForm, vertical, pathname }) => {
  if (pathname === '/ingatlan' || vertical === 'realestate') {
    return (
      <LogoLink href={realEstateHomeUrl} showSearchForm={showSearchForm}>
        <img src="/assets/svg/jfg_logo_realestate_2024.svg" alt="logó" height={40} width={100} />
      </LogoLink>
    );
  }

  if (pathname === '/allas' || vertical === 'job') {
    return (
      <LogoLink href={jobHomeUrl} showSearchForm={showSearchForm}>
        <img src="/assets/svg/jfg_logo_job_2024.svg" alt="logó" height={40} width={100} />
      </LogoLink>
    );
  }

  if (pathname === '/auto' || vertical === 'car') {
    return (
      <LogoLink href={carHomeUrl} showSearchForm={showSearchForm}>
        <img src="/assets/svg/jfg_logo_vehicle_2024.svg" alt="logó" height={40} width={100} />
      </LogoLink>
    );
  }

  return (
    <LogoLink href={homeUrl} showSearchForm={showSearchForm}>
      <Box
        component="img"
        sx={{
          width: { xs: 100, md: 149 },
          height: { xs: 24, md: 32 },
        }}
        src="/assets/svg/jfg_logo_vedd_tovabb.svg"
        alt="logó"
      />
    </LogoLink>
  );
};

export default Logo;
