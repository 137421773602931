import { SocialLink } from '../types';

const socialLinksData: SocialLink[] = [
  {
    name: 'Jofogas Facebook',
    url: 'https://facebook.com/jofogas',
    iconUrl: '/assets/footer/facebook.png',
  },
  {
    name: 'Jofogas Instagram',
    url: 'https://www.instagram.com/jofogas/',
    iconUrl: '/assets/footer/insta.png',
  },
  {
    name: 'Jofogas Youtube',
    url: 'https://www.youtube.com/user/jofogas',
    iconUrl: '/assets/footer/youtube.png',
  },
  {
    name: 'Jofogas LinkedIn',
    url: 'https://hu.linkedin.com/company/adevintacmhkft',
    iconUrl: '/assets/footer/linkedin.png',
  },
];

export default socialLinksData;
