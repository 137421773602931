import { useCallback } from 'react';

import NextHead from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { NextSeo } from 'next-seo';

import { HeadProps } from './types';
import StructuredData from '../../marketing/StructuredData';
import { baseUrl } from '@/config';
import { useMarketingContext } from '@/contexts/Marketing/MarketingContext';

const Head: React.FunctionComponent<HeadProps> = ({
  title, description, canonical, productJsonLdData, ...args
}) => {
  const router = useRouter();
  // @ts-expect-error marketingContext has to be rewritten in TS
  const { setAdverticumReady } = useMarketingContext();

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={canonical}
        openGraph={{
          url: `${baseUrl}${router.pathname}`,
        }}
        {...args}
      />
      <NextHead>
        <link rel="icon" href="/assets/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
      </NextHead>
      <Script
        strategy="lazyOnload"
        src="https://ad.adverticum.net/g3.js"
        onLoad={useCallback(() => {
          // @ts-expect-error adverticum shenanigans
          window.goAdverticum3.onReady(() => {
            setAdverticumReady(true);
          });
        }, [setAdverticumReady])}
      />
      <StructuredData productJsonLdData={productJsonLdData} />
    </>
  );
};

export default Head;
