import PropTypes from 'prop-types';

import { mapping, undefinedCategoryTrackerId } from './data';
import TrackingPixel from '../TrackingPixel/index.client';

const getTrackerId = (categoryId) => {
  if (categoryId === undefined) {
    return undefinedCategoryTrackerId;
  }

  if (mapping[categoryId]) {
    return mapping[categoryId];
  }

  return null;
};

const CategoryTrackingPixel = ({ categoryId }) => {
  const trackerId = getTrackerId(categoryId);
  if (trackerId === null) {
    return null;
  }

  return <TrackingPixel trackerId={trackerId} />;
};

CategoryTrackingPixel.defaultProps = {
  categoryId: undefined,
};

CategoryTrackingPixel.propTypes = {
  categoryId: PropTypes.number,
};

export default CategoryTrackingPixel;
