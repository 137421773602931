import { useEffect, useState } from 'react';

import { CacheProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ErrorBoundary } from 'next/dist/client/components/error-boundary';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';

import Custom500 from './500';
import theme from '../styles/theme';
import Layout from '@/components/common/Layout';
import ABTasty from '@/components/marketing/ABTasty';
import Braze from '@/components/marketing/Braze';
import Didomi from '@/components/marketing/Didomi';
import Tealium from '@/components/marketing/Tealium';
import UtagData from '@/components/marketing/UtagData';
import { baseUrl } from '@/config';
import DimensionsContextWrapper from '@/contexts/Dimensions/DimensionsContextWrapper';
import MarketingContextWrapper from '@/contexts/Marketing/MarketingContext';
import ModalContextWrapper from '@/contexts/Modal/ModalContext';
import { initialize } from '@/contexts/User/helpers';
import UserContextWrapper from '@/contexts/User/UserContext';
import createEmotionCache from '@/helpers/createEmotionCache';
import { getMainCategory, getVertical } from '@/helpers/product/product';
import handleGeneralErrorEvents from '@/helpers/sentry/handleGeneralErrorEvents';
import utagData from '@/helpers/utagData';

import '../styles/countdown.css';
import '../styles/globals.css';
import '../styles/customSwiperStyle.css';

const clientSideEmotionCache = createEmotionCache();

const initialState = {
  account: undefined,
  token: undefined,
  loading: true,
};

const MyApp = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const { appl, category, product } = pageProps;

  const mainCategory = getMainCategory(product);

  const vertical = getVertical(product);

  const [userState, setUserState] = useState(initialState);

  const router = useRouter();

  useEffect(() => {
    const pageLoaded = () => {
      const loaded = parseInt(localStorage.getItem('jfg-loaded-counter') || 0, 10) + 1;
      localStorage.setItem('jfg-loaded-counter', loaded);
    };

    // eslint-disable-next-line no-unused-vars
    router.events.on('routeChangeStart', (url, { shallow }) => {
      pageLoaded();
    });
    pageLoaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.body.addEventListener('error', handleGeneralErrorEvents, true);

    return () => {
      document.body.removeEventListener('error', handleGeneralErrorEvents, true);
    };
  }, []);

  useEffect(() => {
    initialize()
      .then((newState) => {
        utagData.setAccountVariables(newState.account);
        setUserState({ ...newState, loading: false });
        if (pageProps.isProtected && !newState.account) {
          const backUrl = encodeURIComponent(window.location.href).replace(/\./g, '%2E');
          window.location.assign(pageProps.redirectUrl || `${baseUrl}/belepes?backUrl=${backUrl}`);
        }
      });
  }, [pageProps]);

  if (pageProps.isProtected && !userState.account) {
    return (
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <DimensionsContextWrapper>
            <MarketingContextWrapper>
              <UserContextWrapper value={{ userState, setUserState }}>
                <ModalContextWrapper>
                  <Layout>
                    <ErrorBoundary errorComponent={Custom500}>
                      <div data-testid="auth-loading">Betöltés...</div>
                    </ErrorBoundary>
                  </Layout>
                </ModalContextWrapper>
              </UserContextWrapper>
            </MarketingContextWrapper>
          </DimensionsContextWrapper>
        </ThemeProvider>
      </CacheProvider>
    );
  }
  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <DimensionsContextWrapper>
          <MarketingContextWrapper>
            <UserContextWrapper value={{ userState, setUserState }}>
              <ModalContextWrapper>
                <Layout
                  appl={appl}
                  category={category}
                  mainCategory={mainCategory}
                  vertical={vertical}
                >
                  <CssBaseline />
                  <DefaultSeo
                    openGraph={{
                      type: 'website',
                      locale: 'hu_HU',
                      site_name: 'Jofogas.hu',
                      images: [{
                        url: `${baseUrl}/assets/seo/facebook_splash_screen.png`,
                      }],
                    }}
                  />
                  <UtagData />
                  <Didomi />
                  <Braze />
                  <ABTasty />
                  <Tealium />
                  <ErrorBoundary errorComponent={Custom500}>
                    <Component {...pageProps} />
                  </ErrorBoundary>
                </Layout>
              </ModalContextWrapper>
            </UserContextWrapper>
          </MarketingContextWrapper>
        </DimensionsContextWrapper>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default MyApp;
