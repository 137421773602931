import {
  Container, Divider, Grid, Link, Stack, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

const SeoLinks = ({ groups }) => (
  <>
    <Grid
      container
      sx={{ py: 6, bgcolor: 'secondary.darker', color: 'info.main' }}
    >
      <Container>
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          flexWrap="wrap"
          rowGap={4}
        >
          {groups.map(({ title, pages }) => (
            <div key={`SeoLinks-${title}`}>
              <Typography variant="subtitle2">{title}</Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                flexWrap="wrap"
                columnGap={1}
                divider={<Divider sx={{ borderColor: '#44607c' }} orientation="vertical" variant="middle" flexItem />}
              >
                {pages.map(({ label, link }) => (
                  <Link key={`SeoLink-${label}`} href={link} target="_blank" variant="caption" color="inherit">
                    {label}
                  </Link>
                ))}
              </Stack>
            </div>
          ))}
        </Stack>
      </Container>
    </Grid>
    <Divider sx={{ borderColor: '#44607c' }} flexItem />
  </>
);

SeoLinks.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    page: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
    })),
  })).isRequired,
};

export default SeoLinks;
