import { FooterLink } from '../types';

export const FIRST_COLUMN: Record<string, FooterLink> = {
  d2d: {
    url: 'https://docs.jofogas.hu/haztol-hazig/',
    label: 'Háztól-házig (GLS, HDT)',
  },
  foxpost: {
    url: 'https://foxpost.jofogas.hu',
    label: 'Foxpost csomagküldés',
  },
  highlight: {
    url: 'https://docs.jofogas.hu/',
    label: 'Kiemelés',
  },
  offer: {
    url: 'https://ajanlataink.jofogas.hu/',
    label: 'Ajánlataink üzleti felhasználóknak',
  },
  realEstate: {
    url: 'https://ajanlataink.jofogas.hu/realestate.html',
    label: 'Ajánlataink ingatlanközvetítőknek',
  },
};

export const SECOND_COLUMN: Record<string, FooterLink> = {
  us: {
    url: 'https://www.jofogas.hu/rolunk',
    label: 'Rólunk',
  },
  adInsertion: {
    url: 'https://hirdetesfeladas.jofogas.hu/',
    label: 'Hirdetésfeladás',
  },
  terms: {
    url: 'https://docs.jofogas.hu/szabalyzat/',
    label: 'Felhasználói szabályzat',
  },
  aszfRe: {
    url: 'https://docs.jofogas.hu/jofogas-ingatlan-uzleti-altalanos-szerzodesi-feltetelek/',
    label: 'Ingatlan Üzleti ÁSZF',
  },
  aszfJob: {
    url: 'https://docs.jofogas.hu/jofogas-allas-bolt-uzleti-altalanos-szerzodesi-feltetelek/',
    label: 'Állás Üzleti ÁSZF',
  },
  aszfShop: {
    url: 'https://docs.jofogas.hu/jofogas-bolt-uzleti-altalanos-szerzodesi-feltetelek/',
    label: 'Bolt Üzleti ÁSZF',
  },
};

export const THIRD_COLUMN:
Record<string, FooterLink | { onClick: () => void, id: string, label: string }> = {
  dataProtection: {
    url: 'https://adatvedelem.jofogas.hu/adatvedelmi-tajekoztato/',
    label: 'Adatvédelem',
  },
  transparencyReport: {
    url: 'https://www.jofogas.hu/atlathatosagi-jelentes',
    label: 'Átláthatósági jelentés',
  },
  InformationDeclaration: {
    url: 'https://www.jofogas.hu/informaciok-nyilatkozatokrol',
    label: 'Nyilatkozatok',
  },
  safeShopping: {
    url: 'https://ugyfelszolgalat.jofogas.hu/extra-szolgaltatasok/szabalysertes-jelentese/',
    label: 'Biztonságos vásárlás',
  },
  custumerSupport: {
    url: 'https://ugyfelszolgalat.jofogas.hu/',
    label: 'Ügyfélszolgálat',
  },
  cookieSettings: {
    onClick: () => {
      // @ts-expect-error todo
      window.Didomi.manualConsentChange = true;
      // @ts-expect-error todo
      window.Didomi.preferences.show();
    },
    id: 'open-cookie-settings',
    label: 'Süti beállítások',
  },
};

export const FOURTH_COLUMN: Record<string, FooterLink> = {
  cookieHandling: {
    url: 'https://adatvedelem.jofogas.hu/sutikezeles/',
    label: 'Süti kezelés',
  },
  phishing: {
    url: 'https://www.jofogas.hu/adathalaszat',
    label: 'Adathalászat',
  },
  sitemap: {
    url: 'https://www.jofogas.hu/oldalterkep',
    label: 'Oldaltérkép',
  },
  otp: {
    url: '/assets/img/OTP_vasarloi_tajekoztato.pdf',
    label: 'Vásárlói tájékoztató',
  },
  career: {
    url: 'https://karrier.jofogas.hu/',
    label: 'Karrier',
  },
  blog: {
    url: 'https://blog.jofogas.hu/#xtor=AD-9319',
    label: 'Blog',
  },
};
