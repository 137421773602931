import {
  useEffect, useRef, useState,
} from 'react';

import { styled } from '@mui/material';

import {
  loadAdverticum, unloadAdverticum, getZoneID, loadFallback,
} from '@/contexts/Marketing/helpers';
import { useMarketingContext } from '@/contexts/Marketing/MarketingContext';
import useViewportType from '@/hooks/useViewportType';
import useWindowSize from '@/hooks/useWindowSize';

type AdverticumZoneProps = {
  pageType: string,
  zoneName: string,
  displayOn?: string,
  env?: ('development' | 'production' | 'test'),
  category?: number,
  hideWhenNotLoaded?: boolean,
  noFallback?: boolean,
  'data-price'?: number,
  style: any
};

type functionType = () => void;

const ElementContainer = styled('div')(({ theme }) => ({
  minHeight: '280px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  paddingTop: theme.spacing(5),
}));

const AdverticumLabel = styled('div')(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  backgroundColor: 'inherit',
  fontSize: theme.typography.caption.fontSize,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  height: theme.spacing(5),
  color: theme.palette.text.disabled,
  zIndex: 9,
}));

const JfgAdverticumZone: React.FunctionComponent<AdverticumZoneProps> = ({
  pageType,
  zoneName,
  displayOn = 'all',
  env,
  category,
  hideWhenNotLoaded,
  noFallback,
  ...args
}) => {
  const ID = getZoneID({
    pageType, zoneName, displayOn, env, category,
  });
  const { adverticumReady } = useMarketingContext();
  const size = useWindowSize();
  const viewportType = useViewportType(size);
  const adverticumRef = useRef(null);
  const [mounted, setMounted] = useState(false);
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [fallbackImage, setFallbackImage] = useState<{
    url: string,
    width: number,
    height: number,
    image: string,
  } | null>(null);

  // Get a fallback image for the zone, just in case
  useEffect(() => {
    if (fallbackImage === null) {
      const fallback = loadFallback({ pageType, zoneName, displayOn });
      setFallbackImage(fallback);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendDataPriceToCalculator: functionType = () => {
    const priceDataTag = args?.['data-price'] || 0;
    document
      ?.getElementById(`adverticum-${ID}`)
      ?.querySelector('iframe')
      ?.contentWindow
      ?.postMessage({ price: priceDataTag }, '*');
  };

  const calculatorListener: functionType = () => {
    window?.addEventListener('message', (event) => {
      if (event.origin === 'https://ad.adverticum.net' && event.data?.status === 'ready') {
        sendDataPriceToCalculator();
      }
    });
  };

  useEffect(() => {
    type Zone = {
      id: string,
      node?: HTMLElement | undefined | null,
      displayOn: string,
    };
    const zones: Array<Zone> = [];

    if (
      adverticumReady && viewportType !== null
      && (displayOn === 'all' || viewportType === displayOn)
    ) {
      zones.push({ id: ID, node: adverticumRef.current, displayOn });

      loadAdverticum({ zones })
        .then((res) => {
          const loaded = res.find((banner: { zone: number }) => banner.zone === parseInt(ID, 10));

          if (loaded) {
            setMounted(true);
          }
        })
        .catch(() => null);
    }

    setLoadingFlag(false);
    return (): void => {
      if (viewportType !== null) {
        unloadAdverticum({ zones });
      }
    };
  }, [
    viewportType,
    displayOn,
    pageType,
    zoneName,
    category,
    adverticumReady,
    ID,
    loadingFlag,
  ]);

  useEffect(() => {
    if (zoneName === 'calculator' && args?.['data-price'] && args?.['data-price'] > 0) {
      calculatorListener();
    }
  });

  // This is a hack to make the component wait for a while before deciding what to render
  setTimeout(() => {
    setLoadingFlag(false);
  }, 1500);

  const renderDefault: functionType = () => (
    <ElementContainer
      id={`adverticum_${zoneName}_container`}
      className={
        `adverticum-container ${mounted ? 'loaded' : ''} ${hideWhenNotLoaded && 'hide-when-not-loaded'}`
      }
      {...args}
    >
      <AdverticumLabel className="adverticum-label">Hirdetés</AdverticumLabel>
      <div ref={adverticumRef} id={`adverticum-${ID}`} />
    </ElementContainer>
  );

  const renderFallback: functionType = () => (
    fallbackImage && (
      <ElementContainer {...args}>
        <AdverticumLabel className="adverticum-label">Hirdetés</AdverticumLabel>
        <a
          href={fallbackImage.url}
          style={{
            display: 'block',
            maxWidth: fallbackImage.width,
            maxHeight: fallbackImage.height,
            width: '100%',
            height: '100%',
          }}
        >
          <img
            src={`/assets/img/fallback-creatives/${fallbackImage.image}`}
            alt="Jófogás promóció"
            width="100%"
            height="100%"
            loading="lazy"
          />
        </a>
      </ElementContainer>
    )
  );

  // skip render of the component if viewportType has not been set
  if (viewportType === null) {
    return null;
  }
  // skip render of the component if there is no specified zoneID
  if (ID === 0) {
    return null;
  }
  if (
    // if the prop is set to 'all', then this component is always rendered
    displayOn !== 'all'
    // if the prop is set, then this component is only rendered if the device matches
    && viewportType !== displayOn) {
    return null;
  }

  return (
    <>
      {(!loadingFlag && !adverticumReady && noFallback) && null}
      {(!loadingFlag && !adverticumReady) && renderFallback()}
      {(!loadingFlag && adverticumReady) && renderDefault()}
    </>
  );
};

export default JfgAdverticumZone;
