import { getPageMainTitle } from '@/helpers/product/product';
import { formatCategoryPath } from '@/helpers/utils';

const utagData = {
  setAccountVariables: (account) => {
    // Initializes the utag data if it doesn't exist
    window.utag_data = window.utag_data || {};
    if (account) {
      window.utag_data.user_id = account.account_id;
      window.utag_data.user_role_id = account.is_company ? '2' : '1';
      window.utag_data.acmh_account_id = account.account_id;
    } else {
      delete window.utag_data?.user_id;
      delete window.utag_data?.user_role_id;
      delete window.utag_data?.acmh_account_id;
    }
  },

  setProductPageVariables: (product) => {
    // Initializes the utag data if it doesn't exist
    window.utag_data = window.utag_data || {};

    if (product) {
      const ud = window.utag_data;

      ud.acmh_page_name = getPageMainTitle(product);

      ud.event_name = product?.business_id || product?.is_shop || product?.company_ad ? 'Ads::ad_company' : 'Ads::ad_private';

      ud.acmh_ad_type = product?.type?.value === 's' ? 'sell' : 'buy';

      ud.acmh_list_id = `${product?.list_id}` || '';

      ud.acmh_publisher_type = product?.business_id || product?.is_shop || product?.company_ad ? 'pro' : 'private';

      ud.acmh_category_path = formatCategoryPath(product?.category_tree);

      ud.acmh_price = product?.price?.value !== undefined ? product.price.value : 0;

      ud.acmh_location_city = (
        Array.isArray(product?.parameters)
        && product.parameters.filter((item) => item.key === 'zipcode')?.[0]?.values?.[0]?.label
      ) || '';

      ud.acmh_location_region = product?.region?.label;

      ud.acmh_location_postal_code = (
        Array.isArray(product?.parameters)
        && product.parameters.filter((item) => item.key === 'zipcode')?.[0]?.values?.[0]?.value
      ) || '';

      ud.gemius_category = product?.category_tree[2]?.id
        || product?.category_tree[1]?.id
        || product?.category_tree[0]?.id;
    } else {
      window.utag_data.acmh_page_name = document.title;
    }
  },

  setContentGroupVariables: (pageTypeData) => {
    const event = new Event('contentGroupSet');

    if (typeof (window) !== 'undefined') {
      window.utag_data = window.utag_data || {};

      if (pageTypeData) {
        window.utag_data.page_category_1 = pageTypeData.page_category_1;
        window.utag_data.page_category_2 = pageTypeData.page_category_2;
        document.dispatchEvent(event);
      }
    }
  },
};

export default utagData;
