import * as Sentry from '@sentry/nextjs';

const handleGeneralErrorEvents = (event) => {
  if (!event.target) return;

  if (event.target.tagName === 'IMG') {
    Sentry.captureException(`Failed to load image: ${event.target.src}`);
  } else if (event.target.tagName === 'LINK') {
    Sentry.captureException(`Failed to load css: ${event.target.href}`);
  }
};

export default handleGeneralErrorEvents;
