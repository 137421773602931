/**
 * @param {HTMLImageElement} props Image properties
*/
const ShrinkingImage = ({ alt, ...props }) => (
  <img
    alt={alt}
    {...props}
    style={{ maxWidth: '100%' }}
  />
);

export default ShrinkingImage;
