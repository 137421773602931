import { AppSuggesterApp } from './types';

const appSuggesterData: AppSuggesterApp[] = [
  {
    id: 'ios',
    alt: 'Töltsd le alkalmazásunkat iOS-re',
    url: 'https://itunes.apple.com/hu/app/jofogas-aprohirdetesek/id1064914384?mt=8',
    src: '/assets/apps/ios.svg',
  },
  {
    id: 'android',
    alt: 'Töltsd le alkalmazásunkat Androidra',
    url: 'https://play.google.com/store/apps/details?id=com.schibsted.iberica.jofogas',
    src: '/assets/apps/android.svg',
  },
  {
    id: 'huawei',
    alt: 'Töltsd le alkalmazásunkat Huawei-re',
    url: 'https://appgallery.cloud.huawei.com/ag/n/app/C101526927',
    src: '/assets/apps/huawei.svg',
  },
];

export default appSuggesterData;
