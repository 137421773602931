import PropTypes from 'prop-types';

const TrackingPixelURL = 'https://pixel.rubiconproject.com/tap.php?v=';

// TODO: check if rubicon support beacons for this feature https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon

const TrackingPixel = ({ trackerId }) => (
  <img
    src={`${TrackingPixelURL}${trackerId}`}
    style={{ display: 'none' }}
    alt=""
  />
);

TrackingPixel.propTypes = {
  trackerId: PropTypes.number.isRequired,
};

export default TrackingPixel;
