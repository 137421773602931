import utagData from './utagData';

// This is a workaround because I couldn't do it with Tealium
const gtagHelper = {
  SetPageTypeEvent: (pathName) => {
    const pageTypeData = {};

    switch (pathName) {
      case '/':
        pageTypeData.page_category_1 = 'Frontpage';
        break;

      case '/allas':
        pageTypeData.page_category_1 = 'JobHome';
        break;

      case '/ingatlan':
        pageTypeData.page_category_1 = 'RealestateHome';
        break;

      case '/auto':
        pageTypeData.page_category_1 = 'MotorHome';
        break;

      case '/boltok':
        pageTypeData.page_category_1 = 'GeneralistShopListing';
        break;

      case '/allas/kiemelt-partnereink':
        pageTypeData.page_category_1 = 'JobShopListing';
        break;

      case '/product/[listId]':
        pageTypeData.page_category_1 = 'AdView';
        // pageTypeData.page_category_2 = 'Ingatlan';
        // until we figure out how to set the cagegory here
        break;

      case '/belepes':
        pageTypeData.page_category_1 = 'Login';
        break;

      case '/regisztracio':
        pageTypeData.page_category_1 = 'Registration';
        break;

      case '/regisztracio/megerosites':
        pageTypeData.page_category_1 = 'EmailVerification';
        break;

      case '/fiok':
        pageTypeData.page_category_1 = 'Settings';
        break;

      case '/fiok/kedvencek':
        pageTypeData.page_category_1 = 'FavoriteAds';
        break;

      case '/fiok/mentett_keresesek':
        pageTypeData.page_category_1 = 'SavedSearch';
        break;

      case '/fiok/postalada':
        pageTypeData.page_category_1 = 'Messaging';
        break;

      case '/404':
        pageTypeData.page_category_1 = '404';
        break;

      default:
        pageTypeData.page_category_1 = null;
        pageTypeData.page_category_2 = null;
        break;
    }

    utagData.setContentGroupVariables(pageTypeData);
  },
};

export default gtagHelper;
