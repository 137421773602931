import { useMemo, useState } from 'react';

import LayoutContext from '@/contexts/Layout/LayoutContext';

const LayoutContextWrapper = ({
  children,
  countdown: iCountdown = {},
}) => {
  const [countdown, setCountdown] = useState(iCountdown);

  const initValue = useMemo(() => ({
    countdown,
    setCountdown,
  }), [countdown]);

  return (
    <LayoutContext.Provider value={initValue}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutContextWrapper;
