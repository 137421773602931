import { TRENDING_SEARCHES_CAR, TRENDING_CITIES, TRENDING_BRANDS_CAR } from './data';
import SeoLinks from './seo-links';

const SEO_GROUPS = [
  {
    title: 'Gyakori keresések',
    pages: TRENDING_SEARCHES_CAR,
  },
  {
    title: 'Népszerű városok',
    pages: TRENDING_CITIES,
  },
  {
    title: 'Népszerű márkák és modellek',
    pages: TRENDING_BRANDS_CAR,
  },
];

const SeoCar = () => <SeoLinks groups={SEO_GROUPS} />;

export default SeoCar;
