import { brazeApiKey, brazeLogEnabled, brazeSDKEndpoint } from '@/config';

const cookiesAllowed = () => !!window.Didomi?.getUserConsentStatusForVendor('c:braze-K2VYzZpR');
let isInitialized = false;
const isEnabled = () => !!window.appboy && isInitialized && cookiesAllowed();

const initialize = () => {
  window.appboy.initialize(brazeApiKey, {
    baseUrl: brazeSDKEndpoint,
    inAppMessageZIndex: 9000,
  });
  isInitialized = true;
};

const enablePushSubscription = (isPushAccepted) => {
  if (!isPushAccepted) {
    return;
  }

  let granted;
  try {
    granted = window.appboy.isPushPermissionGranted();
  } catch (e) {
    initialize();
    enablePushSubscription(isPushAccepted);
    return;
  }

  if (!granted) {
    return;
  }

  window.appboy.registerAppboyPushMessages();
};

const startBraze = () => {
  const loadedCount = parseInt(localStorage.getItem('jfg-loaded-counter') || 0, 10);
  if (!window.appboy || loadedCount < 3) {
    return;
  }
  if (cookiesAllowed()) {
    window.appboy.resumeWebTracking();

    initialize();
    window.appboy.display.automaticallyShowNewInAppMessages();
    if (brazeLogEnabled) {
      window.appboy.toggleAppboyLogging();
    }

    document.dispatchEvent(new Event('brazeStarted'));
  } else {
    window.appboy.stopWebTracking();
  }
};

const stopBraze = () => {
  try {
    window.appboy.stopWebTracking();
    window.appboy.wipeData();
    window.appboy.destroy();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Failed to stop braze: ${e}`);
  }
};

const startSession = () => {
  if (!isEnabled()) {
    return;
  }

  try {
    window.appboy.openSession();
  } catch (e) {
    initialize();
    startSession();
  }
};

const setAccountId = (accountId) => {
  if (isEnabled()) {
    window.appboy.changeUser(accountId);
  }
};

const customEvent = (eventName, eventData) => {
  if (!isEnabled()) {
    return;
  }

  if (eventData) {
    if (brazeLogEnabled) {
      // eslint-disable-next-line no-console
      console.table(eventData);
    }
    window.appboy.logCustomEvent(eventName, eventData);
    window.appboy.requestImmediateDataFlush();
  } else {
    window.appboy.logCustomEvent(eventName);
    window.appboy.requestImmediateDataFlush();
  }
};

const userChanged = (accountId, globalPushAccepted) => {
  if (isEnabled()) {
    enablePushSubscription(globalPushAccepted);
    setAccountId(accountId);
  }
};

const registerToPush = () => window.appboy.registerAppboyPushMessages();

const addOnAppBoyScriptLoadHandler = () => {
  document.getElementById('braze').addEventListener('load', () => {
    // add page loaded limit
    startBraze();
  });
};

export {
  cookiesAllowed,
  startBraze,
  stopBraze,
  startSession,
  setAccountId,
  customEvent,
  userChanged,
  registerToPush,
  enablePushSubscription,
  addOnAppBoyScriptLoadHandler,
  isEnabled,
};
