import {
  useState, createContext, useContext, useMemo, useCallback,
} from 'react';

const initialState = {
  displayModal: false,
  modalView: null,
  store: {},
  showModal: (): void => {},
  hideModal: (): void => {},
};

interface ModalState {
  displayModal: boolean;
  modalView: React.ReactNode | null;
  store: Record<string, any>;
  showModal: (modalView: any, modalProps: any) => void;
  hideModal: () => void;
}

export const ModalContext = createContext<ModalState>(initialState);
export const useModalContext = (): ModalState => useContext(ModalContext);

interface ModalContextWrapperProps {
  children: React.ReactNode[]
  initialValue?: object
}
const ModalContextWrapper = ({ children, initialValue = {} }: ModalContextWrapperProps):
React.ReactNode => {
  const [state, setState] = useState(initialValue);

  const hideModal = useCallback(() => {
    setState({
      ...state,
      displayModal: false,
    });
  }, [state]);

  const showModal = useCallback((modalView: any, modalProps: any) => {
    setState({
      ...state,
      modalProps,
      displayModal: true,
      modalView,
    });
  }, [state]);

  const value = useMemo(() => ({
    ...initialState,
    ...state,
    showModal,
    hideModal,
  }), [state, showModal, hideModal]);

  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContextWrapper;
