import { useState, useCallback } from 'react';

import { parseCookies, setCookie, destroyCookie } from 'nookies';

import { cookieDomain } from '@/config';

const useCookie = (cookieName) => {
  const [value, setValue] = useState(parseCookies()[cookieName] || null);

  const updateCookie = useCallback((newValue, options) => {
    const cookieOptions = { domain: cookieDomain, path: '/' };
    setCookie(null, cookieName, newValue, { ...cookieOptions, ...options });
    setValue(newValue);
  }, [cookieName]);

  const deleteCookie = useCallback(() => {
    destroyCookie(null, cookieName);
    setValue(null);
  }, [cookieName]);

  return [value, updateCookie, deleteCookie];
};

export default useCookie;
