import { apiKey } from '@/config';
import authenticatedFetch from '@/helpers/authenticatedFetch';

const apiFetch = (
  method: string,
  url: string,
  body: string,
  token: string,
): Promise<Response | void> => authenticatedFetch(
  url,
  {
    method,
    headers: {
      'content-type': 'application/json',
      api_key: apiKey,
      ...token && { account_token: token },
    },
    ...body && { body: JSON.stringify(body) },
  },
);

export default apiFetch;
