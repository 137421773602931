const updatePulseEnabled = () => {
  if (window.utag) {
    const consentStatus = window.Didomi.getUserConsentStatusForVendor('c:pulse-zaXxcmYW');
    if (consentStatus === true && window.utag_data.spt_is_pulse_enabled === 'false') {
      window.utag_data.spt_is_pulse_enabled = 'true';
    }
  }
};

const setDidomiConsentString = () => {
  if (window.didomiState) {
    window.utag_data.didomi_vendors_consent = window.didomiState.didomiVendorsConsent;
  }
};

export {
  updatePulseEnabled,
  setDidomiConsentString,
};
