import * as Sentry from '@sentry/nextjs';

import logger from '../server/log/logger';
import { isServerRun, isTestRun } from '@/helpers/utils';

// Switch to disable fetching metrics at build time
const NEXT_BUILD = parseInt(process.env.NEXT_BUILD || '0', 2) || 0; // Default to '0' if undefined
const APP_NAME = 'dominion';

/**
 * Wrapper around the `fetch` API to automatically measure the fetching time
 * @param {RequestInfo} url
 * @param {RequestInit} [options]
 * @param {nonDynamicPathname} string | undefined
 * @returns {Promise<Response | undefined>}
 */
const measurableFetch = async (
  url: RequestInfo | URL,
  options?: RequestInit,
  nonDynamicPathname: string | undefined = undefined,
): Promise<Response | undefined> => {
  try {
    const startTime = new Date();
    let fetchUrlRequest: URL;
    let statusCode: number | undefined;

    if (typeof url === 'string') {
      fetchUrlRequest = new URL(url);
    } else if (url instanceof URL) {
      fetchUrlRequest = url;
    } else if ((url as Request).url) { // Check if it's an instance of Request
      fetchUrlRequest = new URL((url as Request).url);
    } else {
      throw new Error(`measurableFetch: parameter url is not a valid url string or object, ${url}`);
    }

    return await fetch(url, options)
      .then((response: Response) => {
        statusCode = response.status;
        return response;
      })
      .catch((e: unknown) => {
        Sentry.captureException(e);
        if (isServerRun()) {
          logger.child({
            name: 'Measurable fetch',
            context: {
              url,
              options,
            },
          }).error(e);
        }
        return undefined; // Ensure we return `undefined` in case of an error
      })
      .finally(() => {
        if (isServerRun() && !isTestRun() && NEXT_BUILD === 0) {
          // @ts-expect-error globalThis
          globalThis.serverFetchDurationMilliseconds
            .labels(options?.method || 'GET', fetchUrlRequest.hostname, nonDynamicPathname || fetchUrlRequest.pathname, APP_NAME, statusCode)
            .observe(Date.now() - startTime.getTime());
        }
      });
  } catch (e: unknown) {
    Sentry.captureException(e);
    // Throw error with more context
    throw new Error(`measurableFetch: error fetching url ${url}: ${(e as Error).message}`);
  }
};

export default measurableFetch;
