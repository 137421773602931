import Script from 'next/script';

import { tealiumUrl } from '@/config';

const Tealium = () => (
  <Script
    id="tealium"
    strategy="afterInteractive"
    src={tealiumUrl}
  />
);

export default Tealium;
