import {
  Grid, Link, List, ListItem,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { UsefulLinksProps } from './types';
import useWindowSize from '@/hooks/useWindowSize';

const UsefulLinks = ({ links, children }: UsefulLinksProps): JSX.Element => {
  const theme = useTheme();
  const size = useWindowSize();
  const windowSizeDependencies = size.width < theme.breakpoints.values.sm ? {
    linkVariant: 'subtitle1',
    paddingSize: 2,
  } : {
    linkVariant: 'subtitle2',
    paddingSize: 1,
  };

  return (
    <Grid item xs={1}>
      <List disablePadding>
        {Object.entries(links).map(([key, {
          // @ts-expect-error  todo
          label, url, id, onClick,
        }]) => (
          <ListItem
            disablePadding
            key={`InternalPage-${key}`}
            sx={{
              pr: 2,
              justifyContent: { xs: 'center', sm: 'start' },
            }}
          >
            <Link
              id={id}
              href={url}
              onClick={onClick}
              target="_blank"
              color="inherit"
              // @ts-expect-error todo
              variant={windowSizeDependencies.linkVariant}
              pb={windowSizeDependencies.paddingSize}
            >
              {label}
            </Link>
          </ListItem>
        ))}
        {children}
      </List>
    </Grid>
  );
};

export default UsefulLinks;
