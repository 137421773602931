import { destroyCookie } from 'nookies';

import measurableFetch from './measurableFetch';
import { cookieDomain } from '@/config';

const logout = (): void => {
  const cookieOptions = { domain: cookieDomain, path: '/' };
  destroyCookie(null, 'session_id', cookieOptions);
  destroyCookie(null, 'session_http', cookieOptions);
  localStorage.removeItem('token');
};

const authenticatedFetch = (url: string, options?: RequestInit):
Promise<Response | void> => measurableFetch(url, options || {})
  .then((response: Response | void) => {
    if (response && response.status === 401) {
      logout();
      window.location.assign(window.location.href);
    }
    return response;
  });

export default authenticatedFetch;
