import { useEffect, useState } from 'react';

import NextHead from 'next/head';
import { useRouter } from 'next/router';

import {
  gemiusEnabled,
  googleAnalyticsEnabled,
  xitiEnabled,
} from '@/config';
import tealiumPageData from '@/config/tealium';
import gtagHelper from '@/helpers/gtag';

const UtagData = () => {
  const { pathname, asPath } = useRouter();
  const [origin, setOrigin] = useState(null);

  useEffect(() => {
    if (asPath) {
      const hash = asPath.split('#')[1];
      if (hash) {
        const parsedHash = new URLSearchParams(hash);
        setOrigin({
          postion: parsedHash.get('pos'),
          pageTypeOrigin: parsedHash.get('opt'),
        });
      }
    }
  }, [asPath]);

  const currentPageData = tealiumPageData[pathname];
  gtagHelper.SetPageTypeEvent(pathname);
  // Component merges the content of utag_data if is already defined
  return (
    <NextHead>
      <script
        id="utag_data_script"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `window.utag_data = {
            ...window.utag_data,
            event_name: "${currentPageData?.event_name}",
            acmh_event_type: "${currentPageData?.acmh_event_type}",
            acmh_page_type_origin: "${origin?.pageTypeOrigin || undefined}",
            acmh_position: "${origin?.postion || undefined}",
            acmh_page_type: ${currentPageData?.acmh_page_type},
            gemius_enabled: "${gemiusEnabled}",
            google_analytics_enabled: "${googleAnalyticsEnabled}",
            xiti_enabled: "${xitiEnabled}",
            xiti_p: "${currentPageData?.event_name}",
            level2_site: "${currentPageData?.level2_site}",
            page_type_id: "${currentPageData?.page_type_id}",
            spt_is_pulse_enabled: "false",
            didomi_vendors_consent: "",
          };
          window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
          window.utag_cfg_ovrd.noview = true;
          window.utag_next_app = "true";
          window.pp_gemius_use_cmp = true;
          window.pp_gemius_cmp_timeout = Infinity;
        `,
        }}
      />
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `document.addEventListener("contentGroupSet", () => {
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('event', 'page_type', { 'page_category_1': window.utag_data.page_category_1, 'page_category_2': window.utag_data.page_category_2 });
          });`,
        }}
      />
    </NextHead>
  );
};

export default UtagData;
