import { useEffect, useState } from 'react';

import Script from 'next/script';

import { useMarketingContext } from '@/contexts/Marketing/MarketingContext';

const ABTasty = () => {
  const { isDidomiReady } = useMarketingContext();
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (isDidomiReady && window.Didomi.getUserStatusForVendor('c:ab-tasty')) {
      setRender(true);
    }
  }, [isDidomiReady]);

  if (!render) {
    return null;
  }

  return (
    <Script
      data-testid="abtasty-script"
      strategy="afterInteractive"
      src="//try.abtasty.com/f1d0190ba9d1158909c360a456217f53.js"
    />
  );
};

export default ABTasty;
