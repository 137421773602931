import { useMemo } from 'react';

import { UseResizeDetectorReturn, useResizeDetector } from 'react-resize-detector';

import DimensionsContext from '@/contexts/Dimensions/DimensionsContext';

interface Props {
  children: any
}

const DimensionsContextWrapper = ({
  children,
}: Props): JSX.Element => {
  const breadcrumb: UseResizeDetectorReturn<any> = useResizeDetector();

  const initDimensionsContext = useMemo(() => ({
    breadcrumb,
  }), [breadcrumb]);

  return (
    <DimensionsContext.Provider value={initDimensionsContext}>
      {children}
    </DimensionsContext.Provider>
  );
};

export default DimensionsContextWrapper;
