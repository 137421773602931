import zonesData from '@/config/adverticum';

/**
 * @typedef {Object} AdverticumZone
 * @property {string} id - The id of the adverticum zone, without hashes or prefixes.
 * @property {HTMLElement} [node] - The DOM node of the adverticum zone.
 * */

/**
 * Helper function that injects the adverticum script into the page and load the provided zones.
 * */
function loadAdverticum({ zones }) {
  return new Promise((resolve) => {
    window.goAdverticum3.onReady(() => {
      window.goAdverticum3.invocation(zones, (loadedZones) => {
        resolve(loadedZones);
      });
    });
  });
}

/**
 * Removes the banners and leave the html elements empty.
 */
function unloadAdverticum({ zones }) {
  zones.forEach(({ node }) => {
    if (node) {
      node.removeAttribute('class');
      while (node.firstChild) {
        node.firstChild.remove();
      }
    }
  });
  return zones;
}

/**
 * Search for the zoneID based on the params
 */
const getZoneID = ({
  pageType,
  zoneName,
  displayOn = 'all',
  category,
  env = process.env.NODE_ENV,
}) => {
  if (pageType === 'vi') {
    return zonesData?.[pageType]?.[zoneName]?.[displayOn]?.[category] || 0;
  }
  return zonesData?.[pageType]?.[zoneName]?.[displayOn]?.[env] || 0;
};

const loadFallback = ({ pageType, zoneName, displayOn }) => {
  const fallbacks = zonesData?.[pageType]?.[zoneName]?.fallbacks?.[displayOn];
  return fallbacks ? fallbacks[Math.floor(Math.random() * fallbacks.length)] : null;
};

export {
  loadAdverticum,
  unloadAdverticum,
  getZoneID,
  loadFallback,
};
