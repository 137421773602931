import { messagingV2UnreadMessageCountUrl } from '@/config';
import measurableFetch from '@/helpers/measurableFetch';

const getUnreadMessagesCount = async ({ token }) => {
  const res = await measurableFetch(
    messagingV2UnreadMessageCountUrl,
    {
      method: 'GET',
      headers: {
        account_token: token,
      },
    },
  );

  if (res.status !== 200) {
    throw Error(`${res.url} Request rejected with status ${res.status}`);
  }
  return res.json();
};

export default getUnreadMessagesCount;
