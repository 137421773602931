import JfgAdverticumZone from '../../marketing/AdverticumZone';
import { useUserContext } from '@/contexts/User/UserContext';

const GateBanner = ({
  pathname, env, appl, category,
}) => {
  const { userState } = useUserContext();
  const { account } = userState;

  const isB2B = !!account?.account_level;

  if (pathname === '/') {
    return (
      <JfgAdverticumZone style={{ textAlign: 'center' }} pageType="indexPage" displayOn="desktop" zoneName="r_above" env={env} hideWhenNotLoaded />
    );
  }

  if (pathname === '/fiok/kedvencek') {
    return (
      <>
        {isB2B && (
          <JfgAdverticumZone style={{ textAlign: 'center' }} pageType="favoriteAds" zoneName="m_r_admin" displayOn="mobile" env={env} />
        )}
        <JfgAdverticumZone style={{ textAlign: 'center' }} pageType="favoriteAds" zoneName="r_above" displayOn="desktop" env={env} />
      </>
    );
  }

  if (pathname.includes('/fiok/postalada')) {
    return (
      <>
        {isB2B && (
          <JfgAdverticumZone style={{ textAlign: 'center' }} pageType="mailBox" zoneName="m_r_admin" displayOn="mobile" env={env} />
        )}
        <JfgAdverticumZone style={{ textAlign: 'center' }} pageType="mailBox" zoneName="r_above" displayOn="desktop" env={env} />
      </>
    );
  }

  if (pathname.includes('/fiok')) {
    return (
      <>
        <JfgAdverticumZone style={{ textAlign: 'center' }} pageType="myPages" zoneName="r_above" displayOn="desktop" env={env} />
        <JfgAdverticumZone style={{ textAlign: 'center' }} pageType="myPages" zoneName="r_above" displayOn="mobile" env={env} />
      </>
    );
  }

  if (appl && category) {
    return (
      <JfgAdverticumZone style={{ textAlign: 'center' }} pageType={appl} category={category} zoneName="r_above" displayOn="desktop" env={env} />
    );
  }

  return null;
};

export default GateBanner;
