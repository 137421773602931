import {
  Stack, Container, Box, Typography, Link, Paper,
  useTheme,
} from '@mui/material';

import appSuggesterData from './appSuggesterData';
import Headline from '@/components/adView/common/Headline';

const AppSuggester = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Container sx={{ display: { xs: 'none', lg: 'block' }, bgcolor: theme.palette.background.default }}>
      <Headline>Jófogás mobil applikáció</Headline>
      <Paper>
        <Stack
          id="ob-apps"
          padding="8px 16px"
          direction="row"
          borderRadius={1}
          sx={{
            backgroundColor: theme.palette.primary.lighter,
          }}
          height="148px"
          gap={2}
        >
          <Box height="100%" width="320px" position="relative">
            <img
              style={{
                position: 'absolute',
                height: '110%',
                bottom: '-8px',
              }}
              src="/assets/footer/phone_mockup.png"
              alt="Töltsd le Jófogás alkalmazásunkat!"
              loading="lazy"
            />
          </Box>
          <Stack justifyContent="center" alignItems="center">
            <Typography variant="h5" textAlign="center" mb={1}>
              Töltsd le alkalmazásunkat!
            </Typography>
            <Typography variant="body2" textAlign="center" mb={2}>
              Keress és válogass közel másfélmillió hirdetés közül bárhol is vagy!
            </Typography>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              {appSuggesterData.map((app) => (
                <Box key={`app-suggestion-${app.id}`}>
                  <Link href={app.url} sx={{ width: '150px', height: '40px', margin: 'auto' }} target="_blank" rel="noreferrer">
                    <img
                      src={app.src}
                      alt={app.alt}
                      width={134}
                      height={35}
                      loading="lazy"
                    />
                  </Link>
                </Box>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Paper>

    </Container>
  );
};

export default AppSuggester;
