import { urlPathElementsSlugify } from '../urls';
import { getParameter } from '../utils';
import { baseUrl, defaultAdPic, imageServer } from '@/config';
import getDeliveryInfoFromApi from '@/data/server/delivery';

const notDeliverableCategories = [1000, 6000, 9000];

export const getCity = (product) => product?.parameters?.find((param) => param.key === 'city').values?.[0];

export const getMainCategory = (product) => product?.category_tree?.[0] || null;

export const getVertical = (product) => {
  const mainCategoryId = getMainCategory(product);

  const verticals = {
    1000: 'realestate',
    2000: 'car',
    3000: 'generalist',
    4000: 'generalist',
    5000: 'generalist',
    6000: 'job',
    8000: 'generalist',
    8060: 'generalist',
    9000: 'generalist',
  };

  const vertical = verticals[mainCategoryId?.id];

  if (vertical === undefined) {
    return 'generalist';
  }

  return vertical;
};

const getSubdomain = (mainCategoryId) => {
  const subdomains = {
    1000: 'Jófogás Ingatlan',
    2000: 'Jófogás Autó',
    6000: 'Jófogás Állás',
  };

  return subdomains[mainCategoryId] || 'Jófogás';
};

export const getPageMainTitle = (product) => {
  const city = getCity(product);
  const mainCategory = getMainCategory(product);
  const subdomain = getSubdomain(mainCategory?.id);

  return `${product?.subject} - ${city?.label}, ${product?.category?.name} - ${subdomain}`;
};

export const getPrimaryImageUrl = (product) => {
  const name = product.images?.[0]?.name || null;
  return name ? `https://${imageServer}/767x575crop/${name}` : defaultAdPic;
};

const isInDeliverableCategory = (product) => {
  const mainCategory = getMainCategory(product);
  return mainCategory !== null && !notDeliverableCategories.includes(mainCategory.id);
};

export const getDeliveryInfo = (product) => {
  if (!isInDeliverableCategory(product)) {
    return null;
  }

  return getDeliveryInfoFromApi(product.list_id);
};

export const getProductImageThumb = (images) => (images === undefined ? '/assets/img/placeholder.png' : images[0].url);

export const getListingPageUrl = (product) => {
  const region = urlPathElementsSlugify(product?.region.label);
  const city = urlPathElementsSlugify(getParameter(product, 'city'));
  const category = urlPathElementsSlugify(product.category.name);

  return `${baseUrl}/${region}/${city}/${category}`;
};

export const getBadge = (product, badgeType) => (
  product?.badges?.find((badge) => badge.type === badgeType) || null
);
