import { useState, useEffect } from 'react';

// eslint-disable-next-line import/extensions
import theme from '../styles/theme';

/**
 * small function that returns the viewport type based on the window width
 * @param {Object} size size of the viewport at any given moment
 * @param {Number} size.width width of the viewport
 * @returns {String} viewport type (mobile, desktop, null)
 */
export const checkViewportType = ({ width }) => {
  switch (true) {
    case width <= theme.breakpoints.values.lg:
      return 'mobile';
    case width > theme.breakpoints.values.lg:
      return 'desktop';
    default:
      return null;
  }
};

/**
 * hook that returns the viewport type given a width and height
 * @param {{width: Number, height: Number}} size
 * @returns {string}
 */
const useViewportType = (size) => {
  const [viewportType, setViewportType] = useState(null);
  useEffect(() => {
    setViewportType(checkViewportType(size));
    return () => {
      setViewportType(null);
    };
  }, [size]);
  return viewportType;
};

export default useViewportType;
