import { APARTMENTS, TRENDING_SEARCHES, HOUSES } from './data';
import SeoLinks from './seo-links';

const SEO_GROUPS = [
  {
    title: 'Eladó és kiadó lakások',
    pages: APARTMENTS,
  },
  {
    title: 'Népszerű keresések',
    pages: TRENDING_SEARCHES,
  },
  {
    title: 'Eladó házak országszerte',
    pages: HOUSES,
  },
];

const SeoRealEstate = () => <SeoLinks groups={SEO_GROUPS} />;

export default SeoRealEstate;
