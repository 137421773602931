import { useRouter } from 'next/router';

// INFO: Flag function for features
const useFeatureFlag = (flagName: string): boolean => {
  const router = useRouter();
  const { query, asPath } = router;

  return Object?.keys(query || {})?.includes(flagName) || asPath?.includes(flagName) || false;
};

export default useFeatureFlag;
