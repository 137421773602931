import { parseCookies, setCookie, destroyCookie } from 'nookies';

import { cookieDomain, loginRedirectUrl } from '@/config';
import {
  loginToServer,
  logoutFromServer,
  getAccountInfo,
  registerToServer,
  socialLoginToServer,
  socialRegisterToServer,
  sendLostPasswordToServer,
  acceptTermsToServer,
  updateLegalTypeToServer,
} from '@/data/client/account';
import {
  loginButtonClicked, loginSuccess, loginError,
  registrationButtonClicked, registrationSuccess, registrationError,
  resetPasswordError,
} from '@/helpers/tracking/events';
import { assignLocationTo, replaceLocationWith } from '@/helpers/utils';

const TOKEN_KEY = 'token';
const SITE_VERSION = 'web';

/**
 * Read the token from cookies.
 * If the token is not found, make sure to delete the value from local storage.
 * @returns {string} returns the token
 */
const readToken = () => {
  const sessionToken = parseCookies(null).session_id;
  const sessionHttpToken = parseCookies(null).session_http;
  const localStorageToken = localStorage.getItem(TOKEN_KEY);
  if (sessionToken) {
    return sessionToken;
  }
  if (sessionHttpToken) {
    return sessionHttpToken;
  }
  if (localStorageToken) {
    return localStorageToken;
  }
  return null;
};

/**
 * Save the token to local storage and cookies.
 * @param {string} token an api or cookie token
 * @returns {void}
 */
const storeToken = (token) => {
  const cookieOptions = { domain: cookieDomain, path: '/' };
  if (token) {
    setCookie(null, 'session_id', token, cookieOptions);
    setCookie(null, 'session_http', token, cookieOptions);
    localStorage.setItem(TOKEN_KEY, token);
    return;
  }
  destroyCookie(null, 'session_id', cookieOptions);
  destroyCookie(null, 'session_http', cookieOptions);
  localStorage.removeItem(TOKEN_KEY);
};

const transformErrors = (errors) => {
  if (errors) {
    return errors.reduce((accumulator, value) => {
      if (value.field === 'error') {
        accumulator.form = value.message;
      } else {
        accumulator[value.field] = value.message;
      }
      return accumulator;
    }, {});
  }

  return errors;
};

const onLoginSuccess = (router, method = 'assign') => {
  const validMethods = ['assign', 'replace'];

  if (!validMethods.includes(method)) {
    throw new Error('Invalid window.location method!');
  }

  const redirectLocation = router?.query?.backurl || router?.query?.backUrl || loginRedirectUrl;

  if (method === 'assign') {
    assignLocationTo(redirectLocation);
  } else if (method === 'replace') {
    replaceLocationWith(redirectLocation);
  }
};

const initialize = async () => {
  const token = readToken() || '';
  storeToken(token);

  if (token) {
    const account = await getAccountInfo({ token });
    if (account?.success === true) {
      return { account, token };
    }
  }

  return {
    account: null,
    token: null,
  };
};

const setAccountToken = async (token, socialSiteCapitalized = null) => {
  const account = await getAccountInfo({ token });
  loginSuccess(account, socialSiteCapitalized);

  storeToken(token);

  return {
    account,
    token,
  };
};

const login = async (formData) => {
  const { email, password } = formData;

  loginButtonClicked();

  const resJson = await loginToServer({
    body: {
      email,
      password,
      site_version: 'web',
    },
  });
  const errors = transformErrors(resJson.errors);

  if (errors) {
    loginError(resJson.message);

    return { ...resJson, errors };
  }

  return setAccountToken(resJson.account_token);
};

const logout = async (token) => {
  await logoutFromServer({ token });
  storeToken(null);

  return {
    token: null,
    account: null,
  };
};

const register = async (formData) => {
  const { email, password, newsletter_subscribe } = formData;

  registrationButtonClicked();

  const resJson = await registerToServer({ body: { email, password, newsletter_subscribe } });
  const errors = transformErrors(resJson.errors);

  if (errors) {
    registrationError(resJson.message);
    return { ...resJson, errors };
  }

  registrationSuccess();
  return resJson;
};

const socialLogin = async (formData) => {
  const { social_access_token, social_site } = formData;

  const socialSiteCapitalized = social_site
    ? social_site[0].toUpperCase() + social_site.slice(1) : '';

  const resJson = await socialLoginToServer({
    body: { social_access_token, social_site, site_version: SITE_VERSION },
  });

  if (resJson.success === true && !resJson.errors) {
    return setAccountToken(resJson.account_token, socialSiteCapitalized);
  }

  return resJson;
};

const socialRegister = async (formData) => {
  const {
    newsletter_subscribe, social_access_token, social_site, password,
  } = formData;

  const bodyToSend = {
    newsletter_subscribe,
    social_access_token,
    social_site,
    site_version: SITE_VERSION,
  };

  if (password) {
    bodyToSend.password = password;
  }

  const socialSiteCapitalized = social_site
    ? social_site[0].toUpperCase() + social_site.slice(1) : '';

  const resJson = await socialRegisterToServer({
    body: bodyToSend,
  });

  if (resJson.success === true && !resJson.errors) {
    return setAccountToken(resJson.account_token, socialSiteCapitalized);
  }

  return resJson;
};

const sendLostPassword = async (formData) => {
  const { email } = formData;

  const resJson = await sendLostPasswordToServer({ body: { email } });
  const errors = transformErrors(resJson.errors);

  if (errors) {
    if (resJson.errors[0] && resJson.errors[0].code && resJson.errors[0].message) {
      const { code, message: description } = resJson.errors[0];
      resetPasswordError({ code, description });
    } else {
      resetPasswordError({ code: '', description: '' });
    }

    return { ...resJson, errors };
  }
  return resJson;
};

const updateLegalType = async (formData, token) => {
  let resJson;

  if (formData.legal_type === 'private') {
    resJson = await updateLegalTypeToServer({ body: { legal_type: 'private' }, token });
  } else {
    resJson = await updateLegalTypeToServer({ body: formData, token });
  }

  const errors = transformErrors(resJson.errors);

  if (errors) {
    return { ...resJson, errors };
  }

  return resJson;
};

const acceptTerms = async (accountId, token) => {
  const resJson = await acceptTermsToServer({ body: { accountId }, token });
  const errors = transformErrors(resJson.errors);

  if (errors) {
    return { ...resJson, errors };
  }
  return resJson;
};

export {
  transformErrors,
  onLoginSuccess,
  initialize,
  setAccountToken,
  login,
  logout,
  register,
  socialLogin,
  socialRegister,
  sendLostPassword,
  updateLegalType,
  acceptTerms,
};
