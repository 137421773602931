/**
 * Delay a function call by a given amount of time.
 * @param {Function} fn The function to debounce.
 * @param {Number} delay The amount of time to delay the function call.
 */
const debounce = (func, time = 100) => {
  let timer;
  return (event) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(func, time, event);
  };
};

export default debounce;
