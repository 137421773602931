import { createContext, useContext } from 'react';

const initialState = {
  countdown: null,
  setCountdown: () => {},
};

const LayoutContext = createContext(initialState);

export const useLayoutContext = () => useContext(LayoutContext);

export default LayoutContext;
