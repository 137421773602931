import MenuIcon from '@mui/icons-material/Menu';
import { Badge, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

const MenuButton = ({ openSideMenu, unreadMessages }) => (
  <IconButton
    color="primary"
    size="large"
    aria-label="menu"
    sx={{ mr: 2, display: { sm: 'inline-flex', lg: 'none' } }}
    onClick={openSideMenu}
  >
    {unreadMessages ? (
      <Badge color="primary" variant="dot" aria-label={`${unreadMessages} olvasatlan üzenet`}>
        <MenuIcon />
      </Badge>
    ) : (
      <MenuIcon />
    )}
  </IconButton>
);

MenuButton.protoTypes = {
  openSideMenu: PropTypes.func.isRequired,
  unreadMessages: PropTypes.number.isRequired,
};

export default MenuButton;
