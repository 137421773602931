import { getCategoryTree } from './categories';
import { baseUrl } from '@/config';

const subsites = {
  1000: 'ingatlan',
  2000: 'auto',
  6000: 'allas',
  default: 'www',
};

export const getDomain = ({ mainCategory, env = process.env.NODE_ENV }) => {
  const envUrl = env === 'production' ? '' : 'test-';
  return `https://${subsites?.[+mainCategory] || subsites.default}.${envUrl}jofogas.hu`;
};

export const getFriendly = ({
  query,
  urlFragments,
  paramPriority,
  categoryObject,
  env = process.env.NODE_ENV,
}) => {
  const friendlyURL = [];
  const [mainCat] = getCategoryTree({ cg: query.cg, categories: categoryObject });
  friendlyURL.push(getDomain({ mainCategory: mainCat?.id, env }));
  const fragments = {};
  const searchParams = new URLSearchParams();

  const queryObj = Object.assign(query, {
    w: query.w ? +query.w + 100 : 3,
  });

  Object.entries(queryObj).forEach(([key, value]) => {
    if (key !== 'q' && urlFragments?.[key]) {
      [...`${value}`.split(',')].forEach((singleValue) => {
        fragments[key] = fragments[key] ? `${fragments[key]}+` : '';
        fragments[key] += urlFragments[key][singleValue];
      });
    } else {
      searchParams.append(key, value);
    }
  });

  paramPriority.forEach((param) => {
    if (fragments?.[param]) {
      friendlyURL.push(fragments?.[param]);
    }
  });

  const res = `${friendlyURL.join('/')}${searchParams.toString() ? '?' : ''}${searchParams.toString()}`;
  return res;
};

export const getLoginUrl = (backUrl) => {
  const url = new URL(`${baseUrl}/belepes`);
  const params = new URLSearchParams();

  if (backUrl) {
    params.set('backurl', backUrl);
  }

  url.search = params.toString();

  return url.href;
};

export const redirectToLogin = () => {
  window.location.assign(getLoginUrl(window.location.href));
};

export const urlPathElementsSlugify = (string, separator = '-') => string
  .toString()
  .normalize('NFD')
  .toLowerCase()
  .trim()
  .replace(/\s+/g, separator)
  .replace(/[^\w-]+/g, '')
  .replace(/_/g, separator)
  .replace(/--+/g, separator)
  .replace(/-$/g, '');

export function replaceListId(url) {
  return url.pathname.split('/').slice(0, -1).concat('{listId}').join('/');
}
