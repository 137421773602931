/* eslint-disable max-lines */

const mapping = {
  1000: 556668,
  9021: 635926,
  9022: 635928,
  9023: 635930,
  9024: 635932,
  9025: 635934,
  9026: 635936,
  9027: 635938,
  9028: 635940,
  9029: 635942,
  9030: 635944,
  9031: 635946,
  9032: 635948,
  9033: 635950,
  9035: 635954,
  9041: 635956,
  9042: 635958,
  9043: 635960,
  9044: 635962,
  9046: 635966,
  9047: 635968,
  9061: 635970,
  9062: 635972,
  9063: 635974,
  9064: 635976,
  6021: 635678,
  6022: 635680,
  6023: 635684,
  6024: 635686,
  6025: 635688,
  6026: 635690,
  6027: 635692,
  6028: 635694,
  6029: 635696,
  6030: 635698,
  6031: 635700,
  6032: 635702,
  6033: 635704,
  6034: 635706,
  6035: 635708,
  6036: 635710,
  6037: 635712,
  6038: 635714,
  6039: 635716,
  6041: 635718,
  8069: 635894,
  8070: 635896,
  8071: 635898,
  8072: 635900,
  8073: 635902,
  8074: 635904,
  8075: 635906,
  8076: 635908,
  8077: 635910,
  8078: 635912,
  8079: 635914,
  8080: 635916,
  8081: 635918,
  8082: 635920,
  8083: 635922,
  8084: 635924,
  8101: 635760,
  8102: 635762,
  8103: 635764,
  8104: 635766,
  8105: 635768,
  8106: 635770,
  8107: 635772,
  8108: 635774,
  8109: 635776,
  8110: 635778,
  8111: 635780,
  8112: 635782,
  8113: 635784,
  8114: 635786,
  8115: 635788,
  8116: 635790,
  8117: 635792,
  8118: 635794,
  8201: 635796,
  8202: 635798,
  8203: 635800,
  8204: 635802,
  8205: 635804,
  8206: 635806,
  8207: 635808,
  8208: 635812,
  8209: 635820,
  8031: 635882,
  8032: 635884,
  8033: 635886,
  8034: 635888,
  8035: 635890,
  8044: 635892,
  9065: 635978,
  8210: 635824,
  8211: 635826,
  8212: 635828,
  8213: 635830,
  8214: 635832,
  8215: 635834,
  8217: 635838,
  8216: 635836,
  8301: 635840,
  8302: 635842,
  8303: 635844,
  8304: 635846,
  8305: 635848,
  8306: 635850,
  8307: 635852,
  8308: 635854,
  8309: 635856,
  8310: 635858,
  8311: 635860,
  8312: 635862,
  8313: 635864,
  8314: 635866,
  8315: 635868,
  8316: 635870,
  8317: 635872,
  8401: 635874,
  8402: 635876,
  8403: 635878,
  8404: 635880,
  4316: 634766,
  4317: 634768,
  4314: 634770,
  4061: 634772,
  4062: 634774,
  4301: 634776,
  4302: 634778,
  4303: 634780,
  4304: 634782,
  4321: 634784,
  4322: 634786,
  4323: 634788,
  4324: 634794,
  4325: 634796,
  4326: 634798,
  4063: 634800,
  4305: 634802,
  4461: 634804,
  4462: 634806,
  4466: 634808,
  4467: 634810,
  4464: 634812,
  4463: 634814,
  4465: 634816,
  4386: 634818,
  4383: 634820,
  4382: 634822,
  4384: 634824,
  4381: 634826,
  4387: 634828,
  4385: 634830,
  4403: 634832,
  4392: 634834,
  4395: 634836,
  4396: 634838,
  4397: 634840,
  4398: 634842,
  4399: 634844,
  4400: 634846,
  4401: 634848,
  4402: 634850,
  4394: 634852,
  4081: 634854,
  4082: 634856,
  4084: 634858,
  4083: 634870,
  4107: 634872,
  4108: 634874,
  4104: 634876,
  4109: 634878,
  4106: 634880,
  4102: 634882,
  4110: 634884,
  4111: 634886,
  4101: 634888,
  4105: 634890,
  4148: 634892,
  4143: 634894,
  4149: 634896,
  4150: 634898,
  4146: 634900,
  4151: 634902,
  4144: 634904,
  4152: 634906,
  4141: 634908,
  4147: 634910,
  4145: 634912,
  4044: 634914,
  4042: 634916,
  4041: 634918,
  4046: 634920,
  4049: 634922,
  4045: 634924,
  4043: 634926,
  4050: 634928,
  4047: 634930,
  4048: 634932,
  4344: 634934,
  4345: 634936,
  4341: 634938,
  4346: 634940,
  4347: 634942,
  4342: 634944,
  4348: 634946,
  4343: 634948,
  4021: 634950,
  4024: 634952,
  4023: 634954,
  4362: 634956,
  4364: 634958,
  4361: 634960,
  4363: 634962,
  5002: 634966,
  5003: 634968,
  5004: 634970,
  5005: 634972,
  5006: 634974,
  5007: 634976,
  5008: 634978,
  5009: 634980,
  5060: 634982,
  5070: 634984,
  5360: 634986,
  5370: 634988,
  5022: 634992,
  5024: 634994,
  5042: 634996,
  5043: 634998,
  5044: 635000,
  5045: 635002,
  5046: 635004,
  5054: 635006,
  5055: 635008,
  5048: 635010,
  5049: 635012,
  5050: 635014,
  5051: 635016,
  5052: 635018,
  5053: 635020,
  5081: 635022,
  5082: 635024,
  5083: 635026,
  5084: 635028,
  5085: 635030,
  5086: 635032,
  5321: 635034,
  5322: 635036,
  5323: 635038,
  5324: 635040,
  5325: 635042,
  3002: 634408,
  3003: 634410,
  3004: 634412,
  3005: 634414,
  3006: 634416,
  3007: 634418,
  3008: 634420,
  3021: 634422,
  3022: 634424,
  3023: 634426,
  3024: 634428,
  3025: 634430,
  3041: 634432,
  3042: 634434,
  3043: 634436,
  3044: 634438,
  3047: 634442,
  3071: 634444,
  3072: 634446,
  3073: 634450,
  3074: 634452,
  3076: 634456,
  3051: 634458,
  3052: 634460,
  3053: 634464,
  3054: 634466,
  3055: 634468,
  3057: 634474,
  3062: 634476,
  3063: 634478,
  3064: 634480,
  3065: 634482,
  3066: 634484,
  3067: 634486,
  3081: 634488,
  3083: 634490,
  3084: 634492,
  3085: 634494,
  3101: 634496,
  3102: 634498,
  3103: 634500,
  3105: 634502,
  3106: 634504,
  3107: 634506,
  3108: 634508,
  3109: 634510,
  3362: 634512,
  3363: 634514,
  3364: 634516,
  3366: 634518,
  3367: 634520,
  3368: 634522,
  3424: 634524,
  3425: 634526,
  3426: 634528,
  3427: 634530,
  3428: 634532,
  3463: 634534,
  3464: 634536,
  3465: 634538,
  3466: 634540,
  3467: 634542,
  3468: 634544,
  3469: 634546,
  3470: 634548,
  3474: 634550,
  3475: 634552,
  3476: 634554,
  3478: 634556,
  2084: 634250,
  2085: 634252,
  2086: 634254,
  2087: 634256,
  2088: 634258,
  2089: 634260,
  2090: 634262,
  2091: 634264,
  2101: 634266,
  2102: 634268,
  2103: 634270,
  2104: 634272,
  2105: 634274,
  2119: 634276,
  2121: 634278,
  2123: 634280,
  2124: 634282,
  2125: 634284,
  2126: 634286,
  2127: 634288,
  2128: 634290,
  2129: 634292,
  2130: 634294,
  2131: 634296,
  2132: 634298,
  2133: 634300,
  2134: 634302,
  2135: 634304,
  2136: 634306,
  2137: 634308,
  2138: 634310,
  2139: 634312,
  2160: 634314,
  2142: 634316,
  2143: 634318,
  2144: 634320,
  2145: 634322,
  2146: 634324,
  2147: 634326,
  2148: 634328,
  2149: 634330,
  2150: 634332,
  2151: 634334,
  2152: 634336,
  2153: 634340,
  2155: 634346,
  2156: 634348,
  2157: 634352,
  2158: 634354,
  2159: 634356,
  2181: 634358,
  2183: 634362,
  2184: 634364,
  2185: 634366,
  2186: 634368,
  2187: 634370,
  2188: 634372,
  2189: 634374,
  2190: 634380,
  2191: 634382,
  2411: 634386,
  2412: 634388,
  2413: 634390,
  2414: 634392,
  2415: 634394,
  1210: 633990,
  1220: 633992,
  1361: 633994,
  1362: 633996,
  1363: 633998,
  1364: 634000,
  1365: 634002,
  1366: 634004,
  1367: 634006,
  2083: 634008,
  1050: 633606,
  1060: 633608,
  1080: 633610,
  2020: 633612,
  2040: 633614,
  2060: 633616,
  2100: 633618,
  2120: 633620,
  2140: 633622,
  2180: 633624,
  2310: 633626,
  2410: 633628,
  2460: 633630,
  3001: 633632,
  3020: 633634,
  3060: 633638,
  3423: 633640,
  4300: 633642,
  4460: 633644,
  4390: 633646,
  4080: 633648,
  4040: 633650,
  4340: 633652,
  5010: 633654,
  5041: 633656,
  8300: 633658,
  8400: 633660,
  8030: 633662,
  8061: 633664,
  8062: 633666,
  8063: 633668,
  8064: 633670,
  8065: 633672,
  8066: 633674,
  8067: 633676,
  8068: 633678,
  9020: 633682,
  9040: 633684,
  9060: 633686,
  2081: 623342,
  3050: 623344,
  1020: 632266,
  1100: 632268,
  4020: 632270,
  4360: 632272,
  4380: 632274,
  1040: 632276,
  1340: 632278,
  3040: 632280,
  3461: 632282,
  3462: 632284,
  3360: 623346,
  1360: 623348,
  2000: 556670,
  2080: 568186,
  2122: 568184,
  2141: 568198,
  2154: 568196,
  2182: 568200,
  3000: 556666,
  3045: 568188,
  3046: 568202,
  3048: 568204,
  3361: 568214,
  3471: 568206,
  3472: 568208,
  3473: 568210,
  3477: 568212,
  4000: 556676,
  4100: 568190,
  4140: 568192,
  4318: 568220,
  4319: 568222,
  5000: 556674,
  5021: 568216,
  5047: 568218,
  6000: 556672,
  8000: 556678,
  8021: 568194,
  8060: 554928,
  9000: 556680,
  4310: 575800,
  5001: 575802,
  5320: 575804,
  5040: 575806,
  5080: 575808,
  1200: 594786,
  4365: 594788,
  3080: 594918,
  3100: 594920,
  8200: 594922,
  6020: 597180,
};

const undefinedCategoryTrackerId = 577092;

export { mapping, undefinedCategoryTrackerId };
