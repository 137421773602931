const mapping = {
  0: 574544,
  1: 574546,
  50001: 574548,
  100001: 574550,
  200001: 574552,
  400001: 574554,
  2000001: 574556,
  4000001: 574558,
  6000001: 574560,
  8000001: 574562,
  25000001: 574564,
  40000001: 574566,
  70000001: 574568,
};

const undefinedPriceTrackerId = 574620;

export {
  mapping, undefinedPriceTrackerId,
};
