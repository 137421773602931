import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

const initialState = {
  adverticumReady: false,
  isDidomiReady: false,
  didomiNoticeShown: false,
};

export const MarketingContext = createContext(initialState);

export const useMarketingContext = () => useContext(MarketingContext);

const MarketingContextWrapper = ({ children, initialValue = {} }) => {
  const [state, setState] = useState(initialValue);

  const setDidomiNoticeShown = useCallback((value) => {
    setState((prevState) => ({ ...prevState, didomiNoticeShown: value }));
  }, []);

  const setAdverticumReady = useCallback((value) => {
    setState((prevState) => ({ ...prevState, adverticumReady: value }));
  }, []);

  const setDidomiReady = useCallback((value) => {
    setState((prevState) => ({ ...prevState, isDidomiReady: value }));
  }, []);

  const value = useMemo(() => ({
    ...state,
    setAdverticumReady,
    setDidomiReady,
    setDidomiNoticeShown,
  }), [state, setAdverticumReady, setDidomiReady, setDidomiNoticeShown]);

  return (
    <MarketingContext.Provider value={value}>
      {children}
    </MarketingContext.Provider>
  );
};

export default MarketingContextWrapper;
