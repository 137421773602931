/* eslint-disable max-lines */
import * as braze from '@/helpers/braze';
import { createBrazeData } from '@/helpers/product/createBrazeData';
import tealium from '@/helpers/tealium';

/**
 * Login events
 */
export const loginFormViewed = (backUrl = null, listId = null) => {
  const tealiumObj = { event_name: 'login_form_viewed' };

  if (listId) {
    tealiumObj.acmh_list_id = listId;

    if (backUrl) {
      tealiumObj.back_url = backUrl;
    }
  }

  tealium.link(tealiumObj);
};

export const loginButtonClicked = () => {
  tealium.link({ event_name: 'login_button_clicked' });
};

export const loginSuccess = (account, provider = null) => {
  tealium.link({ event_name: 'login_success', acmh_origin_element_label: provider });
  braze.userChanged(account.account_id, account.marketing_push_subscribe);
  braze.customEvent('log_in_successful');
};

export const loginError = (message) => {
  tealium.link({ event_name: 'login_error', acmh_error_message: message });
};

export const resetPasswordFormViewed = () => {
  tealium.link({ event_name: 'reset_password_form_viewed' });
};

export const resetPasswordConfirmation = () => {
  tealium.link({ event_name: 'reset_password_confirmation' });
};

export const resetPasswordButtonClicked = () => {
  tealium.link({ event_name: 'reset_password_button_clicked' });
};

export const resetPasswordError = ({ code, description }) => {
  tealium.link({ event_name: 'reset_password_error', acmh_error_code: code, acmh_error_description: description });
};

/**
 * Social signup events
 */

export const fbLoginButtonClicked = () => {
  tealium.link({ event_name: 'fb_login_button_clicked' });
};

export const appleLoginButtonClicked = () => {
  tealium.link({ event_name: 'apple_login_button_clicked' });
};

export const googleLoginButtonClicked = () => {
  tealium.link({ event_name: 'google_login_button_clicked' });
};

export const fbRegistrationButtonClicked = () => {
  tealium.link({ event_name: 'fb_registration_button_clicked' });
  braze.customEvent('registration_submit');
};

export const appleRegistrationButtonClicked = () => {
  tealium.link({ event_name: 'apple_registration_button_clicked' });
  braze.customEvent('registration_submit');
};

export const googleRegistrationButtonClicked = () => {
  tealium.link({ event_name: 'google_registration_button_clicked' });
  braze.customEvent('registration_submit');
};

export const confirmationFormViewed = (provider) => {
  tealium.link({ event_name: 'confirmation_form_viewed', acmh_origin_element_label: provider });
};

export const confirmationFormViewedForConnection = (provider) => {
  tealium.link({ event_name: 'confirmation_form_viewed_for_connection', acmh_origin_element_label: provider });
};

export const confirmationButtonClicked = (provider) => {
  tealium.link({ event_name: 'confirmation_button_clicked', acmh_origin_element_label: provider });
};

export const cancelConfirmationButtonClicked = (provider) => {
  tealium.link({ event_name: 'cancel_confirmation_button_clicked', acmh_origin_element_label: provider });
};

export const socialConnectConfirmation = (provider) => {
  tealium.link({ event_name: 'social_connect_confirmation', acmh_origin_element_label: provider });
};

export const newsletterSubscriptionClicked = (provider) => {
  tealium.link({ event_name: 'activate_marketing_newsletter_subscription_clicked', acmh_origin_element_label: provider });
};

export const socialSignupError = ({ code, description }) => {
  tealium.link({ event_name: 'social_signup_error', acmh_error_code: code, acmh_error_description: description });
};

/**
 * Registration events
 */
export const registrationFormViewed = () => {
  tealium.link({ event_name: 'registration_form_viewed' });
  braze.customEvent('registration_start');
};

export const registrationButtonClicked = () => {
  tealium.link({ event_name: 'registration_button_clicked' });
  braze.customEvent('registration_submit');
};

export const registrationSuccess = (provider = null) => {
  tealium.link({ event_name: 'registration_success', acmh_origin_element_label: provider });
};

export const registrationError = (message) => {
  tealium.link({ event_name: 'registration_error', acmh_error_message: message });
};

export const listingUnsaved = () => {
  tealium.link({ event_name: 'listing_unsaved' });
};

/**
 * Favorite / Unfavorite ad
 */
export const favoriteAdSave = (item) => {
  const brazeAdDetails = createBrazeData(item);

  tealium.link({
    event_name: 'favourite_ad_save',
    acmh_location_city: item.region.label,
    acmh_location_region: item.region.label,
    acmh_location_postal_code: item.parameters.find((param) => param.key === 'zipcode').values[0].label,
    acmh_ad_type: item.type.value === 's' ? 'sell' : 'buy',
    acmh_category_path: item.category_tree.map((cat) => cat.name).join(' > '),
    acmh_publisher_type: item.company_ad ? 'pro' : 'private',
    acmh_list_id: item.list_id.toString(),
  });

  braze.customEvent('favorite_added', brazeAdDetails);
};

export const favoriteAdUnsave = (item) => {
  tealium.link({
    event_name: 'favourite_ad_unsave',
    acmh_location_city: item.region.label,
    acmh_location_region: item.region.label,
    acmh_location_postal_code: item.parameters.find((param) => param.key === 'zipcode').values[0].label,
    acmh_ad_type: item.type.value === 's' ? 'sell' : 'buy',
    acmh_category_path: item.category_tree.map((cat) => cat.name).join(' > '),
    acmh_publisher_type: item.company_ad ? 'pro' : 'private',
    acmh_list_id: item.list_id.toString(),
  });
};

/**
 * Contact Box events
 */
export const phoneReveal = (item) => {
  const brazeAdDetails = createBrazeData(item);

  braze.customEvent('phone_reveal', brazeAdDetails);

  tealium.linkGF(
    {
      event_name: 'lead_show_phone',
      acmh_list_id: item.list_id,
    },
    'viShowPhone',
  );
};

export const phoneCalled = (item) => {
  const brazeAdDetails = createBrazeData(item);

  braze.customEvent('phone_called', brazeAdDetails);

  tealium.linkGF(
    {
      event_name: 'lead_show_phone_call',
      acmh_list_id: item.list_id,
    },
    'viCallPhone',
  );
};

export const leadStartTyping = (listId) => {
  const tealiumObj = { event_name: 'lead_start_typing' };

  if (listId) {
    tealiumObj.acmh_list_id = listId;
  }

  tealium.link(tealiumObj);
};

export const leadFileAttached = (listId) => {
  const tealiumObj = { event_name: 'lead_file_attached' };

  if (listId) {
    tealiumObj.acmh_list_id = listId;
  }

  tealium.link(tealiumObj);
};

export const firstMessageSent = (item) => {
  const brazeAdDetails = createBrazeData(item);

  braze.customEvent('first_message_sent', brazeAdDetails);
  tealium.linkGF({ event_name: 'lead_send_ar' }, '');
};

export const leadAttemptSendMessage = (listId) => {
  tealium.linkGF({ event_name: 'lead_attempt_send_message', acmh_list_id: listId }, '');
};

export const leadShowEmail = () => {
  tealium.link({ event_name: 'lead_show_email' });
};

export const leadSendEmail = (listId) => {
  const tealiumObj = { event_name: 'lead_send_email' };

  if (listId) {
    tealiumObj.acmh_list_id = listId;
  }

  tealium.link(tealiumObj);
};

export const leadShowLocation = () => {
  tealium.link({ event_name: 'lead_show_location' });
};

/**
 * FTTV declaration events
 */
export const fttvDeclarationPrivate = () => {
  tealium.link({ event_name: 'fttv_declaration_private' });
};

export const fttvDeclarationCompany = () => {
  tealium.link({ event_name: 'fttv_declaration_company' });
};

export const fttvDeclarationWrongCompanyName = () => {
  tealium.link({ event_name: 'fttv_declaration_wrong_company_name' });
};

export const fttvDeclarationWrongTaxCode = () => {
  tealium.link({ event_name: 'fttv_declaration_wrong_tax_code' });
};

export const fttvDeclarationSubmitted = () => {
  tealium.link({ event_name: 'fttv_declaration_submitted' });
};

export const fttvDeclarationSuccess = () => {
  tealium.link({ event_name: 'fttv_declaration_success' });
};

/**
 * Social Share
 */

export const clickCopyLinkButton = (listId) => {
  tealium.link({
    event_name: 'click_copy_link_button',
    tealium_event: 'show',
    acmh_list_id: listId,
  });
};

/**
 * Map and Public transportation
 */

export const mapIsInViewportEvent = (listId) => {
  tealium.link({
    event_name: 'map_is_in_viewport_event',
    tealium_event: 'show',
    acmh_list_id: listId.toString(),
  });
};

/**
 * Gallery Events
 */

export const galleryToAdViewButton = (listId) => {
  tealium.link({
    event_name: 'click_ad_gallery_to_ad_details',
    acmh_list_id: listId.toString(),
    tealium_event: 'show',
  });
};

export const adGalleryOpen = (listId) => {
  tealium.link({
    event_name: 'ad_gallery_open',
    acmh_list_id: listId.toString(),
  });
};

export const adGalleryView = (listId) => {
  tealium.link({
    event_name: 'ad_gallery_view',
    acmh_list_id: listId.toString(),
    tealium_event: 'show',
  });
};

export const adGalleryClose = (listId) => {
  tealium.link({
    event_name: 'ad_gallery_close',
    acmh_list_id: listId.toString(),
    tealium_event: 'show',
  });
};

export const webWalkButton = (listId) => {
  tealium.link({
    event_name: 'virtual_walk_button',
    tealium_event: 'show',
    acmh_list_id: listId.toString(),
  });
};

/**
 * Adview events
 */

export const viewFullDescriptionButtonAppeared = (listId) => {
  tealium.link({
    event_name: 'view_full_description_button',
    tealium_event: 'show',
    acmh_list_id: listId.toString(),
  });
};

export const clickedOnReadMoreButton = (listId) => {
  tealium.link({
    event_name: 'click_full_description_button',
    tealium_event: 'show',
    acmh_list_id: listId.toString(),
  });
};

export const clickFloatingContactSellerButton = (listId) => {
  tealium.link({
    event_name: 'click_floating_contact_seller_button',
    tealium_event: 'show',
    acmh_list_id: listId.toString(),
  });
};

/**
 * Vertical events
 */

export const clickAiButton = () => {
  tealium.link({
    event_name: 'click_ai_button',
    tealium_event: 'show',
  });
};

/**
 * Delivery order events
 */

export const deliveryOrderSuccess = (listId) => {
  tealium.link(
    {
      event_name: 'delivery_order_success',
      acmh_list_id: listId,
    },
  );
};

/**
 * InfoBox event
 */
export const clickInfoBoxLink = (label) => {
  tealium.link({
    event_name: 'click_infobox_link',
    acmh_origin_element_label: label,
  });
};
