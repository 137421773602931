import { accountUrl, apiKey } from '@/config';
import authenticatedFetch from '@/helpers/authenticatedFetch';

const accountEndpointPostRequest = (endpoint) => async ({ body, token }) => {
  const url = [accountUrl, endpoint].filter(Boolean).join('/');
  const res = await authenticatedFetch(url, {
    method: 'POST',
    ...(body ? { body: JSON.stringify(body) } : {}),
    headers: {
      api_key: apiKey,
      'content-type': 'application/json',
      ...(token ? { account_token: token } : {}),
    },
  });

  return res.json();
};

const accountEndpointGetRequest = (endpoint) => async ({ token }) => {
  const url = [accountUrl, endpoint].filter(Boolean).join('/');
  const res = await authenticatedFetch(url, {
    method: 'GET',
    headers: {
      api_key: apiKey,
      account_token: token,
    },
  });

  return res.json();
};

export const getAccountInfo = accountEndpointPostRequest();

export const loginToServer = accountEndpointPostRequest('login');

export const registerToServer = accountEndpointPostRequest('registration');

export const socialLoginToServer = accountEndpointPostRequest('socialLogin');

export const socialRegisterToServer = accountEndpointPostRequest('socialRegistration');

export const logoutFromServer = accountEndpointPostRequest('logout');

export const sendLostPasswordToServer = accountEndpointPostRequest('lostPassword');

export const getAccountTermsAccepted = accountEndpointGetRequest('hasAcceptedTerms');

export const acceptTermsToServer = accountEndpointPostRequest('acceptTerms');

export const getLegalType = accountEndpointGetRequest('legalType');

export const getFavoritedAds = accountEndpointGetRequest('savedAdList');

export const favoriteAd = accountEndpointPostRequest('saveAd');

export const unfavoriteAd = accountEndpointPostRequest('deleteSavedAd');

export const postNewsletterSubscribe = accountEndpointPostRequest('newsletter');
export const getNewsletterSubscribe = accountEndpointGetRequest('newsletter');

export const updateLegalTypeToServer = async ({ body, token }) => {
  const res = await authenticatedFetch(`${accountUrl}/legalType`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      api_key: apiKey,
      'content-type': 'application/json',
      account_token: token,
    },
  });

  const respObject = await res.json();

  if (res.status >= 400 && !(res.status === 422 && respObject?.errors?.length)) {
    const errMessage = respObject.errors[0]?.message || 'Sajnáljuk, valami hiba történt. Kérjük, próbáld újra!';
    throw Error(errMessage);
  }

  return respObject;
};

export { accountEndpointPostRequest, accountEndpointGetRequest };
