import { getPrimaryImageUrl } from '@/helpers/product/product';

export const createBrazeData = (product) => {
  if (typeof product !== 'object') {
    return null;
  }

  const {
    list_id: listId, region, company_ad, subject, price, category_tree, badges,
  } = product;

  const category = category_tree?.[0]?.name || '';
  const subcategory = category_tree?.[1]?.name || '';
  const leaf_category = category_tree?.[2]?.name || '';

  const countyText = region?.label ? `${region.label} megye` : '';
  const location = region?.label === 'Budapest' ? region.label : countyText;

  const item_price = price?.value || 0;

  const title = subject || '';

  const list_id = listId || '';

  const ad_type = company_ad ? 'pro' : 'pri';

  let has_feature_bump = false;
  let has_feature_gallery = false;
  let has_feature_urgent = false;
  let has_feature_multi_region = false;
  let has_feature_top_ad = false;

  if (Array.isArray(badges)) {
    badges.forEach((element) => {
      switch (element?.type) {
        case 'bump':
          has_feature_bump = true;
          break;
        case 'gallery':
          has_feature_gallery = true;
          break;
        case 'urgent':
          has_feature_urgent = true;
          break;
        case 'multi_region':
          has_feature_multi_region = true;
          break;
        case 'top_ad':
          has_feature_top_ad = true;
          break;
        default:
      }
    });
  }

  return {
    category,
    subcategory,
    leaf_category,
    location,
    item_price,
    title,
    list_id,
    ad_type,
    has_feature_bump,
    has_feature_gallery,
    has_feature_urgent,
    has_feature_multi_region,
    has_feature_top_ad,
  };
};

export const createBrazeDataForAdview = (product, loggedInUserId) => {
  if (typeof product !== 'object') {
    return null;
  }

  const brazeData = createBrazeData(product);

  return {
    ...brazeData,
    price_available: brazeData.item_price !== '',
    main_photo_url: getPrimaryImageUrl(product),
    number_of_photos: product?.images?.length || 0,
    d2d_active: product?.box_status === 'active',
    current_seller: product?.account_id === loggedInUserId,
  };
};
