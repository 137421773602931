import PropTypes from 'prop-types';

import { mapping, undefinedPriceTrackerId } from './data';
import TrackingPixel from '../TrackingPixel/index.client';
import numericSort from '@/helpers/numericSort';

const getTrackerId = (price) => {
  if (price === undefined) {
    return undefinedPriceTrackerId;
  }

  return Object
    .keys(mapping)
    .map((value) => parseInt(value, 10))
    .sort(numericSort)
    .reduce((prevValue, boundary) => {
      if (price >= boundary) {
        return mapping[boundary];
      }
      return prevValue;
    }, mapping[0]);
};

const PriceTrackingPixel = ({ price }) => {
  const trackerId = getTrackerId(price);
  if (trackerId === undefined) {
    return null;
  }

  return <TrackingPixel trackerId={trackerId} />;
};

PriceTrackingPixel.defaultProps = {
  price: undefined,
};

PriceTrackingPixel.propTypes = {
  price: PropTypes.number,
};

export default PriceTrackingPixel;
