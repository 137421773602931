import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import AdInsertionButton from '../AdInsertion/Button';
import AdInsertionIcon from '../AdInsertion/Icon';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { Account } from '@/types/common';

type AdInsertionButtonGroupProps = {
  account: Account,
  pathname: string,
  showSearchForm: boolean
};

const AdInsertionButtonGroup = ({
  account,
  pathname,
  showSearchForm,
}: AdInsertionButtonGroupProps): JSX.Element => (
  <>
    <AdInsertionIcon
      account={account}
      sx={{ display: { xs: showSearchForm ? 'none' : 'flex', sm: 'flex', lg: 'none' } }}
      pathname={pathname}
    />
    {!useFeatureFlag('SFL_HIRDETES_FELADAS') ? (
      // Old
      <AdInsertionButton
        account={account}
        variant="outlined"
        size="medium"
        sx={{ display: { xs: 'none', lg: 'block' }, ml: 4 }}
        pathname={pathname}
        channel={undefined}
      />
    ) : (
      // SFL
      <AdInsertionButton
        account={account}
        startIcon={<AddCircleOutlineIcon />}
        size="medium"
        sx={{ display: { xs: 'none', lg: 'flex' }, ml: 4 }}
        pathname={pathname}
        channel={undefined}
      />
    )}
  </>
);

export default AdInsertionButtonGroup;
